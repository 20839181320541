import React, { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { UserContext } from 'contexts/user';
import CertificatesGroup from 'components/CertificatesGroup/CertificatesGroup';
import CertificateItem from 'components/CertificateItem/CertificateItem';
import customerApi from 'api/customerApi';

const useStyles = makeStyles((theme) => ({
  datePickerContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  datePicker: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  topContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(5),
    },
  },
}));

const CertificatesPage = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [policies, setPolicies] = useState([]);
  const [userState] = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const getPolicies = async () => {
    try {
      const response = await customerApi.getPolicies(
        userState.auth,
        userState.customerNumber
      );
      if (Array.isArray(response.entities)) {
        const policiesToSet = response.entities.filter(
          (policy) =>
            !policy.status.code.startsWith('RE') &&
            policy.status.code !== 'ANNP'
        );
        setPolicies(policiesToSet);
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPolicies();
  }, []);

  const renderNoContent = () => {
    return loading ? <></> : t('dashboard.certificates.noActiveContracts');
  };

  const getPolicyTypeLabel = (policy) => {
    const typeCode = policy.contractType.code;
    return t(
      `dashboard.police.policyTypes.${typeCode.toLowerCase()}${
        policy.housingType
      }`
    );
  };

  return (
    <Grid container>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.topContainer}
      >
        <Box>
          <Typography variant="h3">
            {t('dashboard.certificates.title')}
          </Typography>
        </Box>
      </Grid>
      <Grid container xs={12}>
        {policies.length > 0
          ? policies.map((policy) => {
              return (
                <CertificatesGroup
                  key={policy.policyNumber}
                  title={getPolicyTypeLabel(policy)}
                  address={policy.policyKey}
                  type="house"
                >
                  <CertificateItem policy={policy} auth={userState.auth} />
                </CertificatesGroup>
              );
            })
          : renderNoContent()}
      </Grid>
    </Grid>
  );
};

export default CertificatesPage;
