import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import policyApi from 'api/policyApi';
import Popin from 'components/Popin';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CardDetail from './components/CardDetail';
import CardList from './components/CardList';
import { getMessageError } from 'constants/common/errorCode';

CardSelection.propTypes = {
  policy: PropTypes.object,
};

function CardSelection(props) {
  const classes = useStyles();
  const { policy } = props;
  const [optionsPopin, setOptionsPopin] = useState({
    open: false,
  });
  const { t } = useTranslation();
  const [cardUpdate, setCardUpdate] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [buttonDisabledPopin, setButtonDisabledPopin] = useState(true);
  const history = useHistory();

  const handleModif = () => {
    setOptionsPopin({
      ...optionsPopin,
      open: true,
      content: (
        <CardList
          cardSelected={policy.cardInfo?.bankcardUuid}
          onSelect={handleSelCard}
          onActionAlert={gotoPaymentMethod}
        />
      ),
    });
  };

  const gotoPaymentMethod = () => {
    history.push('/settings/paymentMethods/list');
  };

  const updateNewCardForPolice = async () => {
    if (!cardUpdate || !auth.isAuthenticated) return;
    try {
      await policyApi.updateBankcard(auth, policy.policyNumber, cardUpdate);
      enqueueSnackbar(t('dashboard.card.police.update_success'), {
        variant: 'success',
      });
    } catch (err) {
      console.error(err);
      const messageError = getMessageError(err);
      enqueueSnackbar(t(messageError), { variant: 'error' });
    } finally {
      setOptionsPopin({ ...optionsPopin, open: false });
      setCardUpdate('');
    }
  };

  const handleSelCard = (cardSel) => {
    if (cardSel.bankcardUuid !== policy.cardInfo?.bankcardUuid) {
      setCardUpdate(cardSel.bankcardUuid);
      setButtonDisabledPopin(false);
    } else {
      setButtonDisabledPopin(true);
    }
  };

  return (
    <Box mt={2} p={2} width="100%" className={classes.greybox}>
      <Box mb={2}>
        <Typography variant="h6" color="secondary">
          {t('dashboard.card.police.title')}
        </Typography>
      </Box>

      <CardDetail card={policy.cardInfo} onModif={handleModif} />

      <Popin
        title={t('dashboard.card.police.title_popup')}
        validateButtonLabel={t('common.update')}
        cancelButtonLabel={t('common.cancel')}
        showCancelButton
        onClose={() => {
          setCardUpdate('');
          setOptionsPopin({
            ...optionsPopin,
            open: false,
          });
          setButtonDisabledPopin(true);
        }}
        maxWidth="sm"
        isValidateButtonDisabled={buttonDisabledPopin}
        onValidate={updateNewCardForPolice}
        {...optionsPopin}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  greybox: {
    backgroundColor: theme.palette.grey.grey,
    borderRadius: 10,
  },
}));

export default CardSelection;
