import {
  Box,
  Button,
  Chip,
  Collapse,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
  IconButton,
} from '@material-ui/core';
import checkedLottie from 'assets/lotties/checked.json';
import Lottie from 'components/Lottie';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATE_MONTH } from 'constants/index';
import CardInfo from './CardInfo';
import { getLogoCard } from 'utils/card';
import Star from '@material-ui/icons/Star';

CardItem.propTypes = {
  card: PropTypes.object,
  onSelect: PropTypes.func,
  size: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  row: {
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  column: {
    padding: theme.spacing(1.5),
  },
  chip: {
    marginLeft: theme.spacing(0.7),
    fontSize: theme.spacing(1.5),
    '&.principal': {
      backgroundColor: theme.palette.blue.mixteBg,
      color: theme.palette.blue.mixte,
    },
    '&.expired': {
      backgroundColor: theme.palette.red.light,
      color: theme.palette.white.main,
    },
    '&.quantity': {
      color: theme.palette.green.main,
    },
  },
  typography: {
    display: 'flex',
    alignItems: 'center',

    '&.title': {
      fontSize: '1rem',
    },
    '&.expired': {
      fontSize: '0.9rem',
    },
  },
  menuTypo: {
    fontSize: '0.9rem',
    marginLeft: theme.spacing(1),
  },
  cellArow: {
    width: theme.spacing(1),
  },
}));

function CardItem(props) {
  const classes = useStyles();
  const { card, size, onSelect } = props;

  const { t } = useTranslation();
  const [openRow, setOpenRow] = useState(false);

  const handleSelect = () => {
    onSelect && onSelect(card);
  };

  const translateDate = (month, year) => {
    const monthTrans = t(TRANSLATE_MONTH[+month]);
    return `${monthTrans} ${year}`;
  };

  return (
    <Fragment>
      <TableRow hover={true} className={classes.row}>
        <TableCell
          align="left"
          className={classes.column}
          onClick={() => setOpenRow(!openRow)}
        >
          <Box display="flex" alignItems="center" marginLeft={2}>
            <img src={getLogoCard(card.brand)} height={25} width={45} />
            <Box marginLeft={2}>
              <Box display="flex">
                <Typography
                  variant="body1"
                  className={`${classes.typography} title`}
                >
                  ... {card.last4}
                </Typography>
                {card.principal && (
                  <>
                    <Chip
                      label={t('dashboard.card.item.favori')}
                      className={`${classes.chip} principal`}
                      size="small"
                    />
                    <IconButton size="small">
                      <Star fontSize="small" color="primary" />
                    </IconButton>
                  </>
                )}
                {!card.valid && (
                  <Chip
                    label={t('dashboard.card.item.expired')}
                    className={`${classes.chip} expired`}
                    size="small"
                  />
                )}
                {card.nbElementUsed > 0 && (
                  <Chip
                    label={`${card.nbElementUsed} ${t(
                      card.nbElementUsed === 1 ? 'dashboard.card.item.police_used' : 'dashboard.card.item.polices_used'
                    )}`}
                    size="small"
                    className={`${classes.chip} quantity`}
                    variant="outline"
                  />
                )}
              </Box>
              <Typography
                variant="body2"
                className={`${classes.typography} expired`}
              >
                {t( card.valid ? 'dashboard.card.item.expire_from' : 'dashboard.card.item.expired_from')}{' '}
                {translateDate(card.moisExp, card.anneeExp)}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell align="center">
          {card.valid &&
            (card.selected ? (
              <Lottie animationData={checkedLottie} isActive height={35} />
            ) : (
              <Button variant="outlined" size="small" onClick={handleSelect}>
                {t('dashboard.card.item.select')}
              </Button>
            ))}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={openRow} timeout="auto" unmountOnExit>
            <CardInfo card={card} size={size} />
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default CardItem;
