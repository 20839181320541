import {
  POLICE_MOUVEMENT_AN,
  POLICE_MOUVEMENT_ANNP,
  POLICE_MOUVEMENT_RE,
  POLICE_MOUVEMENT_RESEF,
  TYPE_POLICE_H,
  TYPE_POLICE_PNO,
} from 'constants';
import moment from 'moment/moment';

export const PAGE_SECTION_SHOW = {
  [TYPE_POLICE_H]: {
    alertHamon: true,
    contractDetail: true,
    myCriteria: true,
    selection: true,
    cards: true,
    beneficiaries: true,
    certificates: true,
    quittances: true,
    guarantees: true,
    sinisters: true,
    generalConditions: true,
    changePeriodicity: true,
  },
  [TYPE_POLICE_PNO]: {
    alertHamon: true,
    contractDetail: true,
    myCriteria: true,
    selection: false,
    cards: false,
    beneficiaries: false,
    certificates: false,
    quittances: true,
    guarantees: true,
    sinisters: true,
    generalConditions: true,
    changePeriodicity: false,
  },
};

export const policeStatuses = {
  ACTIVE: 'ACTIVE',
  ENDED: 'ENDED',
  CANCELLED: 'CANCELLED',
  NOT_STARTED: 'NOT_STARTED',
  PENDING: 'PENDING',
  ENDED_FUTUR: 'ENDED_FUTUR',
};

export const getPolicyStatus = (policy) => {
  const {
    status: { code = '' },
    startDate,
    statusEffectiveDate,
  } = policy;

  if (code === POLICE_MOUVEMENT_RESEF) return policeStatuses.CANCELLED;

  if (code.startsWith(POLICE_MOUVEMENT_RE)) {
    if (
      statusEffectiveDate &&
      moment(statusEffectiveDate).utc().isAfter(moment().utc())
    )
      return policeStatuses.ENDED_FUTUR;
    return policeStatuses.ENDED;
  }
  if (code === POLICE_MOUVEMENT_ANNP) return policeStatuses.PENDING;
  if (
    code === POLICE_MOUVEMENT_AN &&
    moment(startDate).utc().diff(moment().utc(), 'seconds') > 0
  )
    return policeStatuses.NOT_STARTED;

  return policeStatuses.ACTIVE;
};
