import queryString from 'query-string';
import moment from 'moment';
import _get from 'lodash/get';
import i18next from 'i18next';
import { ZIP_CODE_NOT_ALLOWED } from 'constants/common/common';

const getQueryStringValueByName = (query, name) => {
  const parsedQueryString = queryString.parse(query);
  const queryStringValue = parsedQueryString[name];
  return queryStringValue ? queryStringValue : '';
};

// const formatNumber = (number) => {
//   if (!number) return '';

//   const stringifiedNumber = number.toString().split(',').join('.');
//   const formatedNumber = Number(stringifiedNumber);
//   if (Math.round(formatedNumber) !== formatedNumber) {
//     return formatedNumber.toFixed(2);
//   }

//   return formatedNumber;
// };

// const formatNumber = (numberToFormat) => {
//   if (!numberToFormat) return '';

//   const number = formatNumberWithoutSpace(numberToFormat);

//   let parts = number.toString().split('.');
//   parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

//   return parts.join('.');
// };

const formatNumber = (number) => {
  if (!number) return ' ';

  return Intl.NumberFormat(i18next.resolvedLanguage, {
    minimumFractionDigits: 2,
  }).format(number);
};

const formatNumberWithoutDecimal = (number) => {
  if (!number) return ' ';

  return Intl.NumberFormat(i18next.resolvedLanguage).format(number);
};

const formatNumberTwoDigits = (number) => {
  return ('0' + number).slice(-2);
};

const formatNumberWithoutSpace = (number) => {
  if (!number) return '';

  const stringifiedNumber = number.toString().split(',').join('.');
  const formatedNumber = Number(stringifiedNumber);
  if (Math.round(formatedNumber) !== formatedNumber) {
    return formatedNumber.toFixed(2);
  }

  return formatedNumber;
};

const isDate = (dateString) => {
  // return moment(dateString, 'YYYY-MM-DDTHH:mm:ss', true).isValid();
  return moment(dateString).isValid();
};

const isFirstDayOfMonth = (dateString) => {
  const firstDayOfMonth = moment(dateString)
    .startOf('month')
    .format('yyyy-MM-DD');

  return firstDayOfMonth === moment(dateString).format('yyyy-MM-DD');
};

const formatDate = (date, format = 'YYYY-MM-DDTHH:mm:ss') => {
  // const { language } = i18next;
  const newDate = moment(date);
  if (!newDate.isValid()) return null;
  return newDate.locale(i18next.resolvedLanguage).format(format);
};

const getStartOfDate = (date, period = 'day') => {
  const newDate = moment(date);
  if (!newDate.isValid()) return null;
  return newDate.format('YYYY-MM-DD');
};

const firstLetterUppercase = (string) => {
  if (typeof string === 'string')
    return string.charAt(0).toUpperCase() + string.slice(1);
  else return '';
};

const downloadFileFromBlob = (blob, nameFile) => {
  if (!blob || !nameFile) {
    console.log('Failed to download with blob empty or nameFile empty');
    return;
  }

  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', nameFile);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

const downloadFileFromLink = (url, fileName, extension) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.${extension}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

const formatDateWithSystem = (date, local = 'fr-FR') => {
  return new Intl.DateTimeFormat(local).format(new Date(date));
};

const formatNumberWithSystem = (number, local = 'fr-FR') => {
  return new Intl.NumberFormat(local).format(number);
};

const getUserFromLocalStorage = () => {
  const storageInfos = JSON.parse(
    window.localStorage.getItem('okta-token-storage')
  );
  const user = _get(storageInfos, 'idToken.claims', null);

  return user;
};

const calculerDateEcheance = (dateAnnivIntValue) => {
  if (!dateAnnivIntValue) return '';

  let dateAnnivStr = dateAnnivIntValue.toString();
  if (dateAnnivStr.length < 3 || dateAnnivStr.length > 4) return dateAnnivStr;

  if (dateAnnivStr.length == 3) dateAnnivStr = '0' + dateAnnivStr;

  let echeanceStr = dateAnnivStr + moment().year();
  let echeance = moment(echeanceStr, 'DDMMYYYY');
  if (moment(echeance).isBefore(Date.now()))
    echeance = moment(echeance).add(1, 'years');

  return moment(echeance).format('DD/MM/YYYY');
};

const setDelay = (miliseconde = 1000) => {
  return new Promise((res) => setTimeout(res, miliseconde));
};

const addUnitTime = (date, dataAdd = {}) => {
  if (!date) return null;
  let newDate = new Date(date);
  const {
    nbYears,
    nbMonth,
    nbDays,
    nbHeurs,
    nbMinutes,
    nbSeconde,
    nbMiliseconde,
  } = dataAdd;
  nbYears && newDate.setFullYear(newDate.getFullYear + nbYears);
  nbMonth && newDate.setMonth(newDate.getMonth + nbMonth);
  if (nbDays)
    newDate = new Date(newDate.getTime() + nbDays * 24 * 60 * 60 * 1000);
  if (nbHeurs) newDate = new Date(newDate.getTime() + nbHeurs * 60 * 60 * 1000);
  if (nbMinutes) newDate = new Date(newDate.getTime() + nbMinutes * 60 * 1000);
  if (nbSeconde) newDate = new Date(newDate.getTime() + nbSeconde * 1000);
  if (nbMiliseconde) newDate = new Date(newDate.getTime() + nbMiliseconde);

  return newDate;
};

const getGouvAddressApiUrl = (inputValue) => {
  return `https://api-adresse.data.gouv.fr/search/?limit=15&q=${inputValue}`;
};

const isZipCodeNotAllowed = (zipCode) => {
  return ZIP_CODE_NOT_ALLOWED.some((zipCodeNotAllowed) =>
    zipCode.startsWith(zipCodeNotAllowed.zipCode)
  );
};

export {
  getQueryStringValueByName,
  formatNumber,
  formatNumberWithoutSpace,
  isDate,
  firstLetterUppercase,
  formatDate,
  isFirstDayOfMonth,
  downloadFileFromBlob,
  downloadFileFromLink,
  formatDateWithSystem,
  formatNumberWithSystem,
  formatNumberTwoDigits,
  formatNumberWithoutDecimal,
  getStartOfDate,
  getUserFromLocalStorage,
  calculerDateEcheance,
  setDelay,
  addUnitTime,
  getGouvAddressApiUrl,
  isZipCodeNotAllowed,
};
