import React, { useEffect } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Forward30Icon from '@material-ui/icons/Forward30';

import {
  QUITTANCE_ANNUL,
  QUITTANCE_NOT_PAID,
  QUITTANCE_PAID,
  QUITTANCE_TYPE_PAID_MANUAL,
  QUITTANCE_DELETED,
  QUITTANCE_PENDING,
  QUITTANCE_IN_PROGESS,
} from 'constants/index';
import { formatDateWithSystem, formatNumber } from 'utils/index';
import QuittanceItemButton from './QuittanceItemButton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  itemGrid: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  item: {
    border: `1px solid ${theme.palette.grey.main}`,
    marginBottom: theme.spacing(1),
    width: '100%',
    position: 'relative',
  },
  boxInfo: {
    marginLeft: theme.spacing(2),
  },
  amount: {
    whiteSpace: 'nowrap',
  },
}));

const QuittanceItem = (props) => {
  const { quittance, police, refresh, getQuittances } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const isNotPaid = quittance?.state?.code === QUITTANCE_NOT_PAID;
  const isPaid = quittance?.state?.code === QUITTANCE_PAID;

  const isTypePaidManual = quittance.paimentType
    ? quittance.paimentType.code === QUITTANCE_TYPE_PAID_MANUAL
    : true;

  const isRistourne = quittance.amount < 0;

  const QUITTANCE_STAT = {
    [QUITTANCE_NOT_PAID]: {
      code: QUITTANCE_NOT_PAID,
      label: isRistourne
        ? t('dashboard.police.premiums.refund_progress')
        : t('dashboard.police.premiums.to_paid'),
    },
    [QUITTANCE_ANNUL]: {
      code: QUITTANCE_ANNUL,
      label: t('dashboard.police.premiums.canceled'),
    },
    [QUITTANCE_PAID]: {
      code: QUITTANCE_PAID,
      label: isRistourne
        ? t('dashboard.police.premiums.refunded')
        : t('dashboard.police.premiums.paid'),
    },
    [QUITTANCE_DELETED]: {
      code: QUITTANCE_DELETED,
      label: t('dashboard.police.premiums.canceled'),
    },
    [QUITTANCE_PENDING]: {
      code: QUITTANCE_PENDING,
      label: t('dashboard.police.premiums.pending'),
    },
    [QUITTANCE_IN_PROGESS]: {
      code: QUITTANCE_IN_PROGESS,
      label: t('dashboard.police.premiums.inProgress'),
    },
  };

  const handleClickGotoPaid = () => {
    history.push(
      `/polices/${police.policyNumber}/paiement/${quittance.premiumUuid}`,
      {
        params: {
          premiumUuid: quittance.premiumUuid,
          policyNumber: police.policyNumber,
          address: police.policyKey,
          policyStatus: police.status.code,
          contractType: police.contractType.code,
        },
      }
    );
  };

  useEffect(() => {
    if (refresh) {
      if (quittance.state.code === QUITTANCE_NOT_PAID) {
        quittance.state = QUITTANCE_STAT[QUITTANCE_PENDING];

        const interval = setInterval(() => {
          getQuittances();
        }, 30000);

        return () => clearInterval(interval);
      } else if (quittance.state.code === QUITTANCE_PAID) {
        history.push(`/polices/${police.policyNumber}`);
      }
    }
  }, []);

  return (
    <Grid item xs={12} className={classes.itemGrid}>
      <Card className={classes.item}>
        <CardContent>
          <Grid container>
            <Grid container item xs={3} alignItems="center">
              <Typography
                component="span"
                variant="h6"
                classes={{ root: classes.amount }}
              >
                {formatNumber(quittance.amount)}€
              </Typography>
            </Grid>
            <Grid item xs={9} sm={6}>
              <Box className={classes.boxInfo}>
                <Typography variant="h6">
                  {isRistourne
                    ? t('dashboard.police.premiums.refund')
                    : t('dashboard.police.premiums.premium')}{' '}
                  {quittance.premiumNumber}
                </Typography>
                <Typography>
                  {t('dashboard.police.premiums.period')}{' '}
                  {t('dashboard.police.premiums.from')}{' '}
                  {formatDateWithSystem(quittance.startDate)}{' '}
                  {t('dashboard.police.premiums.to')}{' '}
                  {formatDateWithSystem(quittance.endDate)}
                </Typography>
                <Typography color={isNotPaid ? 'error' : 'initial'}>
                  {QUITTANCE_STAT[quittance.state.code]?.label}
                  {isPaid &&
                    quittance.paiementMode &&
                    quittance.paimentMode.label}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={3}
              alignItems="center"
              style={{ justifyContent: 'flex-end' }}
            >
              {/* {isPaid && (
                <QuittanceItemButton
                  isPaid={isPaid}
                  onClick={handleClickDownQuittance}
                />
              )} */}
              {isNotPaid && isTypePaidManual && !isRistourne && (
                <QuittanceItemButton
                  isPaid={isPaid}
                  onClick={handleClickGotoPaid}
                />
              )}
              {quittance.state.code === QUITTANCE_PENDING && (
                <Forward30Icon fontSize="large" />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

QuittanceItem.propTypes = {
  quittance: PropTypes.object.isRequired,
  police: PropTypes.object.isRequired,
  refresh: PropTypes.bool,
  getQuittances: PropTypes.func,
};

export default QuittanceItem;
