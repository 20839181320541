import React, { useState, useRef } from 'react';
import {
  Typography,
  Grid,
  Box,
  IconButton,
  Hidden,
  Menu,
  MenuItem,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from 'react-router-dom';
import './DashboardHeader.scss';
import useStyles from './style';

const HeaderSkeleton = () => {
  const classes = useStyles();
  // const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const anchor = useRef();

  return (
    <Box className={classes.header}>
      <Grid container direction="row">
        <Grid item container alignItems="center" xs={10} md={6}>
          <Grid item container direction="column" xs={2}>
            <Box className={classes.arrowContainer}>
              {/* <IconButton color="secondary" onClick={() => history.goBack()}> */}
              <IconButton color="secondary" component={Link} to="/polices">
                <ArrowBackIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item container direction="column" xs={10}>
            <Skeleton variant="rect" width={197} height={32} />
            <Skeleton variant="text" />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeaderSkeleton;
