import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import useStyles from './style';

const MySelectionSkeleton = () => {
  const classes = useStyles();

  return (
    <Box mt={2} p={2} width="100%" className={classes.greyBox}>
      <Grid container>
        <Box mb={2}>
          <Skeleton variant="rect" width={197} height={32} />
        </Box>
        <Grid item container>
          <Grid item container xs={12} md={9}>
            <Grid item container xs={12}>
              <Grid item xs={4}>
                <Box mb={2}>
                  <Skeleton variant="text" width={100} />
                  <Skeleton
                    variant="text"
                    style={{
                      marginRight: 10,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box mb={2}>
                  <Skeleton variant="text" width={100} />
                  <Skeleton
                    variant="text"
                    style={{
                      marginRight: 10,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Skeleton variant="text" width={100} />
                <Skeleton
                  variant="text"
                  style={{
                    marginRight: 10,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={4}>
                <Skeleton variant="text" width={100} />
                <Skeleton
                  variant="text"
                  style={{
                    marginRight: 10,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item md={3} xs={0}>
            <Hidden mdDown>
              <Skeleton variant="rect" width={150} height={150} />
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MySelectionSkeleton;
