import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addUnitTime } from 'utils/index';
import { NB_DAYS_LIMIT_RAPPEL_HAMON } from 'constants/index';

AlertHamon.propTypes = {
  police: PropTypes.object.isRequired,
};

function AlertHamon(props) {
  const { police } = props;

  const history = useHistory();
  const { t } = useTranslation();

  const STATE_ALERT = {
    warning: {
      severity: 'warning',
      textAlert: t('dashboard.resilHamon.alert.warning.msg'),
      textBtn: t('dashboard.resilHamon.alert.warning.btn'),
    },
    success: {
      severity: 'success',
      textAlert: t('dashboard.resilHamon.alert.success.msg'),
      textBtn: t('dashboard.resilHamon.alert.success.btn'),
    },
  };

  const state = police.informationPoliceHamon.terminationDo
    ? STATE_ALERT.success
    : STATE_ALERT.warning;

  const handleClick = () => {
    history.push(`/polices/${police.policyNumber}/resil-hamon`);
  };

  const dateLimitRappel = addUnitTime(police.startDate, {
    nbDays: -1 * NB_DAYS_LIMIT_RAPPEL_HAMON,
  });
  if (!dateLimitRappel || new Date().getTime() > dateLimitRappel.getTime())
    return <></>;

  return (
    <Box>
      <Alert
        severity={state.severity}
        action={<Button onClick={handleClick}>{state.textBtn}</Button>}
      >
        {state.textAlert}
      </Alert>
    </Box>
  );
}

export default AlertHamon;
