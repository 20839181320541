import React from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@material-ui/core/TextField';
import Info from '../Info';

const NumberField = (props) => {
  const { info } = props;

  return (
    <MuiTextField
      type="number"
      variant="filled"
      {...props}
      InputProps={{ endAdornment: info && <Info title={info} /> }}
    />
  );
};

NumberField.propTypes = {
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default NumberField;
