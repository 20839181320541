import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useOktaAuth } from '@okta/okta-react';
import _get from 'lodash/get';

import Header from 'components/Header/Header';
import { UserContext } from 'contexts/user';
import { MrhContext } from 'contexts/mrh';
import getSteps from 'constants/steps/steps';
import customerApi from 'api/customerApi';
import userApi from 'api/userApi';
import Steps from './Steps';
import { formatDate } from 'utils';
import Popin from 'components/Popin';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getMessageError } from 'constants/common/errorCode';

const MrhSubscription = (props) => {
  const { location } = props;
  const { state: routerState } = location;
  const { authState } = useOktaAuth();
  const [userState, dispatchUser] = useContext(UserContext);
  const { state, dispatch } = useContext(MrhContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [afficherPopin, setAfficherPopin] = useState(false);
  const history = useHistory();
  const urlParam = useParams();
  const ancienParcoursId = useRef(null);
  const ancienParcoursDate = useRef(null);

  const stepId = _get(routerState, 'stepId', null);
  const params = _get(routerState, 'params', null);
  const next = _get(routerState, 'next', null);
  const stay = _get(routerState, 'stay', false);

  const setUser = (currentUser, userInfo, auth) => {
    const payload = userInfo
      ? { ...currentUser, ...userInfo, auth }
      : { ...currentUser, auth };

    dispatchUser({
      type: 'SET_USER',
      payload,
    });
  };

  const fillStorage = (userInfo) => {
    const contact = {
      ...state.data.contact,
      birthdate: _get(userInfo, 'individual.birthDate', null),
      gender: _get(userInfo, 'title', null),
      lastName: _get(userInfo, 'lastName', null),
      firstName: _get(userInfo, 'firstName', null),
      mail: _get(userInfo, 'contact.mail', null),
      birthCity: _get(userInfo, 'villeNais', null),
      countryBirth: _get(userInfo, 'paysNais', null),
      phone: {
        value: _get(userInfo, 'contact.mobilePhone', null),
        country: { countryCode: null },
      },
    };

    dispatch({
      type: 'SET_DATA',
      payload: {
        key: 'contact',
        value: contact,
      },
    });
  };

  const getCurrentUser = async (auth) => {
    setLoading(true);
    try {
      const currentUser = await userApi.getExternalUser(auth);
      let currentUserInfo;
      if (currentUser && currentUser.customerNumber) {
        currentUserInfo = await customerApi.getUserInfo(
          auth,
          currentUser.customerNumber
        );
      }
      setUser(currentUser, currentUserInfo, auth);
      fillStorage(currentUserInfo);
    } catch (err) {
      console.error(err);
      const messageError = getMessageError(err);
      enqueueSnackbar(t(messageError), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authState.isAuthenticated) {
      const {
        isAuthenticated,
        accessToken: { accessToken: token },
      } = authState;
      getCurrentUser({ isAuthenticated, token });
    }
  }, [authState]);

  useEffect(() => {
    if (state.redirectToStep) {
      dispatch({
        type: 'DELETE_ENTRY',
        payload: {
          key: 'redirectToStep',
        },
      });

      dispatch({
        type: 'SET_STEP',
        payload: {
          stepId: state.redirectToStep,
        },
      });
    }
  }, []);

  useEffect(() => {
    let ancienParcoursTrouvé = window.localStorage.getItem('parcoursMRH');
    let aucunParcoursEnCours = !urlParam.parcoursId;
    if (ancienParcoursTrouvé && aucunParcoursEnCours) {
      const data = JSON.parse(ancienParcoursTrouvé);
      ancienParcoursId.current = data.parcoursId;
      ancienParcoursDate.current = data.date;

      const dateExpiration = moment(data.date).add(30, 'days');
      const parcoursNonExpiré = moment().isBefore(dateExpiration);

      if (parcoursNonExpiré) setAfficherPopin(true);
    }
  }, []);

  return !loading ? (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Header disablePrevious={stay} context={MrhContext} getSteps={getSteps} />
      <Steps id={stepId} routerParams={{ ...params }} next={next} stay={stay} />
      <Popin
        open={afficherPopin}
        onClose={() => setAfficherPopin(false)}
        title={t('steps.popinRepriseParcours.popinTitre')}
        onValidate={() => {
          history.push('/parcours/mrh/' + ancienParcoursId.current);
          window.location.reload();
        }}
        validateButtonLabel={t('steps.popinRepriseParcours.popinValidation')}
        showCancelButton={true}
        cancelButtonLabel={t('steps.popinRepriseParcours.popinAnnulation')}
        onCancel={() => setAfficherPopin(false)}
        content={
          <div style={{ margin: 20 }}>
            {t('steps.popinRepriseParcours.popinInfo', {
              date: formatDate(ancienParcoursDate.current, 'DD/MM/YYYY'),
              heure: formatDate(ancienParcoursDate.current, 'HH:mm'),
              interpolation: { escapeValue: false },
            })}
            <br />
            {t('steps.popinRepriseParcours.popinQuestion')}
          </div>
        }
      />
    </div>
  ) : (
    <></>
  );
};

MrhSubscription.propTypes = {
  location: PropTypes.shape(),
};

export { MrhSubscription };
