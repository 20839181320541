import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Button, Paper, Grid, Box, TextField } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';
import { useSnackbar } from 'notistack';

import Lottie from 'components/Lottie';
import { passwordStructure } from 'utils/form-validation';
import Icon from 'components/Icon';
import authApi from 'api/authApi';
import Home from 'assets/lotties/home.json';
import HomeMobile from 'assets/lotties/home-mobile.json';
import useStyles from './style';

const SignupConfirm = () => {
  const classes = useStyles();
  const history = useHistory();
  const { token } = useParams();
  const { t } = useTranslation();
  const [stateToken, setStateToken] = useState(null);
  const [activationError, setActivationError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [userInputs, setUserInputs] = useState({});
  const [complexityPasswordError, setComplexityPasswordError] = useState(null);
  const [samePasswordError, setSamePasswordError] = useState(null);
  const [currentPassordError, setCurrentPasswordError] = useState(null);
  const [containsUpperCase, setContainsUpperCase] = useState(false);
  const [containsLowerCase, setContainsLowerCase] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [isUpToMinimumLength, setIsUpToMinimumLength] = useState(false);
  const [containsSpecialChars, setContainsSpecialChars] = useState(false);
  const [isOK, setIsOk] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);


  const handleChange = (event) => {
    const { id, value } = event.target;
    setUserInputs({ ...userInputs, [id]: value });
  };

  const handleSubmit = async () => {
    setActivationError(false);
    setErrorMessage(null);

    if (isFormValid()) {

      try {
        setLoading(true);

        await authApi.resetPassword(
          stateToken,
          userInputs.newPassword
        );
        
        enqueueSnackbar(t('auth.initPassword.successMessage'), {
          variant: 'success',
        });

        setIsOk(true);
      } catch (error) {
        if (error.errorCode && error.errorMessage) {
          const message = error.errorMessage[i18next.resolvedLanguage];
          enqueueSnackbar(message, { variant: 'error' });
        } else {
          enqueueSnackbar(t('auth.initPassword.errorMessage'), {
            variant: 'error',
          });
        }
      }
      setLoading(false);
    }
  };

  const handleGenerateNewLink = () => {
    history.push('/signup/activationLink');
  };

  const getPasswordRules = () => {
    return passwordStructure(userInputs.newPassword);
  };

  const isFormValid = () => {
    const passwordRules = getPasswordRules();
    const goodPasswordCondition =
      passwordRules.containsUpperCase() &&
      passwordRules.containsLowerCase() &&
      passwordRules.containsSpecialChars() &&
      passwordRules.containsNumbers() &&
      passwordRules.isUpToMinimumLength();

    if (!goodPasswordCondition) setComplexityPasswordError(true);
    else setComplexityPasswordError(false);

    const goodPasswordMatchCondition =
      userInputs.confirmPassword === userInputs.newPassword;
    if (!goodPasswordMatchCondition) setSamePasswordError(true);
    else setSamePasswordError(false);

    return (
      goodPasswordCondition &&
      goodPasswordMatchCondition
    );
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const getVisibilityIcon = () => {
    return showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />;
  };

  const getPasswordHelperText = () => {
    return (
      <Box className={classes.container}>
        <Typography>
          {t('auth.passwordCreation.passwordPolicyRequirementsTitle')}
        </Typography>
        <ul className={classes.ul}>
          <li className={isUpToMinimumLength ? classes.liOk : classes.li}>
            <Typography>
              {t(
                'auth.passwordCreation.passwordPolicyRequirements.minimumCharacters'
              )}
            </Typography>
          </li>
          <li
            className={
              containsLowerCase && containsUpperCase && containsNumber
                ? classes.liOk
                : classes.li
            }
          >
            <Typography>
              {t(
                'auth.passwordCreation.passwordPolicyRequirements.typeOfCharacters'
              )}
            </Typography>
          </li>
          <li className={containsSpecialChars ? classes.liOk : classes.li}>
            <Typography>
              {t(
                'auth.passwordCreation.passwordPolicyRequirements.specialCharacter'
              )}
            </Typography>
            <Typography variant="caption" classes={{ root: classes.caption }}>
              {t('auth.passwordCreation.passwordPolicyRequirements.example')}
            </Typography>
          </li>
          <div
            style={{
              display: 'flex',
              marginLeft: -17,
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <Icon className="fas fa-info-circle" style={{ fontSize: 15 }} />
            <Typography style={{ lineHeight: 1, marginLeft: 5 }}>
              {t(
                'auth.passwordCreation.passwordPolicyRequirements.passwordMustNotContain'
              )}
            </Typography>
          </div>
        </ul>
      </Box>
    );
  };

  const verify = async () => {
    try {
      const response = await authApi.verifyRecoveryToken(token);

      if (response.stateToken) {
        setStateToken(response.stateToken);
      } else {
        setError(t('error.authentication.recoveryTokenExpired'));
      }
    } catch (error) {
      setError(t('error.unknown'));
    }
  };

  useEffect(() => {
    verify();
  }, [token]);

  useEffect(() => {
    if (userInputs.newPassword) {
      setContainsUpperCase(getPasswordRules().containsUpperCase());
      setContainsLowerCase(getPasswordRules().containsLowerCase());
      setContainsSpecialChars(getPasswordRules().containsSpecialChars());
      setContainsNumber(getPasswordRules().containsNumbers());
      setIsUpToMinimumLength(getPasswordRules().isUpToMinimumLength());
    }
  }, [userInputs]);

  return (
    <div className={classes.wrapper}>
      <Box className={classes.boxLottie}>
        <Hidden xsDown>
          <Lottie animationData={Home} isActive width={'100%'} />
        </Hidden>
        <Hidden smUp>
          <Lottie animationData={HomeMobile} isActive />
        </Hidden>
      </Box>
      <div className={classes.containerTop}>
        <Paper
          variant="elevation"
          elevation={0}
          className={classes.logoWrapper}
        >
          <img
            src={`${localStorage.getItem(
              'IMAGE_MANAGER_URL_GET'
            )}/assurimo/base/logo-assurimo.png`}
            className={classes.logo}
            width={380}
          />
        </Paper>

        <Paper variant="elevation" elevation={0} className={classes.paper}>
          {loading && (
            <LinearProgress
              variant="indeterminate"
              classes={{
                root: classes.linearProgressRoot,
                colorPrimary: classes.linearProgressColorPrimary,
                bar: classes.linearProgressBar,
              }}
            />
          )}
          <Typography variant="h3" classes={{ root: classes.title }}>
            {t('auth.initPassword.title')}
          </Typography>
          <Grid container item>
            {!isOK ? (
              <div className={classes.containerBottom}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <TextField
                      id="newPassword"
                      type={showPassword ? 'text' : 'password'}
                      label={t('auth.initPassword.newPassword')}
                      onChange={handleChange}
                      error={samePasswordError}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {getVisibilityIcon()}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {complexityPasswordError && (
                      <FormHelperText
                        error={complexityPasswordError}
                        component="div"
                      >
                        {getPasswordHelperText()}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item>
                    <TextField
                      id="confirmPassword"
                      type={showPassword ? 'text' : 'password'}
                      label={t('auth.initPassword.confirmPassword')}
                      onChange={handleChange}
                      helperText={
                        samePasswordError &&
                        t('dashboard.settings.password.errors.samePassword')
                      }
                      error={samePasswordError}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {getVisibilityIcon()}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      fullWidth
                      classes={{ root: classes.button }}
                      disabled={
                        !getPasswordRules().containsUpperCase() ||
                        !getPasswordRules().containsLowerCase() ||
                        !getPasswordRules().containsSpecialChars() ||
                        !getPasswordRules().containsNumbers() ||
                        !getPasswordRules().isUpToMinimumLength() ||
                        userInputs.confirmPassword !== userInputs.newPassword
                      }
                      onClick={handleSubmit}
                    >
                      <Typography classes={{ root: classes.buttonText }}>
                        {t('common.save')}
                      </Typography>
                    </Button>
                    <Link component="button" onClick={handleGenerateNewLink}>
                      {t('auth.passwordCreation.receiveNewActivationLink')}
                    </Link>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div className={classes.containerBottom}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography>
                      {t('auth.passwordCreation.passwordCreationSuccess')}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
          {!isOK && (
            <Grid container item>
              {getPasswordHelperText()}
            </Grid>
          )}
        </Paper>
      </div>
    </div>
  );
};

export default SignupConfirm;
