import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import getPaymentPlans from '../getPaymentPlans';
import Mobile from './Mobile';
import Desktop from './Desktop';
import useStyles from '../style';
import ConditionsGenerales from '../../../../../../public/files/Conditions_générales_MRH.pdf';
import Ipid from '../../../../../../public/files/IPID_MRH.pdf';
import { ASSURIMO_LEGAL_MANSIONS_URL } from 'constants';

const PERIODICITIES = [
  { code: 'M', label: 'mensuel' },
  { code: 'A', label: 'annuel' },
];

const MyPricing = (props) => {
  const { data, setUserData, onConfirm } = props;
  const [paymentPlan, setPaymentPlan] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const periodicity = data.paymentSplitting || PERIODICITIES[0];

  const fetchPaymentPlans = async () => {
    try {
      const paymentPlans = await getPaymentPlans(data);
      const newPaymentPlan = paymentPlans.paymentPlan[0];
      setUserData('paymentPlan', newPaymentPlan);
      setPaymentPlan(newPaymentPlan);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const renderLegalMansion = () => {
    return (
      <Typography className={classes.legalMansion}>
        {t('steps.resultStep.clickingSubscribe')}{' '}
        <a
          className={classes.legalMansionLink}
          href={ConditionsGenerales}
          download="Conditions_générales.pdf"
        >
          {t('common.generalConditions')}
        </a>
        , {t('steps.resultStep.ofThe')}{' '}
        <a
          className={classes.legalMansionLink}
          href={Ipid}
          download="Fiche_IPID.pdf"
        >
          {t('common.ipidPage')}
        </a>{' '}
        {t('steps.resultStep.andThe')}{' '}
        {
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            className={classes.legalMansionLink}
            href={ASSURIMO_LEGAL_MANSIONS_URL}
            target="_blank"
          >
            {t('common.legalNotice')}
          </a>
        }
      </Typography>
    );
  };

  useEffect(() => {
    fetchPaymentPlans();
  }, []);

  if (mobile)
    return (
      <Mobile
        data={data}
        paymentPlan={paymentPlan}
        periodicity={periodicity}
        renderLegalMansion={renderLegalMansion}
        onConfirm={onConfirm}
      />
    );
  else
    return (
      <Desktop
        data={data}
        paymentPlan={paymentPlan}
        periodicity={periodicity}
        renderLegalMansion={renderLegalMansion}
        onConfirm={onConfirm}
      />
    );
};

MyPricing.propTypes = {
  data: PropTypes.shape().isRequired,
  setUserData: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default MyPricing;
