import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import SinisterListItem from './components/SinisterListItem';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import policyApi from 'api/policyApi';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Link } from 'react-router-dom';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  sinistres: {
    paddingTop: theme.spacing(1),
  },
  container: {
    borderRadius: 10,
    backgroundColor: '#fff',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  boxHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const Sinisters = (props) => {
  const { police, auth, showBtnCreate } = props;
  const [sinisters, setSinisters] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();

  const isInProgress = (statusCode) => {
    return ['EC', 'PREDECLA', 'PARCOURS'].includes(statusCode);
  };

  const sortClaims = (claims) => {
    const sortByStatus = (claim1, claim2) => {
      const { code: code1 } = claim1.status;
      const { code: code2 } = claim2.status;

      if (isInProgress(code1) && isInProgress(code2)) {
        return sortByDate(claim1, claim2);
      } else if (isInProgress(code1) && !isInProgress(code2)) {
        return -1;
      } else if (!isInProgress(code1) && isInProgress(code2)) {
        return 1;
      } else if (!isInProgress(code1) && !isInProgress(code2)) {
        return sortByDate(claim1, claim2);
      }
    };

    const sortByDate = (claim1, claim2) => {
      if (moment(claim1.eventDate) < moment(claim2.eventDate)) return 1;
      else if (moment(claim1.eventDate) > moment(claim2.eventDate)) return -1;
      else return 1;
    };

    claims.sort((claim1, claim2) => sortByDate(claim1, claim2));
    return claims.sort((claim1, claim2) => sortByStatus(claim1, claim2));
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await policyApi.listAllClaims(
          auth,
          police.policyNumber
        );
        setSinisters(sortClaims(response));
      } catch (error) {
        enqueueSnackbar(t('dashboard.sinistre.list.list_error'), {
          variant: 'error',
        });
        console.log(error);
      }
    })();
  }, []);

  return (
    <Grid container className={classes.sinistres}>
      <Box p={2} className={classes.container} width="100%">
        <Grid container>
          <Box mb={2} className={classes.boxHeader}>
            <Typography variant="h5" color="secondary">
              {t('dashboard.sinistre.list.title')}
            </Typography>
            {showBtnCreate && (
              <Button
                color="primary"
                startIcon={<AddCircleIcon />}
                component={Link}
                to={`/polices/${police.policyNumber}/nouveau-sinistre`}
              >
                {t('dashboard.policies.declareClaim')}
              </Button>
            )}
          </Box>
          {sinisters.length > 0 && (
            <Grid item container>
              {sinisters.map((item, index) => (
                <SinisterListItem key={index} sinister={item} />
              ))}
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
};

Sinisters.propTypes = {
  police: PropTypes.object,
  auth: PropTypes.object,
  showBtnCreate: PropTypes.bool,
};

export default Sinisters;
