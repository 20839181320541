import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import claimApi from 'api/claimApi';
import policyApi from 'api/policyApi';
import Header from 'components/DashboardHeader';
import Loading from 'components/Loadding/Loading';
import { CODE_DOCUMENT_GED_SINISTRE_PIECE_JOINT } from 'constants/common/codeDocumentGed';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { formatDate } from 'utils/index';
import ConfirmationSinistre from './component/ConfirmationSinistre';
import FileInputAdd from './component/FileInputAdd';
import FormSinistre from './component/FormSinistre';
import ListSinistre from './component/ListSinistre';
import PoliceInfo from './component/PoliceInfo';
import './NewSinisterPage.scss';
import { POLICE_HAVE_CLAIM_SAME_DATE_SURVENANCE_NATURE } from 'constants/common/errorCode';
import Popin from 'components/Popin/index';
import CountdownTimer from 'components/CountdownTimer/index';

const WhiteContainedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.orange.main,
    backgroundColor: `${theme.palette.white.main} !important`,
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  policyContainer: {
    paddingTop: theme.spacing(6),
    borderRadius: 10,
  },
  container: {
    backgroundColor: '#fff',
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  confirmContainer: {
    background: theme.palette.orange.main,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
}));

const NewSinisterPage = () => {
  const {
    params: { id: policyNumber },
  } = useRouteMatch({
    path: '/polices/:id/nouveau-sinistre',
  });
  const { authState } = useOktaAuth();
  const [auth, setAuth] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const today = moment().utc().format('YYYY/MM/DD');
  const [date, setDate] = useState(today);
  const [description, setDescription] = useState('');
  const [reason, setReason] = useState(null);
  const [files, setFiles] = useState([]);
  const [police, setPolice] = useState(null);
  const [reasonError, setReasonError] = useState(null);
  const [dateError, setDateError] = useState(null);
  const { t } = useTranslation();
  const [createSucces, setCreateSucces] = useState(false);
  const [claim, setClaim] = useState(null);
  const [loading, setLoading] = useState(false);
  const [optionsPopin, setOptionsPopin] = useState({
    open: false,
  });

  useEffect(() => {
    (async () => {
      const {
        isAuthenticated,
        accessToken: { accessToken: token },
      } = authState;
      const auth = { isAuthenticated, token };
      setAuth(auth);
      try {
        const res = await policyApi.getPolice(auth, policyNumber);
        setPolice(res);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    })();
  }, [authState, policyNumber]);

  const handleChangeReason = (newReason) => {
    setReason(newReason === reason ? null : newReason);
  };

  const handleChangeFile = (evt) => {
    const file = {
      name: evt.target.files[0].name,
      file: evt.target.files[0],
    };

    setFiles([...files, file]);
  };

  const handleRemoveFile = (index) => {
    const newFiles = files;
    newFiles.splice(index, 1);

    setFiles([...newFiles]);
  };

  const uploadFileToClaim = async (claimUuid) => {
    const promises = files.map((file) => {
      const formData = new FormData();
      formData.append('file', file.file);
      return claimApi.uploadFileToGed(
        auth,
        claimUuid,
        formData,
        CODE_DOCUMENT_GED_SINISTRE_PIECE_JOINT
      );
    });

    await Promise.all(promises);
  };

  const isFormValid = () => {
    setReasonError(null);
    setDateError(null);
    if (!reason) {
      setReasonError(t('dashboard.sinistre.form.error.reason_required'));
    }

    const isBetween = moment(date)
      .utc()
      .isBetween(
        moment(police.startDate).utc().format('YYYY/MM/DD'),
        today,
        'day',
        '[]'
      );

    if (!isBetween) {
      setDateError(getDateError(police.startDate, today));
    }

    return !!reason && !!isBetween;
  };

  const getDateError = (startDate, today) => {
    const diff = moment(startDate)
      .utc()
      .diff(moment(today).utc(), 'days', true);
    const formatedStartDate = moment(startDate).utc().format('DD/MM/YYYY');

    if (diff > 0) {
      return t(
        'dashboard.sinistre.form.error.date_declare_before_today'
      ).replace('%s', formatedStartDate);
    }
    if (diff < 0) {
      return t(
        'dashboard.sinistre.form.error.date_declare_between_startdate_today'
      ).replace('%s', formatedStartDate);
    }
    return t('dashboard.sinistre.form.error.date_declare_is_today');
  };

  const handleValidate = async (forceControl) => {
    if (isFormValid())
      try {
        setLoading(true);
        // creation du claim
        const params = {
          customerDescription: description,
          eventDate: formatDate(date, 'YYYY-MM-DDTHH:mm:ss:SSS[Z]'),
          natureCode: reason,
          forceControl,
        };
        const claimRes = await policyApi.createClaim(
          auth,
          policyNumber,
          params
        );
        const { claimsUuid } = claimRes;
        //upload du claim
        files && files.length && (await uploadFileToClaim(claimsUuid));

        setClaim(claimRes);
        setCreateSucces(true);
      } catch (error) {
        handError(error);
      } finally {
        setLoading(false);
      }
  };

  const handError = (error) => {
    const { code } = error;
    if (POLICE_HAVE_CLAIM_SAME_DATE_SURVENANCE_NATURE === code) {
      showPopinConfirm(t('dashboard.sinistre.form.control.msg_control'));
    } else {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const showPopinConfirm = (message) => {
    setOptionsPopin({
      open: true,
      title: t('common.popin.confirm_title'),
      onValidate: () => handleValidate(false),
      validateButtonLabel: t('common.yes'),
      showCancelButton: true,
      content: (
        <Box margin={3}>
          <Typography variant="body1">{message}</Typography>
        </Box>
      ),
      onCancel: handlePopinClose,
      cancelButtonLabel: t('common.no'),
    });
  };

  const handlePopinClose = () => {
    setOptionsPopin({
      open: true,
      title: t('common.popin.info_title'),
      onValidate: () => history.push(`/polices/${policyNumber}`),
      showCancelButton: false,
      validateButtonLabel: (
        <>
          {t('common.yes')} {'('}
          <CountdownTimer
            targetDate={new Date().getTime() + 6 * 1000}
            onFinishCountDown={() => history.push(`/polices/${policyNumber}`)}
          />
          {')'}
        </>
      ),
      content: (
        <Box margin={3}>
          <Typography variant="body1">
            {t('dashboard.sinistre.form.control.msg_not_continue')}
          </Typography>
        </Box>
      ),
    });
  };

  if (createSucces) {
    return <ConfirmationSinistre claim={claim} police={police} />;
  }

  return (
    <Box mb={10}>
      <Header
        title={t('dashboard.sinistre.form.header.title')}
        mainAction={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => history.goBack()}
          >
            {t('dashboard.sinistre.form.header.btn_close')}
          </Button>
        }
      />

      {loading && <Loading />}
      {!loading && police && (
        <Box className={classes.policyContainer}>
          <Grid container>
            <Box
              className={classes.container}
              width={1}
              display="flex"
              justifyContent="center"
            >
              <Grid container xs={12} lg={8}>
                <Box
                  mb={6}
                  display="flex"
                  width={1}
                  alignItems="center"
                  flexDirection="column"
                >
                  <Typography variant="h5" color="secondary">
                    {t('dashboard.sinistre.form.police.label')}
                  </Typography>
                  {police && (
                    <PoliceInfo displayLinkButton={false} police={police} />
                  )}

                  <Box mt={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      component={Link}
                      to="/sinistres/choix-police"
                    >
                      {t('dashboard.sinistre.form.police.button_change')}
                    </Button>
                  </Box>
                </Box>
                <Box mb={6}>
                  <ListSinistre
                    reason={reason}
                    onChange={handleChangeReason}
                    mb={0}
                  />
                  {reasonError && (
                    <FormHelperText error={true} variant="outlined">
                      {reasonError}
                    </FormHelperText>
                  )}
                </Box>
                <FormSinistre
                  setDate={setDate}
                  setDescription={setDescription}
                  date={date}
                  description={description}
                  today={today}
                  minDate={police.startDate}
                  dateError={dateError}
                />
                <FileInputAdd
                  files={files}
                  onRemove={handleRemoveFile}
                  onChange={handleChangeFile}
                />
              </Grid>
            </Box>
          </Grid>
          <Grid container>
            <Box
              display="flex"
              width={1}
              alignItems="center"
              flexDirection="column"
              className={classes.confirmContainer}
            >
              <Box my={3} width={1} display="flex" justifyItems="center">
                <Grid container justifyContent="center">
                  <WhiteContainedButton
                    align="center"
                    variant="contained"
                    color="primary"
                    onClick={() => handleValidate(true)}
                  >
                    {t('dashboard.sinistre.form.info.send')}
                  </WhiteContainedButton>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Box>
      )}
      <Popin
        onClose={() => setOptionsPopin({ open: false })}
        maxWidth="sm"
        {...optionsPopin}
      />
    </Box>
  );
};

export default NewSinisterPage;
