import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import GlobalContext from 'contexts/global';
import { ASSURIMO_LEGAL_MANSIONS_URL } from 'constants';
import useStyles from './style';

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { cookiesSelectorRef } = useContext(GlobalContext);

  return (
    <Container maxWidth={'md'} className={classes.footerWrapper}>
      <div className={classes.footer}>
        <Typography variant="caption">
          {t('common.socialStatus')}
          <a className={classes.link} href="http://www.orias.fr">
            www.orias.fr
          </a>
          {'.'} {t('common.socialStatusLegalMensions')}
          <a className={classes.link} href={ASSURIMO_LEGAL_MANSIONS_URL}>
            {t('common.legalNotice')}
          </a>
          {'. '}
          <Link
            className={classes.link}
            component="button"
            onClick={() => cookiesSelectorRef.current?.setVisible(true)}
          >
            {t('common.changeCookiesPreferences')}
          </Link>
        </Typography>
      </div>
    </Container>
  );
};

export default Footer;
