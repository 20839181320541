import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSnackbar } from 'notistack';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import policyApi from 'api/policyApi';
import Header from 'components/DashboardHeader';
import Popin from 'components/Popin/index';
import FormSinistre from './component/FormSinistre';
import ListSinistre from './component/ListSinistre';
import PoliceInfo from './component/PoliceInfo';
import useStyles from './style';
import './NewSinisterPage.scss';
import ClaimsIAPopin from './component/ClaimsIAPopin';
import { getMessageError } from 'constants/common/errorCode';

const WhiteContainedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.orange.main,
    backgroundColor: `${theme.palette.white.main} !important`,
  },
}))(Button);

const NewSinisterPage = () => {
  const {
    params: { id: policyNumber },
  } = useRouteMatch({
    path: '/polices/:id/nouveau-sinistre',
  });
  const { authState } = useOktaAuth();
  const [auth, setAuth] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const today = moment().utc().format('YYYY/MM/DD');
  const [date, setDate] = useState(today);
  const [reason, setReason] = useState(null);
  const [police, setPolice] = useState(null);
  const [reasonError, setReasonError] = useState(null);
  const [dateError, setDateError] = useState(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showPopin, setShowPopin] = useState(false);
  const [magicLink, setMagicLink] = useState(null);

  useEffect(() => {
    (async () => {
      const {
        isAuthenticated,
        accessToken: { accessToken: token },
      } = authState;
      const auth = { isAuthenticated, token };
      setAuth(auth);
      try {
        const res = await policyApi.getPolice(auth, policyNumber);
        setPolice(res);
      } catch (error) {
        const messageError = getMessageError(error);
        console.error(t(messageError), error);
        enqueueSnackbar(t(messageError), { variant: 'error' });
      }
    })();
  }, [authState, policyNumber]);

  const handleChangeReason = (newReason) => {
    setReason(newReason === reason ? null : newReason);
  };

  const isFormValid = () => {
    setReasonError(null);
    setDateError(null);
    if (!reason) {
      setReasonError(t('dashboard.sinistre.form.error.reason_required'));
    }

    const isBetween = moment(date)
      .utc()
      .isBetween(
        moment(police.startDate).utc().format('YYYY/MM/DD'),
        today,
        'day',
        '[]'
      );

    if (!isBetween) {
      setDateError(getDateError(police.startDate, today));
    }

    return !!reason && !!isBetween;
  };

  const getDateError = (startDate, today) => {
    const diff = moment(startDate)
      .utc()
      .diff(moment(today).utc(), 'days', true);
    const formatedStartDate = moment(startDate).utc().format('DD/MM/YYYY');

    if (diff > 0) {
      return t(
        'dashboard.sinistre.form.error.date_declare_before_today'
      ).replace('%s', formatedStartDate);
    }
    if (diff < 0) {
      return t(
        'dashboard.sinistre.form.error.date_declare_between_startdate_today'
      ).replace('%s', formatedStartDate);
    }
    return t('dashboard.sinistre.form.error.date_declare_is_today');
  };

  const handleValidate = async () => {
    if (isFormValid()) {
      try {
        setLoading(true);
        const response = await policyApi.checkIfEligibleToClaimsIA(
          auth,
          policyNumber,
          {
            claimsDate: moment(date).format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
            claimsNature: reason,
          }
        );

        if (response.eligibleClaimform) {
          setMagicLink(response.claimformLink);
          setShowPopin(true);
        } else {
          history.push({
            pathname: `/polices/${police.policyNumber}/nouveau-sinistre/informations-complementaires`,
            state: { reason, date },
          });
        }
      } catch (error) {
        const messageError = getMessageError(error);
        console.error(t(messageError), error);
        enqueueSnackbar(t(messageError), { variant: 'error' });
      } finally {
        setLoading(false);
      }
    }
  };

  const renderContent = () => {
    return <ClaimsIAPopin reason={reason} date={date} policy={police} />;
  };

  const handleClosePopin = () => {
    setShowPopin(false);
  };

  const redirectToClaimsIA = () => {
    if (magicLink) window.location.replace(magicLink);
  };

  return (
    <Box mb={10}>
      <Header
        title={t('dashboard.sinistre.form.header.title')}
        mainAction={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => history.goBack()}
          >
            {t('dashboard.sinistre.form.header.btn_close')}
          </Button>
        }
      />

      {police && (
        <Box className={classes.policyContainer}>
          <Grid container>
            <Box
              className={classes.container}
              width={1}
              display="flex"
              justifyContent="center"
            >
              <Grid container xs={12} lg={8}>
                <Box
                  mb={6}
                  display="flex"
                  width={1}
                  alignItems="center"
                  flexDirection="column"
                >
                  <Typography variant="h5" color="secondary">
                    {t('dashboard.sinistre.form.police.label')}
                  </Typography>
                  {police && (
                    <PoliceInfo displayLinkButton={false} police={police} />
                  )}
                </Box>
                <Box mb={6}>
                  <ListSinistre
                    reason={reason}
                    onChange={handleChangeReason}
                    mb={0}
                  />
                  {reasonError && (
                    <FormHelperText error={true} variant="outlined">
                      {reasonError}
                    </FormHelperText>
                  )}
                </Box>
                <FormSinistre
                  setDate={setDate}
                  date={date}
                  today={today}
                  minDate={police.startDate}
                  dateError={dateError}
                />
              </Grid>
            </Box>
          </Grid>
          <Grid container>
            <Box
              display="flex"
              width={1}
              alignItems="center"
              flexDirection="column"
              className={classes.confirmContainer}
            >
              <Box my={3} width={1} display="flex" justifyItems="center">
                <Grid container justifyContent="center">
                  <WhiteContainedButton
                    align="center"
                    variant="contained"
                    color="primary"
                    onClick={() => handleValidate(true)}
                    disabled={loading}
                  >
                    {t('dashboard.sinistre.form.info.declare')}
                    {loading && (
                      <CircularProgress
                        classes={{ root: classes.circularProgress }}
                        color="primary"
                        size={16}
                      />
                    )}
                  </WhiteContainedButton>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Box>
      )}
      <Popin
        open={showPopin}
        onClose={handleClosePopin}
        title={t('dashboard.sinistre.form.info.continue')}
        content={renderContent()}
        validateButtonLabel={t('dashboard.sinistre.form.info.continue')}
        showCancelButton
        onValidate={redirectToClaimsIA}
        // isValidateButtonDisabled={validateButtonDisabled}
      />
    </Box>
  );
};

export default NewSinisterPage;
