import React, { useEffect, useRef, useState, useContext } from 'react';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import PopinMultiPages from 'components/PopinMultiPages';
import Header from 'components/DashboardHeader';
import HeaderSkeleton from 'components/DashboardHeader/skeleton';
import { UserContext } from 'contexts/user';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TuneIcon from '@material-ui/icons/Tune';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import _get from 'lodash/get';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import policyApi from 'api/policyApi';
import customerApi from 'api/customerApi';
import Beneficiaries from './Beneficiaries';
import Certificates from './Certificates';
import CertificateBeneficiarySkeleton from './Certificates/skeleton';
import Quittances from './quittance/Quittances';
import QuittancesSkeleton from './quittance/skeleton';
import ContractDetails from './ContractDetails';
import ContractDetailsSkeleton from './ContractDetails/skeleton';
import TerminationPopin from './TerminationPopin';
import MyCriteria from './MyCriteria';
import MyCriteriaSkeleton from './MyCriteria/skeleton';
import MySelection from './MySelection';
import MySelectionSkeleton from './MySelection/skeleton';
import Guarantees from './Guarantees';
import useStyles from './style';
import Sinisters from './Sinisters/index';
import CardSelection from './CardSelection/index';
import useAuth from 'hooks/useAuth';
import AlertHamon from './AlertHamon/index';
import GeneralConditions from './GeneralConditions';
import ContractPeriodicity from './ContractPeriodicityPopin/ContractPeriodicity';
import Confirmation from './ContractPeriodicityPopin/Confirmation';
import Result from './ContractPeriodicityPopin/Result';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TYPE_POLICE_H, TYPE_POLICE_PNO } from 'constants/index';

const statuses = {
  ACTIVE: 'ACTIVE',
  ENDED: 'ENDED',
  NOT_STARTED: 'NOT_STARTED',
  PENDING: 'PENDING',
  ENDED_FUTUR: 'ENDED_FUTUR',
};

const getPolicyStatus = (policy) => {
  const {
    status: { code = '' },
    startDate,
    statusEffectiveDate,
  } = policy;

  if (code.startsWith('RE')) {
    if (
      statusEffectiveDate &&
      moment(statusEffectiveDate).utc().isAfter(moment().utc())
    )
      return statuses.ENDED_FUTUR;
    return statuses.ENDED;
  }
  if (code === 'ANNP') return statuses.PENDING;
  if (
    code === 'AN' &&
    moment(startDate).utc().diff(moment().utc(), 'seconds') > 0
  )
    return statuses.NOT_STARTED;

  return statuses.ACTIVE;
};

const PAGE_SECTION_SHOW = {
  [TYPE_POLICE_H]: {
    alertHamon: true,
    contractDetail: true,
    myCriteria: true,
    selection: true,
    cards: true,
    beneficiaries: true,
    certificates: true,
    quittances: true,
    guarantees: true,
    sinisters: true,
    generalConditions: true,
    changePeriodicity: true,
  },
  [TYPE_POLICE_PNO]: {
    alertHamon: true,
    contractDetail: true,
    myCriteria: true,
    selection: false,
    cards: false,
    beneficiaries: false,
    certificates: false,
    quittances: true,
    guarantees: true,
    sinisters: true,
    generalConditions: true,
    changePeriodicity: false,
  },
};

const PolicyPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [userState] = useContext(UserContext);
  const storageInfos = JSON.parse(
    window.localStorage.getItem('okta-token-storage')
  );
  const userInfos = _get(storageInfos, 'idToken.claims', null);

  const params = useParams();
  const idPolice = params.id;

  const [police, setPolice] = useState(null);
  const [polichab, setPolichab] = useState(null);
  const [terminationPopinOpen, setTerminationPopinOpen] = useState(false);
  const [beneficiaryPopinOpen, setBeneficiaryPopinOpen] = useState(false);
  const [children, setchildren] = useState([]);
  const [roommates, setRoommates] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [policyStatus, setPolicyStatus] = useState(null);
  const [terminationLoading, setTerminationLoading] = useState(false);
  const [showTerminationButton, setShowTerminationButton] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [contractPopinOpen, setContractPopinOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState('contractPeriodicity');
  const [periodicity, setPeriodicity] = useState({
    code: 'M',
    label: t('steps.quoteStep.everyMonth'),
  });

  const refresh =
    location.state?.refresh ||
    !!location.search.split('?').find((element) => element.includes('refresh'));

  const anchor = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const sectionRefs = {
    contractDetail: {
      ref: useRef(),
      menuLabel: 'dashboard.police.details.title',
    },
    cards: {
      ref: useRef(),
      menuLabel: 'dashboard.card.police.title',
      lienSection: 'cards',
    },
    beneficiaries: {
      ref: useRef(),
      menuLabel: 'dashboard.police.beneficiaries.title',
      lienSection: 'beneficiaries',
    },
    garanties: {
      ref: useRef(),
      menuLabel: 'dashboard.police.guarantees.title',
    },
    sinisters: { ref: useRef(), menuLabel: 'dashboard.sinistre.list.title' },
    quittances: {
      ref: useRef(),
      menuLabel: 'dashboard.police.premiums.premiums_title',
    },
    documents: { ref: useRef(), menuLabel: 'dashboard.relatedDocuments.title' },
  };

  const handleClickMenu = (sectionId) => {
    sectionRefs[sectionId].ref.current.style.scrollMargin = '120px';
    sectionRefs[sectionId].ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleOpenTerminationPopin = () => {
    setTerminationPopinOpen(true);
    setMenuOpen(false);
  };

  const handleCloseTerminationPopin = () => {
    setTerminationPopinOpen(false);
  };

  const handleOpenContractManagementPopin = () => {
    setContractPopinOpen(true);
    setMenuOpen(false);
  };

  const handleCloseContractManagementPopin = () => {
    setContractPopinOpen(false);
    setCurrentPage('contractPeriodicity');
  };

  const handleValidatePeriodicity = (newPeriodicity) => {
    setCurrentPage('confirmation');
    setPeriodicity(newPeriodicity);
  };

  const handleConfirm = async () => {
    await policyApi.updatePeriodicity(
      userState.auth,
      police.policyNumber,
      periodicity.code
    );
    setCurrentPage('result');
  };

  const handleValidateTermination = async (date, reason) => {
    try {
      setTerminationLoading(true);
      await policyApi.terminatePolice(userState.auth, police.policyNumber, {
        reason,
        terminationDate: date,
      });

      enqueueSnackbar(t('dashboard.police.termination.successMessage'), {
        variant: 'succes',
      });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setTerminationLoading(false);
      setTerminationPopinOpen(false);
      history.push('/polices');
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (userState.auth.isAuthenticated) {
          const res = await policyApi.getPolice(userState.auth, idPolice);
          const polichab = await policyApi.getPolichab(
            userState.auth,
            idPolice,
            res.contractType.code
          );
          setPolice(res);
          setPolichab(polichab);
        }
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.auth.isAuthenticated]);

  useEffect(() => {
    if (police && location.state && location.state.hash === 'beneficiaries') {
      sectionRefs.beneficiaries.ref.current.scrollIntoView();
    }
  }, [police, location]);

  const showCertificates = () => {
    return ![statuses.ENDED, statuses.PENDING].includes(policyStatus);
  };

  const getChildren = async () => {
    try {
      const [childrenList, roommatesList] = await Promise.all([
        customerApi.getChildren(userState.auth, police.customer.customerNumber),
        policyApi.getPersons(
          userState.auth,
          police.policyNumber,
          'COLOCATAIRE'
        ),
      ]);

      setchildren(childrenList);
      setRoommates(roommatesList);
    } catch (error) {
      console.log(t('dashboard.police.attestation.error_get_enfant'), error);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (police) {
      const newPolicyStatus = getPolicyStatus(police);
      setPolicyStatus(newPolicyStatus);
      setShowTerminationButton(
        userState.rights.includes('POLICY_TERMINATION') &&
          (newPolicyStatus === statuses.ACTIVE ||
            newPolicyStatus === statuses.NOT_STARTED)
      );
      getChildren();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [police, userState]);

  useEffect(() => {
    setBeneficiaries([...children, ...roommates]);
  }, [children, roommates]);

  const getPolicyTypeLabel = (isPending) => {
    const {
      contractType: { code },
    } = police;
    const { housingType } = polichab.result;
    const label = `dashboard.police.policyTypes.${code.toLowerCase()}${housingType}`;

    return isPending ? t(`${label}Quotation`) : t(label);
  };

  const pages = [
    {
      component: ContractPeriodicity,
      key: 'contractPeriodicity',
      onValidate: handleValidatePeriodicity,
    },
    {
      component: Confirmation,
      key: 'confirmation',
      periodicity: periodicity,
      onValidate: handleConfirm,
      previousPage: () => 'contractPeriodicity',
    },
    {
      component: Result,
      key: 'result',
    },
  ];

  const getTitle = () => {
    switch (currentPage) {
      case pages[0].key:
        return t('dashboard.police.paymentPeriodicity.title');
      case pages[1].key:
        return t('dashboard.police.paymentPeriodicity.contractUpdate');
      default:
        return t('dashboard.police.paymentPeriodicity.contractUpdate');
    }
  };

  return (
    <>
      <Box mb={10}>
        {/* Top bar header */}
        {police && polichab ? (
          <Header
            title={getPolicyTypeLabel(policyStatus === statuses.PENDING)}
            subtitle={police.policyKey}
            mainAction={
              <Box display="flex">
                {[statuses.ACTIVE, statuses.NOT_STARTED].includes(
                  policyStatus
                ) && (
                  <Box mr={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CancelPresentationIcon />}
                      onClick={handleOpenTerminationPopin}
                    >
                      {t('dashboard.policies.terminateContract')}
                    </Button>
                  </Box>
                )}
                <>
                  <Button
                    ref={anchor}
                    variant="contained"
                    color="primary"
                    startIcon={<TuneIcon />}
                    endIcon={<ArrowDownIcon />}
                    onClick={() => setMenuOpen(true)}
                  >
                    {t('dashboard.policies.manageContract')}
                  </Button>
                  <Menu
                    classes={{ paper: classes.menu }}
                    anchorEl={anchor.current}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    keepMounted
                    open={menuOpen}
                    onClose={() => {
                      setMenuOpen(false);
                    }}
                  >
                    {Object.keys(sectionRefs)
                      .filter(
                        (key) =>
                          !sectionRefs[key].lienSection ||
                          PAGE_SECTION_SHOW[police.contractType.code][
                            sectionRefs[key].lienSection
                          ]
                      )
                      .map((item, index) => (
                        <MenuItem
                          key={index}
                          className={classes.menuItem}
                          onClick={() => handleClickMenu(item)}
                        >
                          {t(sectionRefs[item].menuLabel)}
                        </MenuItem>
                      ))}
                    {[statuses.ACTIVE].includes(policyStatus) &&
                      PAGE_SECTION_SHOW[police.contractType.code]
                        .changePeriodicity && (
                        <MenuItem
                          className={classes.menuItem}
                          onClick={handleOpenContractManagementPopin}
                        >
                          {t('dashboard.policies.managePaymentPeriodicity')}
                        </MenuItem>
                      )}
                  </Menu>
                </>
              </Box>
            }
            refAction={
              <Box ml={1}>
                <IconButton
                  ref={anchor}
                  color="secondary"
                  onClick={() => {
                    setIsMenuOpen(true);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchor.current}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  keepMounted
                  open={isMenuOpen}
                  onClose={() => {
                    setIsMenuOpen(false);
                  }}
                >
                  {showTerminationButton && (
                    <MenuItem onClick={handleOpenTerminationPopin}>
                      {t('dashboard.policies.terminateContract')}
                    </MenuItem>
                  )}
                  {Object.keys(sectionRefs)
                    .filter(
                      (key) =>
                        !sectionRefs[key].lienSection ||
                        PAGE_SECTION_SHOW[police.contractType.code][
                          sectionRefs[key].lienSection
                        ]
                    )
                    .map((item, index) => (
                      <MenuItem
                        key={index}
                        className={classes.menuItem}
                        onClick={() => handleClickMenu(item)}
                      >
                        {t(sectionRefs[item].menuLabel)}
                      </MenuItem>
                    ))}
                  {[statuses.ACTIVE].includes(policyStatus) && (
                    <MenuItem
                      className={classes.menuItem}
                      onClick={handleOpenContractManagementPopin}
                    >
                      {t('dashboard.policies.managePaymentPeriodicity')}
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            }
          />
        ) : (
          <HeaderSkeleton />
        )}
        <Box className={classes.policyContainer}>
          {police &&
            PAGE_SECTION_SHOW[police.contractType.code].alertHamon &&
            police?.informationPoliceHamon?.terminationTodo && (
              <AlertHamon police={police} />
            )}

          {/* Contract details */}
          <Grid container>
            <Box className={classes.container} width="100%">
              <div ref={sectionRefs.contractDetail.ref}>
                <Grid container xs="12">
                  {police && polichab ? (
                    PAGE_SECTION_SHOW[police.contractType.code]
                      .contractDetail && (
                      <ContractDetails policy={police} polichab={polichab} />
                    )
                  ) : (
                    <ContractDetailsSkeleton />
                  )}
                  {police && polichab ? (
                    PAGE_SECTION_SHOW[police.contractType.code].myCriteria && (
                      <MyCriteria polichab={polichab} police={police} />
                    )
                  ) : (
                    <MyCriteriaSkeleton />
                  )}
                  {police && polichab ? (
                    PAGE_SECTION_SHOW[police.contractType.code].selection && (
                      <MySelection policy={police} polichab={polichab} />
                    )
                  ) : (
                    <MySelectionSkeleton />
                  )}
                  {police &&
                    PAGE_SECTION_SHOW[police.contractType.code].cards &&
                    police.cardInfo && (
                      <div
                        style={{ width: '100%' }}
                        ref={sectionRefs.cards.ref}
                      >
                        <CardSelection policy={police} />
                      </div>
                    )}
                </Grid>
              </div>
            </Box>
          </Grid>

          {/* Beneficiaries */}
          {police && PAGE_SECTION_SHOW[police.contractType.code].beneficiaries && (
            <div ref={sectionRefs.beneficiaries.ref}>
              <Beneficiaries
                popinOpen={beneficiaryPopinOpen}
                openPopin={setBeneficiaryPopinOpen}
                beneficiaries={beneficiaries}
                fetchChildren={getChildren}
                policyNumber={police.policyNumber}
              />
            </div>
          )}
          {/* Attestations part */}
          {police ? (
            showCertificates() &&
            polichab &&
            PAGE_SECTION_SHOW[police.contractType.code].certificates && (
              <Certificates
                policy={police}
                user={userInfos}
                auth={userState.auth}
                beneficiaries={beneficiaries}
                polichab={polichab}
              />
            )
          ) : (
            <CertificateBeneficiarySkeleton />
          )}
          {/* Quittances part */}
          {police ? (
            PAGE_SECTION_SHOW[police.contractType.code].quittances && (
              <div ref={sectionRefs.quittances.ref}>
                <Quittances
                  police={police}
                  user={userState}
                  auth={userState.auth}
                  refresh={refresh}
                />
              </div>
            )
          ) : (
            <QuittancesSkeleton />
          )}
          {/* Guarantees part */}
          {police && PAGE_SECTION_SHOW[police.contractType.code].guarantees && (
            <div ref={sectionRefs.garanties.ref}>
              <Guarantees
                police={police}
                typeGuarantee={police.contractType.code}
              />
            </div>
          )}
          {/* Sinistres part */}
          {police && PAGE_SECTION_SHOW[police.contractType.code].sinisters && (
            <div ref={sectionRefs.sinisters.ref}>
              <Sinisters
                police={police}
                auth={userState.auth}
                showBtnCreate={
                  ![statuses.PENDING, statuses.NOT_STARTED].includes(
                    policyStatus
                  )
                }
              />
            </div>
          )}
          {/* General conditions part */}
          {police &&
            PAGE_SECTION_SHOW[police.contractType.code].generalConditions && (
              <div ref={sectionRefs.documents.ref}>
                <GeneralConditions police={police} />
              </div>
            )}

          {police && (
            <TerminationPopin
              open={terminationPopinOpen}
              onClose={handleCloseTerminationPopin}
              maxWidth="sm"
              showCancelButton={true}
              onValidate={handleValidateTermination}
              loading={terminationLoading}
              police={police}
            />
          )}
        </Box>
      </Box>

      <PopinMultiPages
        open={contractPopinOpen}
        onClose={handleCloseContractManagementPopin}
        enableTitleArea={true}
        maxWidth="md"
        pages={pages}
        title={getTitle()}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </>
  );
};

export default PolicyPage;
