import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fade from '@material-ui/core/Fade';
import Fab from '@material-ui/core/Fab';
import Checkbox from '@material-ui/core/Checkbox';
// import PopinMultiPages from 'components/PopinMultiPages';

import GarantiesImage from 'assets/images/garanties.svg';
import getHumanReadableString from 'utils/getHumanReadableString';
import Advantages from 'components/Advantages';
import Guarantees from 'components/Guarantees/index';
import Popin from 'components/Popin';
import Price from 'components/Price';
import performantGuaranteesList from 'constants/performantGuarantees/pno';
import guaranteesList from 'constants/guarantees/pno';
import { PnoContext } from 'contexts/pno';
import { firstLetterUppercase } from 'utils';
import _get from 'lodash/get';
import BienAssureInfo from './BienAssureInfo';
import Exclusions from './Exclusions';
import { stepsIds } from 'constants/steps/pno';
import './QuoteStep.scss';
import useStyles from './style';
import { PNO_CODE_RISQUE, TYPE_POLICE_PNO } from 'constants/index';

const QuoteStepDetails = (props) => {
  const { data, rating, onConfirm, stay, parentRef } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const topRef = React.useRef(null);

  const theme = useTheme();
  const isDownXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [goToTopActive, setGoToTopActive] = useState(false);
  const [error, setError] = useState(null);

  const { dispatch } = useContext(PnoContext);

  const [price, setPrice] = useState();
  const [showExclusionPopin, setShowExclusionPopin] = useState(false);

  const { tarifPropose, tarifDeBase } = rating;
  const { proposition } = tarifPropose;
  let propositionDeBase = null;

  if (tarifDeBase) {
    propositionDeBase = tarifDeBase.proposition;
  }

  const handleScroll = () => {
    const activate =
      parentRef.current.scrollTop > parentRef.current.offsetHeight * 0.35;
    setGoToTopActive(activate);
  };

  useEffect(() => {
    const currentRef = parentRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
      return () => {
        currentRef.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    if (proposition) {
      const { annualTtcWithSurtaxe, annualSurtaxe } = proposition;
      const thePrice = {
        annualTTC: annualTtcWithSurtaxe,
        annualSurtaxe: annualSurtaxe,
        annualTTCDeBase: propositionDeBase
          ? propositionDeBase.annualTtcWithSurtaxe
          : null,
      };

      setPrice(thePrice);

      dispatch({
        type: 'SET_DATA',
        payload: {
          key: 'price',
          value: thePrice,
        },
      });

      dispatch({
        type: 'SET_DATA',
        payload: { key: 'riskRate', value: proposition.riskRate },
      });
    } else {
      if (rating.code) {
        setError(rating.code);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rating]);

  const goToStep = (stepId) => {
    dispatch({
      type: 'SET_STEP',
      payload: { stepId, skipSteps: true },
    });
  };

  const guaranteesRef = React.useRef(null);

  const handleGoToTop = () => {
    topRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  const showExclusions = () => {
    setShowExclusionPopin(true);
  };

  const closeExclusions = () => {
    setShowExclusionPopin(false);
  };

  const handleValidateExclusions = () => {
    dispatch({
      type: 'SET_DATA',
      payload: {
        key: 'exclusionChecked',
        value: true,
      },
    });
    closeExclusions();
  };

  const handleRejectExclusions = () => {
    dispatch({
      type: 'SET_DATA',
      payload: {
        key: 'exclusionChecked',
        value: false,
      },
    });

    dispatch({
      type: 'SET_STEP',
      payload: {
        stepId: stepsIds.EXIT,
        skipSteps: true,
      },
    });
  };

  return (
    <Grid className="EndStepPno" ref={topRef}>
      {!isDownXs && (
        <Fade in={goToTopActive}>
          <Fab
            color="primary"
            aria-label="add"
            classes={{
              root: isDownXs ? classes.goToTopMobile : classes.goToTop,
            }}
            onClick={handleGoToTop}
          >
            <Icon className="fas fa-arrow-up" />
          </Fab>
        </Fade>
      )}

      {price && (
        <div className={`${classes.summary} ${classes.summaryActive}`}>
          <Grid container className={classes.summaryContentWrapper}>
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              classes={{ root: classes.priceContainer }}
            >
              <Price
                price={price}
                size="small"
                periodicity={'A'}
                namely={false}
              />
            </Grid>
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              classes={{ root: classes.exclusionContainer }}
            >
              <Typography
                classes={{ root: classes.exclusionText }}
                onClick={showExclusions}
              >
                {t('pno.quoteStep.exclusions.question')}
              </Typography>
              <Checkbox
                checked={!!data.exclusionChecked}
                classes={{ root: classes.checkBox }}
                onClick={showExclusions}
              />
            </Grid>
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              classes={{ root: classes.buttonContainer }}
            >
              {!stay && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onConfirm}
                  endIcon={<ArrowForwardIcon />}
                  disabled={!data.exclusionChecked}
                >
                  {firstLetterUppercase(t('common.continue'))}
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      )}
      <div className="EndStepPno_Hero">
        <Container maxWidth="md">
          <Grid
            container
            alignItems="center"
            classes={{ root: classes.headerWrapper }}
          >
            <Grid
              container
              direction="column"
              xs={12}
              md={error ? 12 : 6}
              item
              justifyContent="center"
            >
              <span>
                <Typography variant="h3" color="textPrimary" component="span">
                  {`${t('steps.quoteStep.thankYou')} `}
                </Typography>
                <Typography variant="h3" color="primary" component="span">
                  {`${firstLetterUppercase(
                    data.contact.firstName.toLowerCase()
                  )}`}
                  ,
                </Typography>
              </span>
              <br />
              {error ? (
                <Typography
                  classes={{ root: classes.error }}
                  variant="h6"
                  component="span"
                >
                  {t(`error.rating.${error}`)}
                </Typography>
              ) : (
                <BienAssureInfo data={data} goToStep={goToStep}>
                  <Typography variant="h6" color="textPrimary" component="span">
                    {`${t('pno.quoteStep.bestOffer')} `}
                  </Typography>
                </BienAssureInfo>
              )}
            </Grid>
            {price && (
              <Grid
                container
                item
                xs={12}
                md={6}
                alignItems="center"
                justifyContent={isDownSm ? 'center' : 'flex-end'}
                classes={{ root: classes.priceTopContainer }}
              >
                <div>
                  <Card className="EndStepPno_Hero_CardPrice">
                    <CardContent>
                      <Price
                        price={price}
                        size="big"
                        periodicity={'A'}
                        namely={false}
                      />
                      <div className="EndStepPno_Hero_CardPrice_AddressCta">
                        <Typography variant="body1">
                          <Trans
                            i18nKey="pno.quoteStep.forYourLodging"
                            values={{
                              interpolation: { escapeValue: false },
                              propertyType: getHumanReadableString(
                                'housingType',
                                t,
                                data.housingType
                              ).toLowerCase(),
                            }}
                          />
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          className="EndStepPno_Hero_CardPrice_AddressCta_Address"
                        >
                          {`${_get(data, 'contractAddress.address1', '')}`}
                          {data.contractAddress.address2 && <br />}
                          {`${_get(data, 'contractAddress.address2', '')}`}
                          {data.contractAddress.residency && <br />}
                          {data.contractAddress.residency &&
                            `${t('common.residency')} ${_get(
                              data,
                              'contractAddress.residency',
                              ''
                            )}`}
                          {data.contractAddress.building && <br />}
                          {data.contractAddress.building &&
                            ` ${t('common.building')} ${_get(
                              data,
                              'contractAddress.building',
                              ''
                            )}`}
                          <br />
                          {` ${t('common.at')} ${_get(
                            data,
                            'contractAddress.city',
                            ''
                          )}`}
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            )}
          </Grid>
        </Container>
      </div>
      <Grid
        className="EndStepPno_Content"
        alignItems="center"
        justifyContent="center"
        container
        direction="column"
        ref={guaranteesRef}
      >
        <div className="EndStepPno_Content_Guarantees">
          <Guarantees
            guarantees={guaranteesList(t)}
            typeGuarantee={TYPE_POLICE_PNO}
            codeTarif={data?.riskRate?.code}
            codeRisque={PNO_CODE_RISQUE}
          />
        </div>
        <div className="EndStepPno_Content_Advantages">
          <Advantages
            image={GarantiesImage}
            title={t('common.assurimoAdvantages')}
            guarantees={performantGuaranteesList(t)}
            lg={4}
          />
        </div>
      </Grid>
      <Popin
        open={showExclusionPopin}
        onClose={closeExclusions}
        showActionsButtons={false}
        enableTitleArea={true}
        maxWidth="md"
        content={
          <Exclusions
            onValidate={handleValidateExclusions}
            onCancel={handleRejectExclusions}
          />
        }
        title={t('pno.quoteStep.exclusions.title')}
        showBackIcon={false}
        titleAreaOnMobile={true}
      />
    </Grid>
  );
};

QuoteStepDetails.propTypes = {
  data: PropTypes.object.isRequired,
  rating: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  stay: PropTypes.bool,
  parentRef: PropTypes.any,
};

export default QuoteStepDetails;
