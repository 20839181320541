import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputsStep from 'components/InputsStep/InputsStep';
import Title from 'components/Title';
import { isEmpty } from 'utils/form-validation';
import { PNO_TYPE_MAISON } from 'constants/common/bienType';
import HouseLottie from 'assets/lotties/house.json';
import ApartmentLottie from 'assets/lotties/apartment.json';

const PropertyTypeStep = ({ data, onConfirm, setUserData }) => {
  const { t } = useTranslation();
  return (
    <>
      <Title title={t('steps.propertyInfoStep.title')} />
      <InputsStep
        onChange={setUserData}
        onConfirm={onConfirm}
        lottie={
          data.housingType === PNO_TYPE_MAISON ? HouseLottie : ApartmentLottie
        }
        isFloor={true}
        fields={[
          {
            id: 'area',
            label: t('steps.propertyInfoStep.area'),
            type: 'number',
            invalid:
              isEmpty(data.area) ||
              parseInt(data.area) < 5 ||
              parseInt(data.area) > 250,
            helperText: (() => {
              if (isEmpty(data.area)) {
                return t('error.fields.fieldRequired');
              }

              if (parseInt(data.area) < 5) {
                return t('steps.propertyInfoStep.errors.areaTooSmall');
              }

              if (parseInt(data.area) > 250) {
                return t('steps.propertyInfoStep.errors.areaTooBig');
              }
            })(),
            value: data.area || '',
          },
          {
            id: 'numberOfPrincipalRooms',
            label: t('steps.propertyInfoStep.numberOfRooms'),
            info: t('steps.propertyInfoStep.mainRoomsInfo'),
            type: 'number',
            invalid:
              isEmpty(data.numberOfPrincipalRooms) ||
              parseInt(data.numberOfPrincipalRooms) < 1 ||
              parseInt(data.numberOfPrincipalRooms) > 8,
            helperText: (() => {
              if (isEmpty(data.numberOfPrincipalRooms)) {
                return t('error.fields.fieldRequired');
              }

              if (parseInt(data.numberOfPrincipalRooms) < 1) {
                return t('steps.propertyInfoStep.errors.numberOfRoomsTooSmall');
              }

              if (parseInt(data.numberOfPrincipalRooms) > 8) {
                return t('steps.propertyInfoStep.errors.numberOfRoomsTooBig');
              }
            })(),
            value: parseInt(data.numberOfPrincipalRooms) || '',
          },
        ]}
      />
    </>
  );
};

PropertyTypeStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
};

export default PropertyTypeStep;
