import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Lottie from 'components/Lottie';
import _get from 'lodash/get';
import getHumanReadableString from 'utils/getHumanReadableString';
import { HOUSING_TYPE_MAISON } from 'constants/index';
import HouseLottie from 'assets/lotties/house.json';
import useStyles from '../style';

const MyGood = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const { data } = props;

  const getEquipementsString = () => {
    return data.equipements
      .map((equipment) => getHumanReadableString(equipment, t))
      .join(', ');
  };

  const isMaleOrfemale =
    data.housingType === HOUSING_TYPE_MAISON ? 'female' : 'male';

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid item xs={12}>
      <Card classes={{ root: classes.reminderCard }}>
        <CardContent align={mobile ? 'center' : 'left'}>
          <Grid
            container
            direction={mobile ? 'column' : 'row'}
            alignItems="center"
          >
            {/* <Typography variant="h6" color="textPrimary">
            {t('steps.resultStep.myGood')}
          </Typography> */}
            <Grid item xs={12} md={4}>
              <Lottie animationData={HouseLottie} isActive={true} />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" component="span">
                {t('steps.resultStep.myGoodInfos.wantInsurance')}{' '}
              </Typography>
              <Typography variant="body2" component="span">
                {t('common.accomodation')}{' '}
              </Typography>
              <Typography variant="body1" component="span">
                {t('steps.resultStep.myGoodInfos.whereIAm')}{' '}
              </Typography>
              <Typography variant="body2" component="span">
                {' '}
                {getHumanReadableString(
                  'assuredQuality',
                  t,
                  data.assuredQuality
                ).toLowerCase()}
              </Typography>
              {'. '}
              <Typography variant="body1" component="span">
                {t('steps.resultStep.myGoodInfos.isLocated')}{' '}
              </Typography>
              <Typography variant="body2" component="span">
                {`${_get(data, 'contractAddress.address1', '')} ${_get(
                  data,
                  'contractAddress.address2',
                  ''
                )} ${_get(data, 'contractAddress.city', '')}`}
                {'. '}
              </Typography>
              <br />
              <br />
              <Typography variant="body1" component="span">
                {t('steps.resultStep.myGoodInfos.this', {
                  context: isMaleOrfemale,
                })}{' '}
              </Typography>
              <Typography variant="body2" component="span">
                {getHumanReadableString(
                  'housingType',
                  t,
                  data.housingType
                ).toLowerCase()}{' '}
              </Typography>
              <Typography variant="body1" component="span">
                {t('steps.resultStep.myGoodInfos.of')}{' '}
              </Typography>
              <Typography variant="body2" component="span">
                {`${data.area} m²`}{' '}
              </Typography>
              <Typography variant="body1" component="span">
                {t('steps.resultStep.myGoodInfos.isMadeUp', {
                  context: isMaleOrfemale,
                })}{' '}
              </Typography>
              <Typography variant="body2" component="span">
                {t('steps.resultStep.myGoodInfos.room', {
                  count: parseInt(data.numberOfPrincipalRooms),
                })}{' '}
              </Typography>
              {data.equipements?.length > 0 && (
                <>
                  <Typography variant="body1" component="span">
                    {t('steps.resultStep.myGoodInfos.benefitsEquipments', {
                      count: data.equipements.length,
                    })}
                    {' : '}
                  </Typography>

                  <Typography variant="body2" component="span">
                    {getEquipementsString()}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

MyGood.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default MyGood;
