import { Box, Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';
import Loading from 'components/Loadding/Loading';
import Header from 'components/DashboardHeader';
import FormResilHamon from './components/FormResilHamon';
import FileInputAdd from './components/FileInputAdd';
import policyApi from 'api/policyApi';
import PoliceInfo from './components/PoliceInfo';
import useStyles from './styles';
import Popin from 'components/Popin/index';
import drsApi from 'api/drsApi';
import { addUnitTime } from 'utils/index';
import { NB_DAYS_LIMIT_RAPPEL_HAMON } from 'constants/index';

const WhiteContainedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.orange.main,
    backgroundColor: `${theme.palette.white.main} !important`,
  },
}))(Button);

const TYPE_DRS_COMPAGNIE_ASSURANCE = 'COMPAGNIE_ASSURANCE';

function ResilHamon() {
  const {
    params: { id: policyNumber },
  } = useRouteMatch();
  const classes = useStyles();

  const { authState } = useOktaAuth();

  const [files, setFiles] = useState([]);
  const [police, setPolice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({});
  const [error, setError] = useState({});
  const [optionsPopin, setOptionsPopin] = useState({
    open: false,
  });
  const [listSoc, setListSoc] = useState([]);
  const [auth, setAuth] = useState(null);

  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      const {
        isAuthenticated,
        accessToken: { accessToken: token },
      } = authState;
      const auth = { isAuthenticated, token };
      setAuth(auth);

      try {
        const policePromise = policyApi.getPolice(auth, policyNumber);
        const listSocPromise = drsApi.list(auth, TYPE_DRS_COMPAGNIE_ASSURANCE);

        const policeRes = await policePromise;
        checkPoliceHamonValid(policeRes);
        setPolice(policeRes);

        const listSocRes = await listSocPromise;
        setListSoc(listSocRes);
      } catch (error) {
        enqueueSnackbar(t('dashboard.resilHamon.form.message.error'), {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [authState]);

  const checkPoliceHamonValid = (policyInfo) => {
    if (!policyInfo?.informationPoliceHamon?.terminationTodo) {
      history.push(`/polices/${policyNumber}`);
      return;
    }

    const dateLimitRappel = addUnitTime(policyInfo.startDate, {
      nbDays: -1 * NB_DAYS_LIMIT_RAPPEL_HAMON,
    });
    if (!dateLimitRappel || new Date().getTime() > dateLimitRappel.getTime())
      history.push(`/polices/${policyNumber}`);
  };

  const messagePopinSuccess = () => {
    setOptionsPopin({
      open: true,
      title: t('dashboard.resilHamon.form.message.title'),
      content: (
        <Box margin={3}>
          <Typography variant="body1">
            {t('dashboard.resilHamon.form.message.msg')}
          </Typography>
        </Box>
      ),
      onClose: () => {
        handlePopinClose();
        history.push(`/polices/${policyNumber}`);
      },
    });
  };

  const handleConfirm = () => {
    if (isFormValid()) {
      setOptionsPopin({
        open: true,
        title: t('common.popin.confirm_title'),
        onValidate: handleValidate,
        validateButtonLabel: t('common.send'),
        showCancelButton: true,
        content: (
          <Box margin={3}>
            <Typography variant="body1">
              {t('dashboard.resilHamon.form.message.confirm')}
            </Typography>
          </Box>
        ),
      });
    }
  };

  const handlePopinClose = () => {
    setOptionsPopin({
      ...optionsPopin,
      open: false,
    });
  };

  const handleChangeForm = (newForm) => {
    setForm(newForm);
  };

  const handleChangeFile = ({ target: { files: newFiles } }) => {
    const newfile = {
      name: newFiles[0].name,
      file: newFiles[0],
      type: 'POL_ATT',
    };

    setFiles([...files, newfile]);
  };

  const handleRemoveFile = (index) => {
    const newFiles = files;
    newFiles.splice(index, 1);

    setFiles([...newFiles]);
  };

  const isFormValid = () => {
    const errorObj = {
      hasError: !form.inssuranceId || !form.ancienPolicyNumber,
      msgError: t('dashboard.resilHamon.form.message.error_form'),
    };

    setError(errorObj);
    return !errorObj.hasError;
  };

  const handleValidate = async () => {
    try {
      setLoading(true);

      const promises = allFileUploadToPolice();
      promises.push(policyApi.resilHamon(auth, policyNumber, form));

      await Promise.all(promises);
      messagePopinSuccess();
    } catch (error) {
      enqueueSnackbar(t('dashboard.resilHamon.form.message.error'), {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const allFileUploadToPolice = () => {
    if (!files || !files.length) return [];

    return files.map((file) => {
      const formData = new FormData();
      formData.append('file', file.file);
      return policyApi.uploadFileToGed(auth, policyNumber, file.type, formData);
    });
  };

  return (
    <Box mb={10}>
      
      {loading && <Loading />}
      {!loading && police && (<>
        <Header title={t(`dashboard.resilHamon.form.header.title_${police?.contractType?.code}`)} />
        <Box className={classes.policyContainer}>
          <Grid container>
            <Box
              className={classes.container}
              width={1}
              display="flex"
              justifyContent="center"
            >
              <Grid container xs={12} lg={8}>
                <Box className={classes.boxPolice}>
                  <Typography
                    variant="h5"
                    color="secondary"
                    className="typography"
                  >
                    {t('dashboard.resilHamon.form.police.label')}
                  </Typography>
                  {police && (
                    <PoliceInfo displayLinkButton={false} police={police} />
                  )}
                </Box>
                <Box className={`${classes.box} boxForm`}>
                  <FormResilHamon
                    form={form}
                    onChange={handleChangeForm}
                    error={error}
                    items={listSoc}
                    data={police.informationPoliceHamon}
                  />
                </Box>
                {!police.informationPoliceHamon.terminationDo && (
                  <Box className={classes.box}>
                    <FileInputAdd
                      files={files}
                      onRemove={handleRemoveFile}
                      onChange={handleChangeFile}
                    />
                  </Box>
                )}
              </Grid>
            </Box>
          </Grid>
          {!police.informationPoliceHamon.terminationDo && (
            <Grid container>
              <Box
                display="flex"
                width={1}
                alignItems="center"
                flexDirection="column"
                className={classes.confirmContainer}
              >
                <Box my={3} width={1} display="flex" justifyItems="center">
                  <Grid container justifyContent="center">
                    <WhiteContainedButton
                      align="center"
                      variant="contained"
                      color="primary"
                      onClick={handleConfirm}
                    >
                      {t('dashboard.resilHamon.form.info.send')}
                    </WhiteContainedButton>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          )}
        </Box>
        </>)}

      <Popin onClose={handlePopinClose} maxWidth="sm" {...optionsPopin} />
    </Box>
  );
}

export default ResilHamon;
