import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import _get from 'lodash/get';

import { UserContext } from 'contexts/user';
import useStyles from './style';

const Currentuser = () => {
  const classes = useStyles();
  const [user] = React.useContext(UserContext);

  return (
    <Box p={2} className={classes.container} width="100%">
      <Grid container item xs={12}>
        <Box mb={2}>
          <Typography variant="h5" color="secondary">
            {`${user.user.firstName} ${user.user.lastName}`}
          </Typography>
        </Box>
        <Grid item container>
          <Grid item container xs={12} md={9}>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <Typography variant="caption" className={classes.infoLabel}>
                  Adresse
                </Typography>
                <Typography variant="body2">
                  {`${_get(user.address, 'address1', '') || ''} ${
                    _get(user.address, 'address2', '') || ''
                  }${_get(user.address, 'address3', '') || ''} ${_get(
                    user.address,
                    'zipCode',
                    ''
                  )} ${_get(user.address, 'city', '')} `}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="caption" className={classes.infoLabel}>
                N° de téléphone mobile
              </Typography>
              <Typography variant="body2">
                {user.contact.mobilePhone}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="caption" className={classes.infoLabel}>
                Adresse mail
              </Typography>
              <Typography variant="body2">{user.login}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={3}>
            <Box display="flex" justifyContent="flex-end" width={1}>
              <Card className={classes.card}>
                <div className={classes.cardContent}>
                  <Typography
                    variant="subtitle2"
                    className={classes.clientNumberLabel}
                  >
                    N° Client Assurimo
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    className={classes.clientNumber}
                  >
                    {user.customerNumber}
                  </Typography>
                </div>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Currentuser;
