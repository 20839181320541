import React, { useEffect, useRef, useState, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import HeaderSkeleton from 'components/DashboardHeader/skeleton';
import { UserContext } from 'contexts/user';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import PolicyHeader from './PolicyHeader';
import Beneficiaries from './Beneficiaries';
import Certificates from './Certificates';
import CertificateBeneficiarySkeleton from './Certificates/skeleton';
import Quittances from './quittance/Quittances';
import QuittancesSkeleton from './quittance/skeleton';
import Guarantees from './Guarantees';
import Sinisters from './Sinisters/index';
import AlertHamon from './AlertHamon/index';
import GeneralConditions from './GeneralConditions';
import PolicyDetails from './PolicyDetails';
import policyApi from 'api/policyApi';

import useStyles from './style';
import { getPolicyStatus, PAGE_SECTION_SHOW, policeStatuses } from './helper';
import {
  POLICE_MOUVEMENT_AN,
  POLICE_MOUVEMENT_ANNP,
  POLICE_MOUVEMENT_RE,
  POLICE_MOUVEMENT_RESEF,
} from 'constants';

const PolicyPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const location = useLocation();
  const classes = useStyles();
  const [userState] = useContext(UserContext);
  const params = useParams();
  const idPolice = params.id;

  const [police, setPolice] = useState(null);
  const [polichab, setPolichab] = useState(null);
  const [beneficiaries, setBeneficiaries] = useState([]);

  const statuses = policeStatuses;
  const sectionRefs = {
    contractDetail: {
      ref: useRef(),
      menuLabel: 'dashboard.police.details.title_menu',
    },
    cards: {
      ref: useRef(),
      menuLabel: 'dashboard.card.police.title',
      lienSection: 'cards',
    },
    beneficiaries: {
      ref: useRef(),
      menuLabel: 'dashboard.police.beneficiaries.title',
      lienSection: 'beneficiaries',
    },
    quittances: {
      ref: useRef(),
      menuLabel: 'dashboard.police.premiums.premiums_title',
    },
    garanties: {
      ref: useRef(),
      menuLabel: 'dashboard.police.guarantees.title',
    },
    sinisters: {
      ref: useRef(),
      menuLabel: 'dashboard.sinistre.list.title',
    },
    documents: {
      ref: useRef(),
      menuLabel: 'dashboard.relatedDocuments.title',
    },
  };

  useEffect(() => {
    (async () => {
      try {
        if (userState.auth.isAuthenticated) {
          const res = await policyApi.getPolice(userState.auth, idPolice);
          const polichab = await policyApi.getPolichab(
            userState.auth,
            idPolice,
            res.contractType.code
          );
          setPolice(res);
          setPolichab(polichab);
        }
      } catch (err) {
        handleError(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.auth.isAuthenticated]);

  useEffect(() => {
    if (police && location.state && location.state.hash === 'beneficiaries') {
      sectionRefs.beneficiaries.ref.current.scrollIntoView();
    }
  }, [police, location]);

  const policyStatus = police ? getPolicyStatus(police) : null;

  const handleError = (error, message = 'Error') => {
    console.error(t(message), error);
    const messageError = getMessageError(error);
    enqueueSnackbar(t(messageError), { variant: 'error' });
  }

  return (
    <>
      <Box mb={10}>
        {/* Top bar header */}
        {police && polichab ? (
          <PolicyHeader
            sectionRefs={sectionRefs}
            police={police}
            polichab={polichab}
            policyStatus={policyStatus}
          />
        ) : (
          <HeaderSkeleton />
        )}
        <Box className={classes.policyContainer}>
          {police &&
            PAGE_SECTION_SHOW[police.contractType.code].alertHamon &&
            police?.informationPoliceHamon?.terminationTodo && (
              <AlertHamon police={police} />
            )}

          {/* Contract details */}
          <PolicyDetails
            police={police}
            polichab={polichab}
            sectionRefs={sectionRefs}
          />

          {/* Beneficiaries */}
          {police && PAGE_SECTION_SHOW[police.contractType.code].beneficiaries && (
            <div ref={sectionRefs.beneficiaries.ref}>
              <Beneficiaries
                police={police}
                policyNumber={police.policyNumber}
                beneficiaries={beneficiaries}
                setBeneficiaries={setBeneficiaries}
              />
            </div>
          )}
          {/* Attestations part */}
          {police ? (
            polichab &&
            PAGE_SECTION_SHOW[police.contractType.code].certificates && (
              <Certificates
                policy={police}
                policyStatus={policyStatus}
                auth={userState.auth}
                beneficiaries={beneficiaries}
                polichab={polichab}
              />
            )
          ) : (
            <CertificateBeneficiarySkeleton />
          )}
          {/* Quittances part */}
          {police ? (
            PAGE_SECTION_SHOW[police.contractType.code].quittances && (
              <div ref={sectionRefs.quittances.ref}>
                <Quittances
                  police={police}
                  user={userState}
                  auth={userState.auth}
                />
              </div>
            )
          ) : (
            <QuittancesSkeleton />
          )}
          {/* Guarantees part */}
          {police && PAGE_SECTION_SHOW[police.contractType.code].guarantees && (
            <div ref={sectionRefs.garanties.ref}>
              <Guarantees
                police={police}
                typeGuarantee={police.contractType.code}
              />
            </div>
          )}
          {/* Sinistres part */}
          {police && PAGE_SECTION_SHOW[police.contractType.code].sinisters && (
            <div ref={sectionRefs.sinisters.ref}>
              <Sinisters
                police={police}
                auth={userState.auth}
                showBtnCreate={
                  ![statuses.PENDING, statuses.NOT_STARTED].includes(
                    policyStatus
                  )
                }
              />
            </div>
          )}
          {/* General conditions part */}
          {police &&
            PAGE_SECTION_SHOW[police.contractType.code].generalConditions && (
              <div ref={sectionRefs.documents.ref}>
                <GeneralConditions police={police} />
              </div>
            )}
        </Box>
      </Box>
    </>
  );
};

export default PolicyPage;
