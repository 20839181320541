import { Box, Button, Card, CardContent, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BrokenGlassLottie from 'assets/lotties/claims/brokenGlass.json';
import EvenementClimatiqueLottie from 'assets/lotties/claims/climate.json';
import FireLottie from 'assets/lotties/claims/fire.json';
import OtherLottie from 'assets/lotties/claims/other.json';
import VandalismLottie from 'assets/lotties/claims/vandalism.json';
import WaterLottie from 'assets/lotties/claims/water.json';
import Lottie from 'components/Lottie';
import {
  SINISTRE_NATURE_CODE_AUTRE,
  SINISTRE_NATURE_CODE_BRIS_DE_GLACE,
  SINISTRE_NATURE_CODE_DEGAT_DES_EAUX,
  SINISTRE_NATURE_CODE_INCENDIE,
  SINISTRE_NATURE_CODE_VANDALISME,
  SINISTRE_NATURE_CODE_VOL,
} from 'constants/common/sinistreNatureCode';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CLAIMS_STATUS_CODE } from 'constants/common/sinistreStatusCode';
import { formatDateWithSystem } from 'utils';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Link } from 'react-router-dom';

SinisterListItem.propTypes = {
  sinister: PropTypes.object,
};

const { CLOSS,  CLOSTER, EC, PREDECLA, PARCOURS } = CLAIMS_STATUS_CODE;

const useStyles = makeStyles((theme) => ({
  rootCard: {
    backgroundColor: theme.palette.minBlue.main,
  },
  info: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  contract: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
    },
  },
  card: {
    boxShadow: '0px 4px 80px rgba(0, 43, 104, 0.1)',
    borderRadius: 10,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 46,
    '&.inProgress': {
      background: theme.palette.orange.main,
    },
    '&.close': {
      background: theme.palette.lightBlue.main,
    }
  },
  cardFooter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  cardButton: {
    marginTop: theme.spacing(1),
  },
  statusCard: {
    width: 236,
    [theme.breakpoints.down('sx')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    '& .inProgress': {
      cursor: 'pointer',
    }
  },
  statusText: {
    color: theme.palette.white.main,
    textDecoration: "none",
    boxShadow: "none"
  },
}));

const sinistreData = {
  [SINISTRE_NATURE_CODE_INCENDIE]: {
    asset: FireLottie,
    label: 'dashboard.sinistre.form.reason.incendie',
  },
  [SINISTRE_NATURE_CODE_DEGAT_DES_EAUX]: {
    asset: WaterLottie,
    label: 'dashboard.sinistre.form.reason.dde',
  },
  [SINISTRE_NATURE_CODE_VANDALISME]: {
    asset: VandalismLottie,
    label: 'dashboard.sinistre.form.reason.vandalisme',
  },
  [SINISTRE_NATURE_CODE_VOL]: {
    asset: EvenementClimatiqueLottie,
    label: 'dashboard.sinistre.form.reason.vol',
  },
  [SINISTRE_NATURE_CODE_BRIS_DE_GLACE]: {
    asset: BrokenGlassLottie,
    label: 'dashboard.sinistre.form.reason.bdg',
  },
  [SINISTRE_NATURE_CODE_AUTRE]: {
    asset: OtherLottie,
    label: 'dashboard.sinistre.form.reason.other',
  },
};

function SinisterListItem({ sinister }) {
  const classes = useStyles();
  const {
    claimsNumber,
    eventDate,
    status: { code: statusCode },
    claimformLink
    // cost,
  } = sinister;
  const { t } = useTranslation();
  const { asset, label } = sinistreData[sinister.nature.code] || {
    ...sinistreData[SINISTRE_NATURE_CODE_AUTRE],
    label: sinister.nature.code,
  };

  const handleClickRedirectClaimsia = () => {
    window.open(claimformLink, '_self');
  }

  return (
    <Box mb={2} width={1}>
      <Card className={classes.rootCard}>
        <CardContent className={classes.content}>
          <Box
            className={classes.details}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              className={classes.contract}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box ml={2} mr={4} display="flex">
                <Hidden smDown>
                  <Lottie
                    animationData={asset}
                    isActive
                    width={116}
                    height={116}
                  />
                </Hidden>
                <Hidden mdUp>
                  <Lottie
                    animationData={asset}
                    isActive
                    width={80}
                    height={80}
                  />
                </Hidden>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                height={1}
              >
                <Box>
                  <Typography variant="h6">{t(label)}</Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box className={classes.info} mr={3}>
                    <Typography variant="caption">N°</Typography>
                    <Typography variant="body2">{claimsNumber}</Typography>
                  </Box>
                  <Box className={classes.info}>
                    <Typography variant="caption">
                      {t('dashboard.sinistre.list.date_decla')}
                    </Typography>
                    <Typography variant="body2">
                      {formatDateWithSystem(eventDate)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.statusCard}>
              <Box className={classes.cardContainer}>
                <Card className={classes.card}>
                  {[EC.code, PREDECLA.code].includes(statusCode) && (
                    <>
                      <div className={`${classes.cardHeader} inProgress`}>
                        <Typography
                          //variant="subtitle2"
                          className={classes.statusText}
                        >
                          {t('dashboard.sinistre.list.stat_ec')}
                        </Typography>
                      </div>
                      {/* <div className={classes.cardFooter}>
                      <Button align='center' variant='contained' color='primary' size='small' endIcon={<ArrowForwardIcon />} component={Link} to='/sinistres/1'>
                        Suivre
                      </Button>
                    </div> */}
                    </>
                  )}

                  {[CLOSS.code, CLOSTER.code].includes(statusCode) && (
                    <>
                      <div className={`${classes.cardHeader} close`}>
                        <Typography
                          //variant="subtitle2"
                          className={classes.statusText}
                        >
                          {t('dashboard.sinistre.list.stat_close')}
                        </Typography>
                      </div>
                      {/* {
                      cost && (
                        <div className={classes.cardFooter}>
                          <Box>
                            <Typography variant="subtitle-2">{`Règlement de ${cost}€`}</Typography>
                          </Box> */}
                      {/* <Box>
                        <Button align='center' variant='outlined' color='secondary' size='small' endIcon={<ArrowForwardIcon />} component={Link} to='/sinistres/1' className={classes.cardButton}>
                          Détails
                        </Button>
                      </Box> */}
                      {/* </div>
                      )} */}
                    </>
                  )}

                  {[PARCOURS.code].includes(statusCode) && (
                    <>
                      <div className={`${classes.cardHeader} inProgress`} onClick={handleClickRedirectClaimsia}>
                        <Typography
                          variant="subtitle-2"
                          className={classes.statusText}
                        >
                          {t('dashboard.sinistre.list.stat_replay')}
                        </Typography>
                      </div>
                    </>
                  )}
                </Card>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default SinisterListItem;
