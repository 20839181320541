import React, { useReducer, createContext, useEffect, useState } from 'react';
import moment from 'moment';
import _set from 'lodash/set';
import { useHistory, useParams } from 'react-router-dom';

import subscribeApi from 'api/subscribeApi';
import useAuth from 'hooks/useAuth';
import getSteps, { stepsIds } from 'constants/steps/steps';
import { generateUUID } from 'utils/uuid';
import { isEmail } from 'utils/form-validation';
import { persistState, deleteState } from 'utils/persist-state';

const MrhContext = createContext();

const defaultState = {
  currentStep: {
    group: 0,
    step: 0,
  },
  visitedSteps: [stepsIds[Object.keys(stepsIds)[0]]],
  skipSteps: false,
  data: {
    address: {
      address1: '',
      address2: '',
      zipCode: '',
      city: '',
    },
    contractAddress: { address1: '', address2: '', zipCode: '', city: '' },
    contact: {},
    price: {},
    equipements: [],
    residenceType: ['P'],
  },
};

const getStepsWithoutHiddenSteps = (state) => {
  const allSteps = getSteps(state.data);
  const steps = allSteps.filter((step) => !step.hidden);
  return steps;
};

const goToNextStep = (state) => {
  const { group, step } = state.currentStep;
  const steps = getStepsWithoutHiddenSteps(state);
  const currentGroup = steps[group];
  const currentStep = currentGroup[step];
  const currentGroupLength = currentGroup.length;

  // Skip case
  if (state.skipSteps) {
    return goToStep(state, {
      stepId: stepsIds.QUOTE,
    });
  } else if (currentStep.next) {
    return goToStep(state, { stepId: currentStep.next });
    // Change group case
  } else if (step === currentGroupLength - 1) {
    const newGroup = steps[group + 1];
    const newCurrentStep = { group: group + 1, step: 0 };
    const newCurrentStepId =
      steps[newCurrentStep.group][newCurrentStep.step].id;
    if (newGroup) {
      return {
        ...state,
        currentStep: newCurrentStep,
        visitedSteps: state.visitedSteps.includes(newCurrentStepId)
          ? state.visitedSteps
          : [...state.visitedSteps, newCurrentStepId],
        skipSteps: false,
      };
    }

    // Same group case
  } else if (currentGroup && step < currentGroupLength - 1) {
    const newCurrentStep = { group, step: step + 1 };
    const newCurrentStepId =
      steps[newCurrentStep.group][newCurrentStep.step].id;
    return {
      ...state,
      currentStep: newCurrentStep,
      visitedSteps: state.visitedSteps.includes(newCurrentStepId)
        ? state.visitedSteps
        : [...state.visitedSteps, newCurrentStepId],
      skipSteps: false,
    };
  } else return state;
};

const goToPrevStep = (state) => {
  const { group, step } = state.currentStep;
  const steps = getStepsWithoutHiddenSteps(state);
  const currentGroup = steps[group];
  const currentStep = currentGroup[step];

  if (currentStep.previous) {
    return goToStep(state, { stepId: currentStep.previous });
  } else if (currentGroup[step - 1]) {
    const newCurrentStep = { group, step: step - 1 };
    const newCurrentStepId =
      steps[newCurrentStep.group][newCurrentStep.step].id;
    return {
      ...state,
      currentStep: newCurrentStep,
      visitedSteps: state.visitedSteps.includes(newCurrentStepId)
        ? state.visitedSteps
        : [...state.visitedSteps, newCurrentStepId],
      skipSteps: false,
    };
  } else if (steps[group - 1]) {
    const newCurrentStep = {
      group: group - 1,
      step: steps[group - 1].length - 1,
    };
    const newCurrentStepId =
      steps[newCurrentStep.group][newCurrentStep.step].id;
    return {
      ...state,
      currentStep: newCurrentStep,
      visitedSteps: state.visitedSteps.includes(newCurrentStepId)
        ? state.visitedSteps
        : [...state.visitedSteps, newCurrentStepId],
      skipSteps: false,
    };
  } else return state;
};

const goToStep = (state, payload) => {
  const steps = getSteps(state.data);

  const getStepById = () => {
    for (let index = 0; index < steps.length; index++) {
      const stepIndex = steps[index].findIndex((step) => {
        return step.id === payload.stepId;
      });

      if (stepIndex > -1) {
        return { group: index, step: stepIndex };
      }
    }
  };

  const step = getStepById();

  if (step) {
    const stepId = steps[step.group][step.step];
    return {
      ...state,
      currentStep: step,
      visitedSteps: state.visitedSteps.includes(stepId)
        ? state.visitedSteps
        : [...state.visitedSteps, stepId],
      skipSteps: !!payload.skipSteps,
    };
  } else return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'PREV_STEP':
      return goToPrevStep(state);
    case 'NEXT_STEP':
      return goToNextStep(state);
    case 'SET_STEP':
      return goToStep(state, action.payload);
    case 'SET_DATA': {
      const currentState = { ...state };
      const updatedState = _set(
        currentState,
        `data[${action.payload.key}]`,
        action.payload.value
      );
      return {
        ...updatedState,
        time: moment().format(),
      };
    }
    case 'SET_DATA_AND_CHANGE_PAGE': {
      const currentState = { ...state };

      const updatedState = _set(
        currentState,
        `data[${action.payload.key}]`,
        action.payload.value
      );
      const nextState = goToNextStep(currentState);

      const newStep = {
        ...updatedState,
        visitedSteps: nextState.visitedSteps,
        currentStep: nextState.currentStep,
      };

      return {
        ...newStep,
        time: moment().format(),
      };
    }
    case 'SET_ENTRY': {
      const newState = { ...state };
      newState[action.payload.key] = action.payload.value;
      return newState;
    }
    case 'DELETE_ENTRY': {
      const newState = { ...state };
      delete newState[action.payload.key];
      return newState;
    }
    case 'LOAD_DATA': {
      return {
        ...action.payload,
        time: moment().format(),
      };
    }
    case 'RESET_DATA': {
      return {
        currentStep: {
          group: 0,
          step: 0,
        },
        visitedSteps: [stepsIds[Object.keys(stepsIds)[0]]],
        skipSteps: false,
        data: {
          address: {
            address1: '',
            address2: '',
            zipCode: '',
            city: '',
          },
          contractAddress: {
            address1: '',
            address2: '',
            zipCode: '',
            city: '',
          },
          contact: {},
          price: {},
          equipements: [],
          residenceType: ['P'],
        },
      };
    }
    default:
      throw new Error();
  }
};

const MrhContextProvider = (props) => {
  const auth = useAuth();
  const urlParams = useParams();
  const history = useHistory();

  const defaultParcoursId = urlParams.parcoursId || generateUUID();

  const [parcoursId, setParcoursId] = useState(defaultParcoursId);

  const [state, dispatch] = useReducer(reducer, defaultState);

  useEffect(async () => {
    if (urlParams.parcoursId) {
      let data = await subscribeApi
        .recupereInformationsSouscriptionMRH(auth, parcoursId)
        .catch(() => {
          history.push('/souscription/mrh/');
          deleteState('parcoursMRH');
          setParcoursId(generateUUID());
          return defaultState;
        });

      dispatch({
        type: 'LOAD_DATA',
        payload: data,
      });
    }
  }, []);

  useEffect(() => {
    const { gender, lastName, firstName, mail } = state.data.contact;

    if (gender && lastName && firstName && isEmail(mail)) {
      subscribeApi.persisteInformationsSouscriptionMRH(auth, state, parcoursId);
      persistState('parcoursMRH', { parcoursId: parcoursId, date: new Date() });
      history.push('/parcours/mrh/' + parcoursId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentStep]);

  return (
    <MrhContext.Provider value={{ state, dispatch, parcoursId }}>
      {props.children}
    </MrhContext.Provider>
  );
};

export { MrhContext, MrhContextProvider };
