import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Popin from 'components/Popin';
import Field from 'components/Field';
import { PRIVFONCIA, TYPE_POLICE_H, TYPE_POLICE_PNO } from 'constants/index';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/index';
import PaperImage from 'assets/images/paper.svg';
import { ASSURIMO_INDIVIDUAL_EMAIL, ASSURIMO_PHONE_NUMBER } from 'constants';

const getMinDateAvailable = (police) => {
  if (TYPE_POLICE_PNO === police?.contractType?.code) {
    const toDay = moment(new Date()).startOf('day');
    const startDateAfter1Year = moment(police.startDate)
      .add(1, 'y')
      .startOf('day');
    if (startDateAfter1Year.isAfter(toDay)) {
      return moment(startDateAfter1Year).add(-1, 'days');
    }
  }

  return moment(new Date()).add(1, 'days');
};

const getMaxDateAvailable = (police, minDate) => {
  if (TYPE_POLICE_PNO === police?.contractType?.code) {
    const threeMonthAfterNow = moment(new Date()).add(3, 'months');
    if (threeMonthAfterNow.isAfter(minDate)) {
      return threeMonthAfterNow;
    }
  }

  return null;
};

const TerminationPopin = (props) => {
  const { open, onClose, onValidate, loading, police } = props;
  const minDate = getMinDateAvailable(police);
  const maxDate = getMaxDateAvailable(police, minDate);

  const [showPopin, setShowPopin] = useState(open);
  const [date, setDate] = useState(minDate);
  const [reason, setReason] = useState(null);
  const { t } = useTranslation();

  const COMPANY_BANQUES_POPULAIRES_CODE = 'X1062';
  const MRH_PRIVILEGE = (TYPE_POLICE_H === police?.contractType?.code && police.riskType &&
        police.riskType.code === PRIVFONCIA &&
        police?.insuranceCompany?.code === COMPANY_BANQUES_POPULAIRES_CODE);
  const handleDateChange = (date) => {
    const newDate = date.target ? date.target.value : date;
    setDate(newDate);
  };

  const handleValidate = () => {
    onValidate(date, reason);
  };

  const isValidateButtonDisabled = () => {
    if (date === null || MRH_PRIVILEGE) return true;

    if (TYPE_POLICE_PNO === police?.contractType?.code) {
      const toDay = moment(new Date()).startOf('day');
      const startDateAfter1Year = moment(police.startDate)
        .add(1, 'y')
        .startOf('day');
      if (startDateAfter1Year.isAfter(toDay)) {
        const dateMinResil = moment(startDateAfter1Year).add(-1, 'days');
        return dateMinResil.isAfter(date);
      }
    }

    return moment(new Date()).isAfter(date);
  };

  useEffect(() => {
    setShowPopin(open);
  }, [open]);

  const renderPopinContent = () => {
    if (TYPE_POLICE_PNO === police?.contractType?.code || MRH_PRIVILEGE) {
      return (
        <Box
          style={{
            display: 'flex',
            margin: 32,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 25,
          }}
        >
          <img
            src={PaperImage}
            alt="Assurimo"
            style={{
              width: 160,
            }}
          />
          <Typography variant="body3" style={{ textAlign: 'center' }}>
            {t('dashboard.policies.terminateContractPnoPopin.forAnyterm')}{' '}
            <a
              style={{ whiteSpace: 'nowrap' }}
              href={`tel:${ASSURIMO_PHONE_NUMBER}`}
            >
              {ASSURIMO_PHONE_NUMBER}
            </a>{' '}
            {t('dashboard.policies.terminateContractPnoPopin.orByEmail')}{' '}
            <a href={`mailto:${ASSURIMO_INDIVIDUAL_EMAIL}`}>
              {ASSURIMO_INDIVIDUAL_EMAIL}
            </a>
            .
          </Typography>
        </Box>
      );
    }
    return (
      <>
        <Box mb={2} mr={2} ml={2} mt={2}>
          <Field
            id="date"
            label={t('dashboard.police.termination.popin_form_date_label')}
            type="date"
            minDate={minDate}
            {...(maxDate && { maxDate })}
            value={date}
            variant="outlined"
            fullWidth
            onChange={handleDateChange}
            minDateMessage={t(
              'dashboard.police.termination.popin_form_date_min'
            ).replace('__dateMin__', formatDate(minDate, 'DD/MM/YYYY'))}
          />
        </Box>
        <Box mb={2} mr={2} ml={2} mt={2}>
          <Field
            id="reason"
            label={t('dashboard.police.termination.popin_form_reason')}
            type="text"
            variant="outlined"
            multiline={true}
            fullWidth
            onChange={(e) => setReason(e.target.value)}
            rows={3}
          />
        </Box>
      </>
    );
  };

  if (TYPE_POLICE_PNO === police?.contractType?.code) {
    return (
      <Popin
        open={showPopin}
        onClose={onClose}
        title={t('dashboard.police.termination.popin_title_PNO')}
        content={renderPopinContent()}
        maxWidth="sm"
        showCancelButton={false}
        isValidateButtonDisabled={false}
        validateLoading={loading}
        useValidateLoader={true}
      />
    );
  } else
    return (
      <Popin
        open={showPopin}
        onClose={onClose}
        title={t('dashboard.police.termination.popin_title')}
        content={renderPopinContent()}
        validateButtonLabel={t('dashboard.police.termination.popin_btn')}
        maxWidth="sm"
        showCancelButton={true}
        isValidateButtonDisabled={isValidateButtonDisabled()}
        onValidate={handleValidate}
        validateLoading={loading}
        useValidateLoader={true}
      />
    );
};

TerminationPopin.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  loading: PropTypes.bool,
  police: PropTypes.object,
};

TerminationPopin.defaultProps = {
  open: false,
};

export default TerminationPopin;
