export const CUSTOMER_HAVE_POLICE_ACTIVE_SAME_ADDRESS =
  'CUSTOMER_HAVE_POLICE_ACTIVE_SAME_ADDRESS';

export const CUSTOMER_HAVE_POLICE_ACTIVE_MAIN_RESIDENCE =
  'CUSTOMER_HAVE_POLICE_ACTIVE_MAIN_RESIDENCE';

export const POLICE_HAVE_CLAIM_SAME_DATE_SURVENANCE_NATURE =
  'POLICE_HAVE_CLAIM_SAME_DATE_SURVENANCE_NATURE';

export const NEED_ADVISOR = 'NEED_ADVISOR';
export const NEED_ADVISOR_STATUS_FAIL = 'NEED_ADVISOR_STATUS_FAIL';
export const NEED_ADVISOR_STATUS_PENDING = 'NEED_ADVISOR_STATUS_PENDING';
