import React, { useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DataGrid from 'components/DataGrid';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { UserContext } from 'contexts/user';
import policyApi from 'api/policyApi';
import getColumns from './columns';
import useStyles from './style';

const DmriContracts = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [user] = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
  };

  const getDmriContracts = async () => {
    try {
      setLoading(true);

      const response = await policyApi.search(user.auth, {
        page: page,
        params: [
          {
            key: 'POLICY_TYPE',
            value: [
              {
                operation: 'IN',
                value1: {},
                value2: {},
                value3: ['DMI', 'DMP', 'DPJ', 'DDO'],
              },
            ],
          },
        ],
        size: rowsPerPage,
      });

      setRows(response.entities);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDmriContracts();
  }, []);

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        className={classes.title}
        justify="space-between"
        alignItems="center"
      >
        <Box>
          <Typography variant="h3">
            {t('dashboard.menu.DMRIContracts')}
          </Typography>
        </Box>
        <Box />
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div style={{ height: '80vh', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={getColumns()}
              getRowId={(row) => row.policyNumber}
              loading={loading}
              page={page}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              pageSize={rowsPerPage}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DmriContracts;
