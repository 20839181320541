import { useEffect, useState } from 'react';
import diversApi from 'api/diversApi';
import { useSnackbar } from 'notistack';

export default function () {
  const [iframeUrl, setIFrameUrl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      try {
        const res = await diversApi.getIframeUrl();
        setIFrameUrl(res.url);
      } catch (error) {
        console.log(error);
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    })();
  }, []);

  return { iframeUrl };
}
