import moment from 'moment';

const findDataInArray = (data, key) => {
  return data?.find((equipement) => equipement === key);
};

const getMrhSubscriptionData = (data, parcoursId) => {
  const user = {
    address: data.address?.address1 ? data.address : data.contractAddress,
    contact: {
      mail: data.contact.mail,
      mobilePhone: data.contact.phone.value,
    },
    firstName: data.contact.firstName,
    lastName: data.contact.lastName,
    birthDate: data.contact.birthdate,
    birthCity: data.contact.birthCity,
    countryBirth: data.contact.countryBirth,
    title: data.contact.gender,
    createReason: 'MRH',
  };

  const oldPolicy = data.hasCurrentWarranty
    ? {
        policyNumber: data.policyNumber ? data.policyNumber : null,
        insuranceCompany: data.insuranceCompany ? data.insuranceCompany : null,
        terminationTodo: true,
      }
    : { terminationTodo: false };

  const policy = {
    customerPhone: data.contact.phone.value,
    customerBirthDate: data.contact.birthdate,
    riskType: 'SMA',
    startDate: moment(data.contractStartDate).format('YYYY-MM-DD'),
    paymentSplittingCode: data.paymentPlan.paymentSplitting.code,
    annualTTC: data.price.annualTTC,
    rateOptions: {
      deductibleOptionCode: data.deductible.code,
      landscapingOptionCode: data.landscaping.code,
      movableCapitalAmountsOptionCode: data.movableCapitalAmount.code,
    },
    externalAccountManager: {
      firstName: data.externalAccountManager
        ? data.externalAccountManager.firstName
        : null,
      lastName: data.externalAccountManager
        ? data.externalAccountManager.lastName
        : null,
      title: data.externalAccountManager
        ? data.externalAccountManager.title
        : null,
      usualMail: data.externalAccountManager
        ? data.externalAccountManager.usualMail
        : null,
      company: data.externalAccountManager
        ? data.externalAccountManager.company
        : null,
      groupmentCompanyN1: data.externalAccountManager
        ? data.externalAccountManager.groupmentCompanyN1
        : null,
      groupmentCompanyN2: data.externalAccountManager
        ? data.externalAccountManager.groupmentCompanyN2
        : null,
    },
    coinsured: [],
    children: [], // data.children ? data.children.elements : [],
    flatmates: [], // data.flatmates ? data.flatmates.elements : [],
    risk: {
      address: data.contractAddress,
      area: data.area,
      assuredQuality: data.assuredQuality,
      housingType: data.housingType,
      housingUsageType: 'P',
      numberOfRoom: data.numberOfPrincipalRooms,
      equipment: {
        swimmingPool: !!findDataInArray(
          data.equipements,
          'gardenWithSwimmingPool'
        ),
        fireDetectionEquipment: !!findDataInArray(
          data.equipements,
          'detection_incendie'
        ),
        burglarAlarm: !!findDataInArray(data.equipements, 'alarme_antivol'),
        firePlace: !!findDataInArray(data.equipements, 'cheminee'),
        solarPanels: !!findDataInArray(data.equipements, 'panneaux_solaires'),
        veranda: !!findDataInArray(data.equipements, 'veranda'),
        outbuilding: findDataInArray(data.equipements, 'outbuilding')
          ? 'INF120'
          : 'NONE',
        landscaping:
          !!findDataInArray(data.equipements, 'gardenWithSwimmingPool') ||
          !!findDataInArray(data.equipements, 'gardenWithoutSwimmingPool'),
      },
    },
    forecastFinishDate: data.contractEndDate,
    parcoursSouscriptionUuid: parcoursId,
  };

  if (data.from) {
    policy.originalCode = data.from;
  }

  if (data.promotionCode) {
    policy.promotionCode = data.promotionCode;
  }

  if (data.forceControl) {
    policy.forceControl = data.forceControl;
  }

  return {
    user,
    policy: {
      ...policy,
      oldPolicy,
    },
  };
};

export default getMrhSubscriptionData;
