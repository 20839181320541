import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Popin from 'components/Popin';
import Field from 'components/Field';

const CertificatesPopin = (props) => {
  const { t } = useTranslation();
  const {
    title,
    fields,
    open,
    onClose,
    handleFieldChange,
    isValidateButtonDisabled,
    onValidate,
    policyNumber,
    hideOptionManageBenificiaire
  } = props;
  const history = useHistory();
  const [showPopin, setShowPopin] = useState(open);

  useEffect(() => {
    setShowPopin(open);
  }, [open]);

  const handleChange = (event, child) => {
    handleFieldChange({
      target: {
        value: event.target.value,
        id: child.props.parentId,
      },
    });
  };

  const handleAddBeneficiaryClick = () => {
    setShowPopin(false);
    history.push(`/polices/${policyNumber}#beneficiaries`, {
      hash: 'beneficiaries',
    });
  };

  const renderPopinContent = () => {
    return (
      <Box mb={2} mr={2} ml={2} mt={2}>
        {fields.map((param) => {
          const { id, label, type, items, ...otherProps } = param;
          if (type === 'select') {
            const itemSelected = items.filter(i => i.selected);
            return (
              <div key={id}>
                <InputLabel id={label}>{t(label)}</InputLabel>
                <Select
                  labelId={label}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  {... !!itemSelected.length && {value : itemSelected[0].value} }
                  {...otherProps}
                >
                  {items.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value} parentId={id}>
                        {t(item.label)}
                      </MenuItem>
                    );
                  })}
                  {
                    !hideOptionManageBenificiaire && 
                    <MenuItem onClick={handleAddBeneficiaryClick}>
                      {t('dashboard.police.beneficiaries.manageBeneficiaries')}...
                    </MenuItem>
                  }
                  
                </Select>
              </div>
            );
          }
          return (
            <div key={id}>
              <Typography variant="body1" color="secondary">
                {t(label)}
              </Typography>
              <Field
                id={id}
                type={type}
                variant="outlined"
                fullWidth
                onChange={handleFieldChange}
                {...otherProps}
              />
            </div>
          );
        })}
      </Box>
    );
  };

  return (
    <Popin
      open={showPopin}
      onClose={onClose}
      title={title}
      content={renderPopinContent()}
      validateButtonLabel="Continuer"
      maxWidth="sm"
      showCancelButton={true}
      isValidateButtonDisabled={isValidateButtonDisabled}
      onValidate={onValidate}
    />
  );
};

CertificatesPopin.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.array.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  isValidateButtonDisabled: PropTypes.bool,
  policyNumber: PropTypes.string.isRequired,
  onValidate: PropTypes.func,
  hideOptionManageBenificiaire: PropTypes.bool,
};

CertificatesPopin.defaultProps = {
  open: false,
  isValidateButtonDisabled: false,
  hideOptionManageBenificiaire: false,
};

export default CertificatesPopin;
