import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';

import columns from './columns';
import Table from 'components/Table';
import Search from 'components/Search';
import policyApi from 'api/policyApi';
import useStyles from './style';

/*Material datagrid*/
import { DataGrid } from '@material-ui/data-grid';

/***********/

const filters = [
  { id: 'POLICY_NUMBER', label: 'Numéro de police', operation: 'EQUALS' },
  { id: 'CUSTOMER_LASTNAME', label: 'Nom client', operation: 'CONTAINS' },
  { id: 'BUILDING_ENTRANCE_CITY', label: 'Ville', operation: 'CONTAINS' },
  {
    id: 'BUILDING_ENTRANCE_ZIPCODE',
    label: 'Code postal',
    operation: 'EQUALS',
  },
];

const Contracts = () => {
  const { authState } = useOktaAuth();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilterChange = (filter) => {
    const { id, operation, value } = filter;
    const newSelectedFilters = { ...selectedFilters };

    delete newSelectedFilters[id];
    if (value) newSelectedFilters[id] = { operation, value };

    setSelectedFilters(newSelectedFilters);
  };

  const getSearchParams = () => {
    const searchParams = {
      page: page + 1,
      size: rowsPerPage,
    };

    const params = Object.keys(selectedFilters).reduce((newFilters, id) => {
      const filter = selectedFilters[id];
      const newFilter = {
        key: id,
        value: [
          {
            operation: filter.operation,
            value1: filter.value,
          },
        ],
      };
      return [...newFilters, newFilter];
    }, []);

    if (params.length > 0) return { ...searchParams, params };

    return { ...searchParams };
  };

  const handleSearch = async () => {
    setRows([]);
    setLoading(true);
    const {
      isAuthenticated,
      accessToken: { accessToken: token },
    } = authState;
    try {
      const searchParams = getSearchParams();

      const response = await policyApi.search(
        { isAuthenticated, token },
        searchParams
      );

      setRows(response.entities);
      setTotal(response.total);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authState.isAuthenticated) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, page]);

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        className={classes.title}
        justify="space-between"
        alignItems="center"
      >
        <Box>
          <Typography variant="h3">Polices</Typography>
        </Box>
        <Box />
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Search
            filters={filters}
            onFilterChange={handleFilterChange}
            onSearchClick={handleSearch}
          />
        </Grid>
        <Grid item xs={12}>
          {/* <Table
            columns={columns}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            rowId={'policyNumber'}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            total={total}
            loading={loading}
          /> */}
          <div style={{ height: '80vh', width: '100%' }}>
            <DataGrid
              rows={rows}
              getRowId={(row) => row.policyNumber}
              loading={loading}
              columns={columns}
              page={page}
              onPageChange={handleChangePage}
              onPageSizeChange={handleChangeRowsPerPage}
              pageSize={rowsPerPage}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Contracts;
