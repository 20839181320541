import React, { useState, useRef, useContext, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  GetApp as GetAppIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';

import Popin from 'components/Popin';
import PdfViewVer from 'components/PdvViewver';
import { downloadFileFromLink, downloadFileFromBlob } from 'utils';
import NoticeInformationPNO from "public/files/202401_Notice_d'information_Assurimo.pdf";
import IpidPNO from 'public/files/202401_Fiche_IPID_Assurimo.pdf';
import ConditionsGeneralesMRH from 'public/files/Conditions_générales_MRH.pdf';
import IpidMRH from 'public/files/IPID_MRH.pdf';
import {
  DOC_CONTRACTUEL,
  TYPE_POLICE_H,
  TYPE_POLICE_PNO,
} from 'constants/index';
import policyApi from 'api/policyApi';
import documentApi from 'api/documentApi';
import { UserContext } from 'contexts/user';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  buttonsWrapper: {
    display: 'flex',
  },
  container: {
    borderRadius: 10,
    backgroundColor: '#fff',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  pdfWrapper: {
    border: '1px solid black',
    marginBottom: theme.spacing(2),
  },

  elementWrapper: {
    border: `1px solid ${theme.palette.grey.main}`,
  },
}));

const DOCUMENTATION_GENERAL = {
  [TYPE_POLICE_H]: [
    {
      id: 'ConditionsGeneralesMRH',
      document: ConditionsGeneralesMRH,
      nameDownload: 'Conditions_générales_MRH.pdf',
      nameShow: 'common.generalConditions',
    },
    {
      id: 'IpidMRH',
      document: IpidMRH,
      nameDownload: 'IPID_MRH.pdf',
      nameShow: 'common.ipidPage',
    },
  ],
  [TYPE_POLICE_PNO]: [
    {
      id: 'NoticeInformationPNO',
      document: NoticeInformationPNO,
      nameDownload: "202401_Notice_d'information_Assurimo.pdf",
      nameShow: 'common.generalDispositions',
    },
    {
      id: 'IpidPNO',
      document: IpidPNO,
      nameDownload: '202401_Fiche_IPID_Assurimo.pdf',
      nameShow: 'common.ipidPage',
    },
  ],
};

const GeneralConditions = (props) => {
  const classes = useStyles();
  const { police } = props;
  const { t } = useTranslation();
  const [optionsPopin, setOptionsPopin] = useState({
    open: false,
  });
  const [loadingDoc, setLoadingDoc] = useState(null);
  const [userState] = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [docContractuels, setDocContractuels] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await policyApi.documentContractuel(
          userState.auth,
          police.policyNumber
        );
        setDocContractuels(res);
      } catch (error) {
        console.log(t('dashboard.relatedDocuments.error_generate'), error);
      }
    })();
  }, []);

  const previewFile = async (doc) => {
    setLoadingDoc(doc.id);
    try {
      const blob = await documentApi.download(userState.auth, doc.id);
      showPopinPreview(blob, doc);
    } catch (error) {
      console.log(t('dashboard.relatedDocuments.error_generate'), error);
      enqueueSnackbar(t('dashboard.relatedDocuments.error_generate'), {
        variant: 'error',
      });
    } finally {
      setLoadingDoc(null);
    }
  };

  const showPopinPreview = (blob, doc) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    setOptionsPopin({
      open: true,
      onValidate: () =>
        downloadFileFromBlob(
          blob,
          `${t(
            DOC_CONTRACTUEL[doc.codeDocument]
              ? DOC_CONTRACTUEL[doc.codeDocument].label
              : doc.labelDefault
          )}.pdf`
        ),
      validateButtonLabel: t('common.download'),
      content: <PdfViewVer filePath={url} />,
      onCancel: () => setOptionsPopin({ open: false }),
    });
  };

  if (!docContractuels || !docContractuels.length) return <></>;

  return (
    <Grid container className={classes.quittances}>
      <Box p={2} className={classes.container} width="100%">
        <Grid container>
          <Box mb={2}>
            <Typography variant="h5" color="secondary">
              {t('dashboard.relatedDocuments.title')}
            </Typography>
          </Box>

          <Grid item container direction="column">
            {docContractuels.map((doc, index) => (
              <Box
                key={index}
                padding={1}
                mb={1}
                className={classes.elementWrapper}
              >
                <Grid
                  item
                  container
                  flex
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Typography>
                    {t(
                      DOC_CONTRACTUEL[doc.codeDocument]
                        ? DOC_CONTRACTUEL[doc.codeDocument].label
                        : doc.labelDefault
                    )}
                  </Typography>
                  <div className={classes.buttonsWrapper}>
                    {loadingDoc === doc.id ? (
                      <CircularProgress color="primary" size={16} />
                    ) : (
                      <Button
                        startIcon={<VisibilityIcon />}
                        variant="outlined"
                        color="secondary"
                        onClick={() => previewFile(doc)}
                      >
                        {t('common.preview')}
                      </Button>
                    )}
                  </div>
                </Grid>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
      <Popin
        onClose={() => setOptionsPopin({ open: false })}
        maxWidth="xl"
        enableTitleArea={false}
        cancelButtonLabel={t('common.cancel')}
        showCancelButton={true}
        {...optionsPopin}
      />
    </Grid>
  );
};

export default GeneralConditions;
