import { Box, Grid, Typography } from '@material-ui/core';
import BrokenGlassLottie from 'assets/lotties//claims/brokenGlass.json';
import VandalismLottie from 'assets/lotties//claims/vandalism.json';
import FireLottie from 'assets/lotties/claims/fire.json';
import OtherLottie from 'assets/lotties/claims/other.json';
import WaterLottie from 'assets/lotties/claims/water.json';
import SinisterReason from 'components/SinisterReason/SinisterReason';
import {
  SINISTRE_NATURE_CODE_AUTRE,
  SINISTRE_NATURE_CODE_BRIS_DE_GLACE,
  SINISTRE_NATURE_CODE_DEGAT_DES_EAUX,
  SINISTRE_NATURE_CODE_INCENDIE,
  SINISTRE_NATURE_CODE_VANDALISME,
  SINISTRE_NATURE_CODE_VOL,
} from 'constants/common/sinistreNatureCode';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

ListSinistre.propTypes = {
  reason: PropTypes.string,
  onChange: PropTypes.func,
  mb: PropTypes.number,
};

function ListSinistre(props) {
  const { t } = useTranslation();

  const { reason, onChange, mb } = props;

  const handleChangeReason = (reasonSel) => {
    if (!onChange) return;
    onChange(reasonSel);
  };

  const getMarginBottom = () => {
    if (mb !== null || mb !== undefined) return mb;
    else return 6;
  };

  return (
    <Box
      mb={getMarginBottom()}
      display="flex"
      width={1}
      alignItems="center"
      flexDirection="column"
    >
      <Typography variant="h5" color="secondary">
        {t('dashboard.sinistre.form.reason.title')}
      </Typography>
      <Box mt={2} width={1}>
        <Grid container alignItems="center" spacing={2} justify="start" xs={12}>
          {sinistreData.map((item, index) => (
            <SinisterReason
              key={index}
              selected={reason === item.reason}
              asset={item.asset}
              label={t(item.label)}
              onClick={() => handleChangeReason(item.reason)}
            />
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

const sinistreData = [
  {
    reason: SINISTRE_NATURE_CODE_INCENDIE,
    asset: FireLottie,
    label: 'dashboard.sinistre.form.reason.incendie',
  },
  {
    reason: SINISTRE_NATURE_CODE_DEGAT_DES_EAUX,
    asset: WaterLottie,
    label: 'dashboard.sinistre.form.reason.dde',
  },
  {
    reason: SINISTRE_NATURE_CODE_VANDALISME,
    asset: VandalismLottie,
    label: 'dashboard.sinistre.form.reason.vandalisme',
  },
  {
    reason: SINISTRE_NATURE_CODE_VOL,
    asset: VandalismLottie,
    label: 'dashboard.sinistre.form.reason.vol',
  },
  {
    reason: SINISTRE_NATURE_CODE_BRIS_DE_GLACE,
    asset: BrokenGlassLottie,
    label: 'dashboard.sinistre.form.reason.bdg',
  },
  {
    reason: SINISTRE_NATURE_CODE_AUTRE,
    asset: OtherLottie,
    label: 'dashboard.sinistre.form.reason.other',
  },
];

export default ListSinistre;
export { sinistreData };
