import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { MrhContext } from 'contexts/mrh';
import { useSnackbar } from 'notistack';
import Details from './Details';
import { fetchRatings } from './fetchRating';
import { generateQuote } from './generateQuote';
import QuoteStepPreloader from './Preloader';
import subscribeApi from 'api/subscribeApi';
import { PnoContext } from 'contexts/pno';
import { getMessageError } from 'constants/common/errorCode';

//http://localhost:8080/souscription/lodgis?info=%7B%22contractAddress%22%3A%7B%22address1%22%3A%221%20Rue%20Marcel%22%2C%22address2%22%3A%22%22%2C%22zipCode%22%3A%2294100%22%2C%22city%22%3A%22Saint-Maur-des-Foss%C3%A9s%22%7D%7D
const QuoteStep = ({ onConfirm, stay, parentRef }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [loaded, setLoaded] = useState(false);
  const [rating, setRating] = useState([]);
  const [ratingPromo, setRatingPromo] = useState(null);
  const { auth, dispatch, state, parcoursId } = useContext(MrhContext);

  let { data } = state;

  const handleValidate = async () => {
    try {
      generateQuote(data, parcoursId); // we do not want to wait for it
      onConfirm();
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    dispatch({
      type: 'SET_DATA',
      payload: { key: 'landscaping', value: null },
    });

    if (data.contractAddress.address1) {
      fetchRatings(data)
        .then((res) => {
          if (Array.isArray(res.errors))
            enqueueSnackbar(res.errors.join(' | '), { variant: 'error' });
          else if (res.error_description)
            enqueueSnackbar(res.error_description, { variant: 'error' });
          else {
            setRating(res);
            setTimeout(() => setLoaded(true), 5000);
          }
        })
        .catch((err) => {
          handleError(err);
        });
    }
  }, []);

  const handleError = (error) => {
    console.error('error', error);
    const messageError = getMessageError(error);
    enqueueSnackbar(t(messageError), { variant: 'error' });
  }

  useEffect(() => {
    (async () => {
      if (data.promotionCode) {
        try {
          const res = await fetchRatings(data, true);
          setRatingPromo(res);
        } catch (error) {
          enqueueSnackbar(t('steps.quoteStep.promotionCodeError'), {
            variant: 'error',
          });
          dispatch({
            type: 'SET_DATA',
            payload: { key: 'promotionCode', value: null },
          });
        }
      }
    })();
  }, []);

  useEffect(() => {
    subscribeApi.persisteInformationsSouscriptionMRH(auth, state, parcoursId);
  }, [auth, state, parcoursId]);

  return (
    <Grid
      container
      className="Step"
      alignItems="center"
      justifyContent="center"
    >
      {!loaded && <QuoteStepPreloader data={data} />}
      {loaded && (
        <Details
          data={data}
          rating={rating}
          onConfirm={handleValidate}
          stay={stay}
          parentRef={parentRef}
        />
      )}
    </Grid>
  );
};

QuoteStep.propTypes = {
  data: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  stay: PropTypes.bool,
  parentRef: PropTypes.any,
};

export default QuoteStep;
