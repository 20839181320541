import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TuneIcon from '@material-ui/icons/Tune';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useSnackbar } from 'notistack';

import { policeStatuses } from 'views/Dashboard/Customer/PolicyPage/helper';
import Header from 'components/DashboardHeader';
import { UserContext } from 'contexts/user';
import policyApi from 'api/policyApi';
import { getAdresseDepuisPoliceHab } from 'utils/policies';
import TerminationPopin from './TerminationPopin';
import PaiementPeriodicitePopin from './PaiementPeriodicitePopin';
import MenuHeader from './Menu';
import RetractationPopin from './RetractationPopin';
import Alert from '@material-ui/lab/Alert';
import { formatDate } from 'utils';
import { styleInline } from './style';

const PolicyHeader = ({ police, polichab, policyStatus, sectionRefs }) => {
  const DELAI_RETRACTATION_EN_JOURS = 18;
  const DELAI_RESIL_APRES_NOTIF_SOUSCRIPTEURS = 30;
  const MOTIF_API_RETRACTATION = 'RETRACTATION';

  const { t } = useTranslation();
  const buttonRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [userState] = useContext(UserContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [contractPopinOpen, setContractPopinOpen] = useState(false);
  const [showResiliationPopin, setShowResiliationPopin] = useState(false);
  const [showRetractationPopin, setShowRetractationPopin] = useState(false);
  const [motifsDeResiliation, setMotifsDeResiliation] = useState(null);

  const statuses = policeStatuses;

  useEffect(async () => {
    try {
      const result = await policyApi.getMotifsDeResiliation(
        userState.auth,
        police.policyNumber
      );

      setMotifsDeResiliation(result);
    } catch (error) {
      console.log(t('dashboard.police.attestation.error_get_enfant'), error);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, []);

  const getPolicyTypeLabel = (isPending) => {
    const {
      contractType: { code },
    } = police;
    const { housingType } = polichab.result;
    const label = `dashboard.police.policyTypes.${code.toLowerCase()}${housingType}`;

    return isPending ? t(`${label}Quotation`) : t(label);
  };

  const loadingMotifsResil = motifsDeResiliation === null;

  const estEligibleALaRetractation = !!motifsDeResiliation?.findLast(
    (motif) => motif?.motifResiliation === MOTIF_API_RETRACTATION
  );

  const estEligibleALaResiliation =
    !loadingMotifsResil && motifsDeResiliation.length !== 0;

  const adresse = getAdresseDepuisPoliceHab(polichab, t);
  const policeResiliee =
    policyStatus === statuses.ENDED ||
    policyStatus === statuses.CANCELLED ||
    policyStatus === statuses.ENDED_FUTUR;

  const getStatusMessage = (status) => {
    const dateEffetResil = formatDate(police.statusEffectiveDate, 'DD/MM/YYYY');
    const dateResil = formatDate(police.statusDate, 'DD/MM/YYYY');

    switch (status) {
      case statuses.CANCELLED:
        return t('dashboard.police.statuses.cancelled') + dateResil;
      case statuses.ENDED:
        return t('dashboard.police.statuses.terminated') + dateEffetResil;
      case statuses.ENDED_FUTUR:
        return t('dashboard.police.statuses.toBeTerminated') + dateEffetResil;
      default:
        return '';
    }
  };

  return (
    <>
      <Header
        title={getPolicyTypeLabel(policyStatus === statuses.PENDING)}
        subtitle={police.policyKey}
        mainAction={
          !loadingMotifsResil && (
            <Box display="flex">
              <Box display="flex" mr={2}>
                {policeResiliee && (
                  <Alert severity="info" style={styleInline}>
                    {getStatusMessage(policyStatus)}
                  </Alert>
                )}
              </Box>

              {!policeResiliee && estEligibleALaRetractation && (
                <Box mr={1}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<CancelPresentationIcon />}
                    onClick={() => setShowRetractationPopin(true)}
                    style={styleInline}
                  >
                    {t('dashboard.policies.meRetracter')}
                  </Button>
                </Box>
              )}
              {!policeResiliee && estEligibleALaResiliation && (
                <Box mr={1}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<CancelPresentationIcon />}
                    onClick={() => setShowResiliationPopin(true)}
                    style={styleInline}
                  >
                    {t('dashboard.policies.terminateContract')}
                  </Button>
                </Box>
              )}

              <Button
                ref={buttonRef}
                variant="contained"
                color="primary"
                startIcon={<TuneIcon />}
                endIcon={<ArrowDownIcon />}
                onClick={() => setIsMenuOpen(true)}
                style={styleInline}
              >
                {t('dashboard.policies.manageContract')}
              </Button>

              <>
                <MenuHeader
                  isMobileVersion={false}
                  sectionRefs={sectionRefs}
                  police={police}
                  policyStatus={policyStatus}
                  isMenuOpen={isMenuOpen}
                  peutResilier={!policeResiliee}
                  peutSeRetracter={
                    !policeResiliee && estEligibleALaRetractation
                  }
                  closeMenu={() => setIsMenuOpen(false)}
                  onPaymentPeriodicityClick={() => setContractPopinOpen(true)}
                  buttonRef={buttonRef}
                />
              </>
            </Box>
          )
        }
        refAction={
          !loadingMotifsResil && (
            <Box ml={1}>
              <IconButton
                ref={buttonRef}
                color="secondary"
                onClick={() => {
                  setIsMenuOpen(true);
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <MenuHeader
                isMobileVersion={true}
                sectionRefs={sectionRefs}
                police={police}
                policyStatus={policyStatus}
                isMenuOpen={isMenuOpen}
                peutResilier={!policeResiliee && estEligibleALaResiliation}
                peutSeRetracter={estEligibleALaRetractation}
                closeMenu={() => setIsMenuOpen(false)}
                onResiliationClick={() => setShowResiliationPopin(true)}
                onRetractationClick={() => setShowRetractationPopin(true)}
                onPaymentPeriodicityClick={() => setContractPopinOpen(true)}
                buttonRef={buttonRef}
              />
            </Box>
          )
        }
      />
      {motifsDeResiliation && (
        <>
          <TerminationPopin
            delaiEnJoursDeResilApresNotifSouscripteur={
              DELAI_RESIL_APRES_NOTIF_SOUSCRIPTEURS
            }
            motifsDeResiliation={motifsDeResiliation}
            police={police}
            adresse={adresse}
            policyStatus={policyStatus}
            isOpen={showResiliationPopin}
            onClose={() => setShowResiliationPopin(false)}
          />
          <RetractationPopin
            delaiEnJoursDeRetractation={DELAI_RETRACTATION_EN_JOURS}
            motifsDeResiliation={motifsDeResiliation}
            police={police}
            adresse={adresse}
            isOpen={showRetractationPopin}
            onClose={() => setShowRetractationPopin(false)}
          />
        </>
      )}

      <PaiementPeriodicitePopin
        police={police}
        isOpen={contractPopinOpen}
        onClose={() => setContractPopinOpen(false)}
      />
    </>
  );
};

PolicyHeader.propTypes = {
  police: PropTypes.object.isRequired,
  polichab: PropTypes.object.isRequired,
  policyStatus: PropTypes.string.isRequired,
  sectionRefs: PropTypes.object.isRequired,
};

export default PolicyHeader;
