import { getHeaderFetch, responseWithJson } from './httpUtil';

const NODE_BASE_URL = '/subscribe';
const API_BASE_URL_V3 = '/v3/souscription';

const subscribeApi = {
  async subscribe(auth, params, source) {
    const url = `${NODE_BASE_URL}`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(auth),
      body: JSON.stringify({ ...params, source: source }),
    });

    return responseWithJson(response);
  },

  async persisteInformationsSouscriptionPNO(auth, parcoursJson, idParcours) {
    const url = `${API_BASE_URL_V3}/pno/${idParcours}`;

    return await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(auth),
      body: JSON.stringify(parcoursJson),
    });
  },

  async persisteInformationsSouscriptionMRH(auth, parcoursJson, idParcours) {
    const url = `${API_BASE_URL_V3}/mrh/${idParcours}`;

    return await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(auth),
      body: JSON.stringify(parcoursJson),
    });
  },

  async recupereInformationsSouscriptionPNO(auth, idParcours) {
    let url = `${API_BASE_URL_V3}/pno/${idParcours}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: getHeaderFetch(auth),
    });

    return responseWithJson(response);
  },

  async recupereInformationsSouscriptionMRH(auth, idParcours) {
    let url = `${API_BASE_URL_V3}/mrh/${idParcours}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: getHeaderFetch(auth),
    });

    return responseWithJson(response);
  },
};

export default subscribeApi;
