import React, { useState, useRef } from 'react';
import {
  Typography,
  Grid,
  Box,
  IconButton,
  Hidden,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import './DashboardHeader.scss';
import useStyles from './style';

const Header = ({ title, subtitle, mainAction, otherActions = [], refAction }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const anchor = useRef();

  const handleActionClick = (e) => {
    setIsMenuOpen(false);
    e.action();
  };

  return (
    <Box className={classes.header}>
      <Grid container direction="row">
        <Grid item container alignItems="center" xs={10} md={7}>
          <Grid item container direction="column" xs={2}>
            <Box className={classes.arrowContainer}>
              {/* <IconButton color="secondary" onClick={() => history.goBack()}> */}
              <IconButton color="secondary" onClick={() => history.push('/polices')}>
                <ArrowBackIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item container direction="column" xs={10}>
            {title && (
              <Typography variant="h6" className={classes.text}>
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography
                variant="body1"
                color="secondary"
                className={classes.text}
              >
                {subtitle}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          xs={2}
          md={5}
          justifyContent="flex-end"
        >
          <Hidden mdDown>
            <Box>{mainAction}</Box>
          </Hidden>
          {
            refAction && 
            <Hidden mdUp>{refAction}</Hidden>
          }
          {!!otherActions.length && (
            <Hidden mdUp>
              <Box ml={1}>
                <IconButton
                  ref={anchor}
                  color="secondary"
                  onClick={() => {
                    setIsMenuOpen(true);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchor.current}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  keepMounted
                  open={isMenuOpen}
                  onClose={() => {
                    setIsMenuOpen(false);
                  }}
                >
                  {otherActions.map((e, i) => {
                    return (
                      <MenuItem key={i} onClick={() => handleActionClick(e)}>
                        {e.value}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Box>
            </Hidden>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;
