import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { HOUSING_TYPE_MAISON, LANGUAGES } from 'constants';
import getHumanReadableString from 'utils/getHumanReadableString';
import { stepsIds } from 'constants/steps/steps';
import { firstLetterUppercase } from 'utils';

const getEquipementsString = (equipements = [], t) => {
  return equipements
    .map((equip) => getHumanReadableString(equip, t))
    .join(', ');
};

const BienAssureInfo = (props) => {
  const { data, children, showTitle } = props;
  const { t } = useTranslation();

  if (i18next.resolvedLanguage === LANGUAGES.en) {
    return (
      <>
        {showTitle && (
          <>
            <Typography>
              {children && children}

              <Typography variant="h6" component="span" color="primary">
                {t('common.accomodation')}
              </Typography>
            </Typography>
            <br />
          </>
        )}

        <Typography variant="body1" color="textPrimary">
          <Typography variant="body1" component="span" color="primary">
            {data.housingType[0] === HOUSING_TYPE_MAISON
              ? `A ${t('common.house').toLowerCase()}`
              : `An ${t('common.apartment').toLowerCase()}`}
          </Typography>
          {data.area > 0 && (
            <>
              {' of '}
              <Typography variant="body1" component="span" color="primary">
                {` ${data.area} m²`}
              </Typography>
            </>
          )}{' '}
          with{' '}
          <Typography variant="body1" component="span" color="primary">
            {data.numberOfPrincipalRooms}{' '}
            {`room${data.numberOfPrincipalRooms > 1 ? 's' : ''} `}
          </Typography>
          <br />
          where you are the
          <Typography variant="body1" component="span" color="primary">
            {' '}
            {data.assuredQuality === 'P'
              ? t('common.owner').toLowerCase()
              : t('common.tenant').toLowerCase()}
          </Typography>
          {!!data.equipements?.length && (
            <span>
              {' and including the following: '}
              <Typography variant="body1" component="span" color="primary">
                {getEquipementsString(data.equipements, t)}
              </Typography>
            </span>
          )}
          {!data.equipements?.length && (
            <span>
              {' and '}
              <Typography variant="body1" component="span" color="primary">
                {'no extras'}
              </Typography>
            </span>
          )}
        </Typography>
      </>
    );
  } else {
    return (
      <>
        {showTitle && (
          <>
            <Typography>
              {children && children}

              <Typography variant="h6" component="span" color="primary">
                {t('common.accomodation')}
              </Typography>
            </Typography>
            <br />
          </>
        )}

        <Typography variant="body1" color="textPrimary">
          <Typography variant="body1" component="span" color="primary">
            {data.housingType[0] === 'M'
              ? `Une ${t('common.house')}`
              : `Un ${t('common.apartment')}`}
          </Typography>
          {data.area > 0 && (
            <>
              {' de '}
              <Typography variant="body1" component="span" color="primary">
                {` ${data.area} m²`}
              </Typography>
            </>
          )}
          , dont vous êtes{' '}
          <Typography variant="body1" component="span" color="primary">
            {data.assuredQuality === 'P'
              ? t('common.owner').toLowerCase()
              : t('common.tenant').toLowerCase()}
          </Typography>
          ,
          <br />
          comportant{' '}
          <Typography variant="body1" component="span" color="primary">
            {data.numberOfPrincipalRooms}{' '}
            {`pièce${data.numberOfPrincipalRooms > 1 ? 's' : ''} `}
          </Typography>
          {!!data.equipements?.length && (
            <span>
              {`et ${
                data.equipements.length > 1
                  ? 'les aménagements'
                  : "l'aménagement"
              } `}
              {`${data.equipements.length > 1 ? 'suivants' : 'suivant'} :`}
              <Typography variant="body1" component="span" color="primary">
                {` ${getEquipementsString(data.equipements, t)}`}
              </Typography>
            </span>
          )}
          {!data.equipements?.length && (
            <span>
              et
              <Typography variant="body1" component="span" color="primary">
                {' '}
                aucun aménagement spécifique.
              </Typography>
            </span>
          )}
        </Typography>
      </>
    );
  }
};

BienAssureInfo.propTypes = {
  data: PropTypes.shape(),
  goToStep: PropTypes.func,
  children: PropTypes.node,
  shwoTitle: PropTypes.bool,
};

BienAssureInfo.defaultProps = {
  data: {},
  showTitle: true,
};

export default BienAssureInfo;
