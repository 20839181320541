import { getHeaderFetch, responseWithJson } from './httpUtil';

const BASE_URL = '/v2/quotes';

const quotesApi = {
  generateQuote: async (params) => {
    const url = `${BASE_URL}/H`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },

  generatePnoQuote: async (params) => {
    const url = `${BASE_URL}/PNO`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },
};

export default quotesApi;
