import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { UserContext } from 'contexts/user';
import userApi from 'api/userApi';
import customerApi from 'api/customerApi';
import AdministrativeDirector from './AdministrativeDirector';
import Assistance from './Assistance';
import Customer from './Customer/index';
import Loader from 'components/Loader/index';
import { getMessageError } from 'constants/common/errorCode';

const INTERNAL = 'internal';
const EXTERNAL = 'external';
const ASSISTANCE = 'ASSISTANCE';
const CUSTOMER = 'CLIENT';

const Dashboard = (props) => {
  const { loginSystem } = props;
  const [loading, setLoading] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { t } = useTranslation();

  const [userState, dispatch] = React.useContext(UserContext);

  const setUser = (payload) => {
    dispatch({
      type: 'SET_USER',
      payload,
    });
  };

  // Client
  const getClientUSer = async (auth) => {
    setLoading(true);
    try {
      const userInfo = await oktaAuth.token.getUserInfo();
      const currentUser = await userApi.getExternalUser(auth);

      let currentUserInfo;
      if (currentUser && currentUser.customerNumber) {
        currentUserInfo = await customerApi.getUserInfo(
          auth,
          currentUser.customerNumber
        );
      }
      setUser({ ...userInfo, ...currentUser, ...currentUserInfo, auth });
    } catch (error) {
      if (error.status === 403) {
        history.push('/login');
        handleError(error, 'auth.error.finscan');
      }

      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (error, message) => {
    console.error(t(message), error);
    const messageError = getMessageError(error);
    enqueueSnackbar(t(messageError), { variant: 'error' });
  }

  const manageLoggedInUser = async () => {
    let auth = null;

    if (authState.isAuthenticated) {
      auth = {
        isAuthenticated: authState.isAuthenticated,
        token: authState.accessToken.accessToken,
      };
    }

    getClientUSer(auth);
  };

  useEffect(() => {
    manageLoggedInUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, loginSystem]);

  if (loginSystem === INTERNAL) {
    return <AdministrativeDirector />;
  }

  if (loginSystem === EXTERNAL && userState) {
    if (userState.profile && userState.profile.code === ASSISTANCE)
      return <Assistance />;

    if (userState.profile && userState.profile.code === CUSTOMER) {
      return <Customer />;
    }
  }
  return <Loader />;
};

Dashboard.propTypes = {
  loginSystem: PropTypes.string.isRequired,
};

export default Dashboard;
