import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import policyApi from 'api/policyApi';
import customerApi from 'api/customerApi';
import SinisterReason from 'components/SinisterReason/SinisterReason';
import Popin from 'views/Dashboard/Customer/PolicyPage/Certificates/Popin';
import useStyles from './style';
import { POLICY_PROPERTYS, getCertificates } from 'constants/policy/index';

const formatPersons = (items) => {
  const getItem = (item) => ({
    label: `${item.firstName} ${item.lastName}`,
    value: item,
  });

  if (Array.isArray(items))
    return items.map((item) => {
      return getItem(item);
    });

  return getItem(items);
};

const CertificateItem = ({ policy, user, auth }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [showPopin, setShowPopin] = useState(false);
  const [popinFields, setPopinFields] = useState([]);
  const [children, setChildren] = useState([]);
  const [roommates, setRoommates] = useState([]);
  const [coInsured, setCoInsured] = useState([]);
  const [certificateParams, setCertificatesParams] = useState({});
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [isValidateButtonDisabled, setIsValidateButtonDisabled] =
    useState(true);

  const currentUser = formatPersons({
    firstName: policy.customer.firstName,
    lastName: policy.customer.lastName,
    linkType: {
      label: t('dashboard.police.attestation.title_contrat'),
    },
  });

  const assetHousingAttes = POLICY_PROPERTYS[policy.contractType.code][policy.housingType].avatar;

  const handleClosePopin = () => {
    setCertificatesParams({});
    setShowPopin(false);
  };

  const handleValidatePopin = () => {
    redirectToCertificate();
  };

  const handleFieldChange = (event) => {
    const { target } = event;
    const newCertificateParams = { ...certificateParams };
    newCertificateParams[target.id] = target.value;
    setCertificatesParams(newCertificateParams);
  };

  const handleCertificateClick = (item) => {
    setSelectedCertificate(item);

    const newCertificatesParams = item.params.reduce((result, currentParam) => {
      const newParams = { ...result };

      if (currentParam.id === 'beneficiary' && coInsured.length === 1) {
        newParams[currentParam.id] = {
          fistName: currentUser.value.firstName,
          lastName: currentUser.value.lastName,
        };
      } else newParams[currentParam.id] = null;
      return newParams;
    }, {});

    setShowPopin(true);
    setPopinFields(item.params);
    setCertificatesParams(newCertificatesParams);
  };

  const redirectToCertificate = (
    certificate = selectedCertificate,
    params = certificateParams
  ) => {
    history.push('/attestation', {
      auth,
      policy,
      params: params,
      certificateType: {
        code: certificate.reason,
        label: certificate.label,
      },
    });
  };

  const getChildren = async () => {
    if (!policy.customer || !policy.customer.customerNumber || !auth) return;

    try {
      const result = await customerApi.getChildren(
        auth,
        policy.customer.customerNumber
      );
      setChildren(formatPersons(result));
    } catch (error) {
      console.log(t('dashboard.police.attestation.error_get_enfant'), error);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const getRoommates = async () => {
    if (!policy.policyNumber) return;

    try {
      const result = await policyApi.getAllColocCoassure(
        auth,
        policy.policyNumber
      );
      setRoommates(formatPersons(result));
    } catch (error) {
      console.log(t('dashboard.police.attestation.error_get_coloc'), error);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const isFormValid = () => {
    if (Object.entries(certificateParams).length !== popinFields.length)
      return false;

    const emptyParams = Object.values(certificateParams).findIndex(
      (value) => !value
    );

    if (emptyParams > -1) return false;

    return true;
  };

  useEffect(() => {
    getChildren();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRoommates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCoInsured([currentUser, ...children, ...roommates]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy, children, roommates]);

  useEffect(() => {
    setIsValidateButtonDisabled(!isFormValid());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificateParams]);

  return (
    <Grid container spacing={3}>
      <Grid item container spacing={3}>
        {getCertificates(coInsured, assetHousingAttes)[policy.contractType.code][policy.riskType.code].map((item, index) => (
          <SinisterReason
            key={index}
            asset={item.asset}
            label={t(item.title)}
            onClick={() => handleCertificateClick(item)}
          />
        ))}
      </Grid>
      <Popin
        title={selectedCertificate && t(selectedCertificate.label)}
        fields={popinFields}
        open={showPopin}
        onClose={handleClosePopin}
        onValidate={handleValidatePopin}
        handleFieldChange={handleFieldChange}
        isValidateButtonDisabled={isValidateButtonDisabled}
        policyNumber={policy.policyNumber}
        hideOptionManageBenificiaire={!!selectedCertificate?.hideOptionManageBenificiaire}
      />
    </Grid>
  );
};

export default CertificateItem;
