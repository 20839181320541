import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import InputsStep from 'components/InputsStep/InputsStep';
import Title from 'components/Title';
import { isEmpty } from 'utils/form-validation';
import CommercialPremiseLottie from 'assets/lotties/localcommercial.json';
import GarageLottie from 'assets/lotties/garage.json';
import {
  PNO_TYPE_APPARTEMENT,
  PNO_TYPE_GARAGE,
  PNO_TYPE_LOCAL_COMMERCIAL,
  PNO_TYPE_MAISON,
} from 'constants/common/bienType';

const PropertyInfoStep = (props) => {
  const { data, onConfirm, setUserData } = props;
  const { t } = useTranslation();

  const isGarage = data.housingType === PNO_TYPE_LOCAL_COMMERCIAL;
  const isCommercialPremise = data.housingType === PNO_TYPE_GARAGE;
  const isGarageOrCommercial = isGarage || isCommercialPremise;

  const getTitle = () => {
    switch (data.housingType) {
      case PNO_TYPE_LOCAL_COMMERCIAL:
        return t('pno.propertyInfoStep.titleCommercialPremise');
      case PNO_TYPE_GARAGE:
        return t('pno.propertyInfoStep.titleGarage');
      default:
        return t('pno.propertyInfoStep.title');
    }
  };

  const getLottie = () => {
    switch (data.housingType) {
      case PNO_TYPE_LOCAL_COMMERCIAL:
        return CommercialPremiseLottie;
      case PNO_TYPE_GARAGE:
        return GarageLottie;
      default:
        return null;
    }
  };

  let pnoFields = [
    {
      id: 'numberOfPrincipalRooms',
      label: t('pno.propertyInfoStep.numberOfRooms'),
      info: (
        <Trans
          i18nKey="pno.propertyInfoStep.mainRoomsInfo"
          components={{ italic: <i /> }}
        />
      ),
      type: 'number',
      invalid:
        isEmpty(data.numberOfPrincipalRooms) ||
        parseInt(data.numberOfPrincipalRooms) < 1 ||
        parseInt(data.numberOfPrincipalRooms) > 7,
      inputProps: { min: 0, max: 7 },
      helperText: (() => {
        if (isEmpty(data.numberOfPrincipalRooms)) {
          return t('error.fields.fieldRequired');
        }
        if (parseInt(data.numberOfPrincipalRooms) < 1) {
          return t('pno.propertyInfoStep.errors.numberOfRoomsTooSmall');
        }

        if (parseInt(data.numberOfPrincipalRooms) > 7) {
          return t('pno.propertyInfoStep.errors.numberOfRoomsTooBig');
        }
      })(),
      value: data.numberOfPrincipalRooms
        ? parseInt(data.numberOfPrincipalRooms)
        : '',
    },
    {
      id: 'area',
      label: t('pno.propertyInfoStep.area'),
      type: 'number',
      invalid:
        isEmpty(data.area) ||
        parseInt(data.area) < 5 ||
        parseInt(data.area) > 150,
      inputProps: { min: 0, max: 150 },
      helperText: (() => {
        if (isEmpty(data.area)) {
          return t('error.fields.fieldRequired');
        }

        if (parseInt(data.area) < 5) {
          return t('pno.propertyInfoStep.errors.areaTooSmall');
        }

        if (parseInt(data.area) > 150) {
          return (
            <Trans
              i18nKey="pno.propertyInfoStep.errors.areaTooBig"
              values={{ area: 150 }}
            />
          );
        }
      })(),
      value: data.area ? parseInt(data.area) : '',
    },
    {
      id: 'floor',
      label:
        data.housingType === PNO_TYPE_APPARTEMENT
          ? t('pno.propertyInfoStep.floorA')
          : t('pno.propertyInfoStep.floorM'),
      type: 'number',
      invalid: data.floor && parseInt(data.floor) > 30,
      inputProps: {
        min: -1,
        max: 30,
      },
      helperText: (() => {
        if (parseInt(data.floor) > 30) {
          return t('pno.propertyInfoStep.errors.floorTooHigh');
        }
      })(),
      value: data.floor ? parseInt(data.floor) : '',
    },
  ];

  if (data.housingType === PNO_TYPE_APPARTEMENT) {
    pnoFields = [
      ...pnoFields,
      {
        id: 'historical',
        label: t('pno.propertyInfoStep.historical'),
        type: 'checkbox',
        value: data.historical,
        checked: !!data.historical,
      },
    ];
  }

  const pneFields = [
    {
      id: 'area',
      label: t('pno.propertyInfoStep.area'),
      type: 'number',
      invalid:
        isEmpty(data.area) ||
        parseInt(data.area) < 5 ||
        parseInt(data.area) > 350,
      inputProps: { min: 0, max: 350 },
      helperText: (() => {
        if (isEmpty(data.area)) {
          return t('error.fields.fieldRequired');
        }

        if (parseInt(data.area) < 5) {
          return t('pno.propertyInfoStep.errors.areaTooSmall');
        }

        if (parseInt(data.area) > 8) {
          return (
            <Trans
              i18nKey="pno.propertyInfoStep.errors.areaTooBig"
              values={{ area: 350 }}
            />
          );
        }
      })(),
      value: data.area ? parseInt(data.area) : '',
    },
  ];

  useEffect(() => {
    if (data.housingType === PNO_TYPE_APPARTEMENT) {
      setUserData('contractAddress.appartementNumber', null);
      setUserData('contractAddress.emplacement', null);
    }
  }, []);

  return (
    <>
      <Title title={getTitle()} />
      <InputsStep
        lottie={getLottie()}
        isNotFloor={isGarageOrCommercial}
        isFloor={!(data.housingType === PNO_TYPE_MAISON)}
        onChange={setUserData}
        onConfirm={onConfirm}
        twoPerLine={isGarageOrCommercial ? false : true}
        text={`${t('pno.propertyInfoStep.info')}`}
        fields={isGarageOrCommercial ? pneFields : pnoFields}
      />
    </>
  );
};

PropertyInfoStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
};

export default PropertyInfoStep;
