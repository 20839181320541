import React from 'react';
import { Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import ContractDetails from './ContractDetails';
import ContractDetailsSkeleton from './ContractDetails/skeleton';
import MyCriteria from './MyCriteria';
import MySelection from './MySelection';
import MyCriteriaSkeleton from './MyCriteria/skeleton';
import MySelectionSkeleton from './MySelection/skeleton';
import CardSelection from './CardSelection/index';
import { PAGE_SECTION_SHOW } from '../helper';
import useStyles from '../style';

const PolicyDetails = ({ sectionRefs, police, polichab }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Box className={classes.container} width="100%">
        <div ref={sectionRefs.contractDetail.ref}>
          <Grid container>
            {police && polichab ? (
              PAGE_SECTION_SHOW[police.contractType.code].contractDetail && (
                <ContractDetails policy={police} polichab={polichab} />
              )
            ) : (
              <ContractDetailsSkeleton />
            )}
            {police && polichab ? (
              PAGE_SECTION_SHOW[police.contractType.code].myCriteria && (
                <MyCriteria polichab={polichab} police={police} />
              )
            ) : (
              <MyCriteriaSkeleton />
            )}
            {police && polichab ? (
              PAGE_SECTION_SHOW[police.contractType.code].selection && (
                <MySelection policy={police} polichab={polichab} />
              )
            ) : (
              <MySelectionSkeleton />
            )}
            {police &&
              PAGE_SECTION_SHOW[police.contractType.code].cards &&
              police.cardInfo && (
                <div style={{ width: '100%' }} ref={sectionRefs.cards.ref}>
                  <CardSelection policy={police} />
                </div>
              )}
          </Grid>
        </div>
      </Box>
    </Grid>
  );
};

PolicyDetails.propTypes = {
  sectionRefs: PropTypes.object.isRequired,
  police: PropTypes.object,
  polichab: PropTypes.object,
};

export default PolicyDetails;
