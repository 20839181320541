import {
  Box,
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import customerApi from 'api/customerApi';
import noCard from 'assets/lotties/no-card.json';
import Lottie from 'components/Lottie';
import { UserContext } from 'contexts/user';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardSkeleton from 'components/CardSkeleton/index';
import CardItem from './CardItem';

CardList.propTypes = {
  cardSelected: PropTypes.string,
  onSelect: PropTypes.func,
  onActionAlert: PropTypes.func,
};

const useStyles = makeStyles(() => ({
  alert: {
    fontSize: '0.9rem',
  },
}));

function CardList(props) {
  const { cardSelected, onSelect, onActionAlert } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const auth = useAuth();
  const [cards, setCards] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [user] = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (auth.isAuthenticated) {
        try {
          setLoading(true);
          const res = await customerApi.listCard(auth, user.customerNumber);
          if (res && res.length && cardSelected) {
            const cardSelIdx = res.findIndex(
              (c) => c.bankcardUuid === cardSelected
            );
            if (cardSelIdx >= 0) {
              res[cardSelIdx] = { ...res[cardSelIdx], selected: true };
            }
          }
          setCards(res);
        } catch (err) {
          console.log(err);
          enqueueSnackbar(err.message, { variant: 'error' });
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [auth.isAuthenticated]);

  const handleSelectCard = (card) => {
    let newListCard = [...cards];
    const cardSelIdx = cards.findIndex(
      (c) => c.bankcardUuid === card.bankcardUuid
    );
    if (cardSelIdx >= 0) {
      newListCard = newListCard.map((c) => ({ ...c, selected: false }));
      newListCard[cardSelIdx] = { ...newListCard[cardSelIdx], selected: true };
    }
    setCards(newListCard);
    onSelect && onSelect(card);
  };

  const handleAlert = () => {
    onActionAlert && onActionAlert();
  };

  return (
    <Box width="100%">
      {loading ? (
        <CardSkeleton length={3} />
      ) : (
        <>
          {cards.length ? (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <caption>
                  <Alert
                    severity="info"
                    action={
                      <Button
                        color="inherit"
                        size="small"
                        onClick={handleAlert}
                      >
                        {t('dashboard.card.police.list.action_btn')}
                      </Button>
                    }
                    className={classes.alert}
                  >
                    {t('dashboard.card.police.list.action_label')}
                  </Alert>
                </caption>
                <TableBody>
                  {cards
                    .filter((item) => item.valid)
                    .map((card, index) => (
                      <CardItem
                        key={index}
                        card={card}
                        onSelect={handleSelectCard}
                        size="small"
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              <Lottie animationData={noCard} isActive height={480} />
              <Typography align="center" color="inherit">
                {t('dashboard.card.list.no_card')}
              </Typography>
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default CardList;
