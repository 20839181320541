import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import policyApi from 'api/policyApi';
import QuittanceItem from './QuittanceItem';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  quittances: {
    paddingTop: theme.spacing(1),
  },
  container: {
    borderRadius: 10,
    backgroundColor: '#fff',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
}));

function Quittances(props) {
  const { police, auth } = props;
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [quittances, setQuittances] = useState(null);
  const { t } = useTranslation();

  const refresh =
    location.state?.refresh ||
    !!location.search.split('?').find((element) => element.includes('refresh'));

  const getQuittances = async () => {
    try {
      const response = await policyApi.getListQuittance(
        auth,
        police.policyNumber
      );
      setQuittances(response);
    } catch (error) {
      console.error(
        'Failed get list quittances with policeNumber',
        police.policyNumber
      );

      const messageError = getMessageError(error);
      enqueueSnackbar(t(messageError), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (!police.policyNumber) return;

    getQuittances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!quittances) return null;

  return (
    <Grid container className={classes.quittances}>
      <Box p={2} className={classes.container} width="100%">
        <Grid container>
          <Box mb={2}>
            <Typography variant="h5" color="secondary">
              {t('dashboard.police.premiums.premiums_title')}
            </Typography>
          </Box>
          <Grid item container>
            {quittances.map((quittance, index) => (
              <QuittanceItem
                key={index}
                quittance={quittance}
                police={police}
                getQuittances={getQuittances}
                refresh={refresh}
              />
            ))}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}

Quittances.propTypes = {
  police: PropTypes.object,
  auth: PropTypes.object,
  getQuittances: PropTypes.func,
};

Quittances.defaultProps = {
  police: {},
  user: {},
};

export default Quittances;
