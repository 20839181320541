import {
  getHeaderCustomFetch,
  getHeaderFetch,
  responseWithJson,
  responseWithBlob,
  responseWithVoid,
} from './httpUtil';

const BASE_URL = '/v2/policies';

const policyApi = {
  async getAllColocCoassure(auth, policyNumber) {
    const url = `${BASE_URL}/${policyNumber}/persons`;
    const response = await fetch(url, { headers: getHeaderFetch(auth) });
    return responseWithJson(response);
  },

  async generateCertificate(auth, policyNumber, params = {}) {
    // const paramList = Object.keys(params).reduce((curr, item) => {
    //   if (params[item]) curr[item] = params[item];
    //   return curr;
    // }, {});

    const paramsAsQueryString = Object.keys(params).reduce(
      (result, currentKey) => {
        if (!params[currentKey]) return result;

        const stringToAdd = `${currentKey}=${params[currentKey]}`;
        return `${result}&${stringToAdd}`;
      },
      ''
    );

    let url = `${BASE_URL}/${policyNumber}/certificate?${new URLSearchParams(
      paramsAsQueryString
    ).toString()}`;

    const response = await fetch(url, { headers: getHeaderFetch(auth) });
    return responseWithBlob(response);
  },

  async getPolice(auth, policyNumber) {
    const url = `${BASE_URL}/${policyNumber}`;

    const response = await fetch(url, { headers: getHeaderFetch(auth) });
    return responseWithJson(response);
  },

  async terminatePolice(auth, policyNumber, params) {
    const url = `${BASE_URL}/${policyNumber}/termination`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(auth),
      body: JSON.stringify({ ...params, source: 'Extranet' }),
    });
    return responseWithJson(response);
  },

  async getPolichab(auth, policyNumber, typePolicy) {
    const url = `${BASE_URL}/${typePolicy}/${policyNumber}`;

    const response = await fetch(url, { headers: getHeaderFetch(auth) });
    return responseWithJson(response);
  },

  async getListQuittance(auth, policyNumber) {
    const url = `${BASE_URL}/${policyNumber}/paymentPlan`;

    const response = await fetch(url, { headers: getHeaderFetch(auth) });
    return responseWithJson(response);
  },

  async getHPolicy(params) {
    const url = `${BASE_URL}/H`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },

  async getPNOPolicy(params) {
    const url = `${BASE_URL}/PNO`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },

  async getPaymentPlan(params) {
    const url = `${BASE_URL}/paymentplan`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },

  async search(auth, params) {
    const url = `${BASE_URL}/search`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(auth),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },

  async uploadFileToGed(auth, policyNumber, fileType, params) {
    const url = `${BASE_URL}/${policyNumber}/ged/upload/${fileType}`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderCustomFetch(auth),
      body: params,
    });
    return responseWithVoid(response);
  },

  async createClaim(auth, policyNumber, params) {
    const url = `${BASE_URL}/${policyNumber}/claim`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(auth),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },

  async getPoliciesByOfficeCode(auth, societeRattachement, types, period) {
    let url = `${BASE_URL}/office/${societeRattachement}?types=${types}&period=${period}`;
    if (societeRattachement === '8003') {
      //michael Prod et recette
      url = `${BASE_URL}/office/0140?types=${types}&period=${period}`; //0675
    } else if (societeRattachement === '8014') {
      //Guillaume Prod
      url = `${BASE_URL}/office/0140?types=${types}&period=${period}`;
    } else if (societeRattachement === '0503') {
      //Guillaume Recette
      url = `${BASE_URL}/office/0140?types=${types}&period=${period}`;
    }

    const response = await fetch(url, {
      method: 'GET',
      headers: getHeaderFetch(auth),
    });

    try {
      const jsonResponse = await responseWithJson(response);
      return jsonResponse;
    } catch {
      return [];
    }
  },

  async getPoliciesByOfficeCodeAndPlanifiedPayment(
    auth,
    societeRattachement,
    planifiedPaymentUuid
  ) {
    // planifiedPaymentUuid = '1a7e1091-37cb-423a-9966-603b83fdd8b0';
    let url = `${BASE_URL}/paymentPlannified/office/${societeRattachement}?uuid=${planifiedPaymentUuid}`;
    // url = `${BASE_URL}/paymentPlannified/office/0290?uuid=${planifiedPaymentUuid}`;

    if (societeRattachement === '8003') {
      url = `${BASE_URL}/paymentPlannified/office/0140?uuid=${planifiedPaymentUuid}`;
    } else if (societeRattachement === '8014') {
      url = `${BASE_URL}/paymentPlannified/office/0140?uuid=${planifiedPaymentUuid}`;
    } else if (societeRattachement === '0503') {
      url = `${BASE_URL}/paymentPlannified/office/0140?uuid=${planifiedPaymentUuid}`;
    }

    const response = await fetch(url, {
      method: 'GET',
      headers: getHeaderFetch(auth),
    });

    try {
      // const mockResponseJson = [...Array(60)].map((item, index) => {
      //   return {
      //     policyNumber: index,
      //     policyKey: '2 Rue Ordener 75018 Paris',
      //     startDate: '2022-09-21',
      //     socRef: '0290',
      //     contractAnniversaryDate: 101,
      //     annualTtc: 223.52,
      //     typePolicy: {
      //       code: 'H',
      //       label: 'Habitation',
      //       description: null,
      //     },
      //     paymentSplitting: {
      //       code: 'M',
      //       label: 'mensuel',
      //       description: null,
      //     },
      //     numeroImmeuble: '4029',
      //     agence: null,
      //     adresse1: "50 Rue d'Erévan",
      //     adresse2: null,
      //     gestionnaireNom: null,
      //     etatPP: 'PLANIFIE',
      //     premiums: [
      //       {
      //         id: 22112179,
      //         montant: 24.82,
      //         uuid: '1bac5111-4f70-4afb-b8df-84c1b4da2c1d',
      //         type: {
      //           code: 'VIRE',
      //           label: null,
      //           description: null,
      //         },
      //         paymentMethod: {
      //           code: 'VIRE',
      //           label: 'Virement',
      //           description: ' Mode de paiement par virement',
      //         },
      //         status: null,
      //         datePrelevement: '18/08/2022',
      //         startDate: '21/09/2022',
      //         endDate: '31/10/2022',
      //         sens: 'D',
      //         compagnie: null,
      //         dateComptable: null,
      //         dateIndice: null,
      //         plannifiedPaymentQuittanceId: null,
      //         net: null,
      //         tax: null,
      //         ibanFrom: null,
      //         etatPPQ: 'PLANIFIE',
      //       },
      //     ],
      //   };
      // });

      const jsonResponse = await responseWithJson(response);

      return jsonResponse;
    } catch (e) {
      return [];
    }
  },

  async changePaymentMode(
    auth,
    societeRattachement,
    policyNumber,
    paymentMode,
    comment,
    paymentPlannedUuid
  ) {
    const url = `${BASE_URL}/${policyNumber}/change-payment-mode`;

    let officeCode = societeRattachement;
    if (['8003', '8014', '0503'].includes(societeRattachement)) {
      officeCode = '0140';
    }

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        ...getHeaderFetch(auth),
        'office-code': officeCode,
        // 'office-code': '0290',
      },
      body: JSON.stringify({
        modePayment: paymentMode,
        paymentPlannedUuid,
        // groupment: '2021-06',
        reasonRefusal: comment,
      }),
    });

    return await responseWithVoid(response);
  },

  async uploadFileToClaim(claimId, params, userLogin) {
    // const url = `${policyNumber}/claim`;
    // const response = await fetch(url, {
    //   method: 'POST',
    //   headers: getHeaderFetch(userLogin),
    //   body: JSON.stringify(params),
    // });
    // return responseWithJson(response);
  },

  async listAllClaims(auth, policyNumber) {
    const url = `${BASE_URL}/${policyNumber}/claims`;

    const response = await fetch(url, { headers: getHeaderFetch(auth) });
    return responseWithJson(response);
  },

  async getPersons(auth, policyNumber, linkType) {
    const url = `${BASE_URL}/${policyNumber}/persons?linkType=${linkType}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: getHeaderFetch(auth),
    });
    return responseWithJson(response);
  },

  async addPerson(auth, policyNumber, params) {
    const url = `${BASE_URL}/${policyNumber}/persons`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(auth),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },

  async updatePerson(auth, policyNumber, personUuid, params) {
    const url = `${BASE_URL}/${policyNumber}/persons/${personUuid}`;

    const response = await fetch(url, {
      headers: getHeaderFetch(auth),
      method: 'PUT',
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },

  async updateBankcard(auth, policyNumber, bankcardUuid) {
    const url = `${BASE_URL}/${policyNumber}/update-bankcard/${bankcardUuid}`;

    const response = await fetch(url, {
      method: 'PUT',
      headers: getHeaderFetch(auth),
    });
    return responseWithVoid(response);
  },

  async resilHamon(auth, policyNumber, params) {
    const url = `${BASE_URL}/${policyNumber}/resil-hamon`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(auth),
      body: JSON.stringify(params),
    });
    return responseWithVoid(response);
  },
  async updatePeriodicity(auth, policyNumber, codeFractionnement) {
    const url = `${BASE_URL}/${policyNumber}/fractionnement/${codeFractionnement}`;

    const response = await fetch(url, {
      headers: getHeaderFetch(auth),
      method: 'POST',
    });
    return responseWithJson(response);
  },
  async terminationPoliceDraft(auth, policyNumber) {
    const url = `${BASE_URL}/${policyNumber}/termination-draft`;

    const response = await fetch(url, {
      headers: getHeaderFetch(auth),
      method: 'POST',
    });
    return responseWithVoid(response);
  },

  async documentContractuel(auth, policyNumber){
    const url = `${BASE_URL}/${policyNumber}/contractualsDocuments`;
    const response = await fetch(url, { headers: getHeaderFetch(auth) });
    return responseWithJson(response);
  },
};

export default policyApi;
