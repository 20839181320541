import quotesApi from 'api/quotesApi';
import { PNO_CODE_RISQUE } from 'constants/index';
import { getHousingType } from 'constants/common/bienType';

const generateQuote = (data, parcoursId) => {
  const params = {
    firstName: data.contact.firstName,
    lastName: data.contact.lastName,
    mail: data.contact.mail,
    risk: {
      address: data.contractAddress,
      housingType: getHousingType(data),
      numberPieces: parseInt(data.numberOfPrincipalRooms),
      area: data.area ? parseInt(data.area) : null,
      floor: data.floor ? parseInt(data.floor) : null,
      furnished: data.furnished,
      equipment: {},
    },
    riskType: PNO_CODE_RISQUE,
    originalCode: data.from ? data.from : null,
    promotionCode: data.promotionCode ? data.promotionCode : null,
    souscriptionParcoursId: parcoursId,
  };

  return quotesApi.generatePnoQuote(params);
};

export { generateQuote };
