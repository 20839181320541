import ApiError from './apiError';
import { getHeaderFetch } from './httpUtil';

const BASE_URL = '/auth';

const responseWithJson = (res) => {
  if (res.ok) {
    const contentType = res.headers.get('content-type');
    if (!contentType || !contentType.includes('application/json')) {
      return;
    }
    return res.json();
  }

  throw new Error(`${res.status} - ${res.statusText}`);
};

const responseWithJsonError = async (res) => {
  if (res.ok) {
    const contentType = res.headers.get('content-type');
    if (!contentType || !contentType.includes('application/json')) {
      return;
    }
    return res.json();
  } else {
    const error = await res.json();
    if (error.errorCode) {
      throw new ApiError(error);
    } else {
      throw new ApiError({
        errorMessage: error.errorMessage,
        errorStatus: null,
        errorCode: null,
      });
    }
  }
};

const authApi = {
  async signup(params) {
    const url = `${BASE_URL}/signup`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },

  async activate(params) {
    const url = `${BASE_URL}/user/activate`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify(params),
    });
    return responseWithJsonError(response);
  },

  async reactivate(params) {
    const url = `${BASE_URL}/user/reactivate`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify(params),
    });
    return responseWithJsonError(response);
  },

  async setCredentials(params) {
    const url = `${BASE_URL}/set-credentials`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify(params),
    });
    return responseWithJsonError(response);
  },

  async getConfig() {
    const url = `${BASE_URL}/config`;

    const response = await fetch(url, {
      method: 'GET',
      headers: getHeaderFetch(),
    });
    return responseWithJson(response);
  },

  async recoverPassword(params) {
    const url = `${BASE_URL}/recovery/password`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },

  async getRecoveryQuestion(params) {
    const url = `${BASE_URL}/recovery/answer`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },

  async changePassword(params) {
    const url = `${BASE_URL}/recovery/change-password`;
    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },

  async verifyUser(login) {
    const url = `${BASE_URL}/signin/verify`;
    return fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify({
        login,
      }),
    });
  },

  ////
  async sendRecoveryLinkByMail(login) {
    const url = `${BASE_URL}/recovery`;
    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify({ login }),
    });
    return response;
  },

  async verifyRecoveryToken(token) {
    const url = `${BASE_URL}/recovery/verify`;
    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify({ token }),
    });
    return responseWithJson(response);
  },

  async answerRecoveryQuestion(stateToken, answer) {
    const url = `${BASE_URL}/recovery/answer`;
    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify({ stateToken, answer }),
    });

    return response;
  },

  async resetPassword(stateToken, password) {
    const url = `${BASE_URL}/recovery/reset`;
    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify({ stateToken, password }),
    });
    return responseWithJsonError(response);
  },
};

export default authApi;
