import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Hidden from '@material-ui/core/Hidden';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Lottie from 'components/Lottie';
import PriceCard from 'components/PriceCard/PriceCard';
import { formatDateWithSystem, calculerDateEcheance, formatDate } from 'utils';
import useStyles from './style';
import { POLICY_PROPERTYS } from 'constants/policy/index';
import {
  getPolicyStatus,
  policeStatuses as statuses,
} from 'views/Dashboard/Customer/PolicyPage/helper';

const StyledCardActions = withStyles({
  root: {
    padding: '1rem',
    borderTop: '2px solid #F8F8F8',
  },
})(CardActions);

const WhiteContainedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.orange.main,
    backgroundColor: `${theme.palette.white.main} !important`,
  },
}))(Button);

const ActivePolicyItemActions = ({ policyNumber, policyStatus, onRemove }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const linkSinistre = `/polices/${policyNumber}/nouveau-sinistre`;
  const linkToPolicyPage = `/polices/${policyNumber}`;

  const handleRemovePolice = () => {
    if (!onRemove) return;
    onRemove(policyNumber);
  };

  return (
    <Box className={classes.actions} display="flex" alignItems="center">
      {[statuses.ACTIVE, statuses.ENDED_FUTUR, statuses.ENDED].includes(
        policyStatus
      ) && (
        <Box className={classes.action}>
          <Button
            align="center"
            variant="outlined"
            color="primary"
            size="small"
            component={Link}
            to={linkSinistre}
          >
            {t('dashboard.policies.declareClaim')}
          </Button>
        </Box>
      )}
      {policyStatus === statuses.PENDING && (
        <Box className={classes.action}>
          <Button
            align="center"
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleRemovePolice}
          >
            {t('dashboard.policies.deleteThisQuote')}
          </Button>
        </Box>
      )}
      <Box className={classes.action}>
        <Button
          align="center"
          variant="contained"
          color="primary"
          size="small"
          endIcon={<ArrowForwardIcon />}
          component={Link}
          to={linkToPolicyPage}
        >
          {t('dashboard.policies.details')}
        </Button>
      </Box>
    </Box>
  );
};

ActivePolicyItemActions.propTypes = {
  policyNumber: PropTypes.string.isRequired,
  policyStatus: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
};

const EndedPolicyItemActions = ({ policyNumber }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      className={classes.actions}
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Box className={classes.action}>
        <WhiteContainedButton
          align="center"
          variant="contained"
          color="primary"
          size="small"
          endIcon={<ArrowForwardIcon />}
          component={Link}
          to={`/polices/${policyNumber}`}
        >
          {t('dashboard.policies.seeDetails')}
        </WhiteContainedButton>
      </Box>
    </Box>
  );
};

EndedPolicyItemActions.propTypes = {
  policyNumber: PropTypes.string.isRequired,
};

const getPolicyInfos = (policy, t) => [
  {
    label: t('dashboard.policies.contractNumber'),
    value: policy.policyNumber,
  },
  {
    label: t('dashboard.policies.startDate'),
    value: formatDateWithSystem(policy.startDate),
  },
  {
    label: t('dashboard.policies.term'),
    value: calculerDateEcheance(policy.contractAnniversaryDate),
  },
];

const PolicyItem = (props) => {
  const { policy, onRemove } = props;
  const { t } = useTranslation();
  const policyStatus = getPolicyStatus(policy);
  const policyInfos = getPolicyInfos(policy, t);
  const classes = useStyles();
  const isActive = policyStatus !== statuses.ENDED;

  const getPolicyTypeLabel = (isPending) => {
    const {
      contractType: { code },
      housingType,
    } = policy;
    const label = `dashboard.police.policyTypes.${code.toLowerCase()}${housingType}`;

    return isPending ? t(`${label}Quotation`) : t(label);
  };

  return (
    <Box mb={2} width={1}>
      <Card>
        <CardContent className={classes.content}>
          <Box
            className={classes.details}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              className={classes.contract}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box ml={2} mr={2} display="flex">
                <Hidden smDown>
                  <Lottie
                    animationData={
                      POLICY_PROPERTYS[policy.contractType.code][
                        policy.housingType
                      ]?.avatar
                    }
                    isActive
                    width={116}
                    height={116}
                  />
                </Hidden>
                <Hidden mdUp>
                  <Lottie
                    animationData={
                      POLICY_PROPERTYS[policy.contractType.code][
                        policy.housingType
                      ]?.avatar
                    }
                    isActive
                    width={80}
                    height={80}
                  />
                </Hidden>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                height={1}
              >
                <Box>
                  <Typography variant="h6">
                    {`${getPolicyTypeLabel(policyStatus === statuses.PENDING)}`}
                  </Typography>
                  <Typography variant="body2" className={classes.infoValue}>
                    {policy.policyKey}
                  </Typography>
                </Box>
                {/* {status === 'sinister' && (
                  <Box display="flex" mt={2}>
                    <Alert className={classes.alert} severity="error">
                      Sinistre en cours
                    </Alert>
                  </Box>
                )}*/}
                <Box display="flex" mt={2}>
                  {policyStatus === statuses.PENDING && (
                    <Alert className={classes.alert} severity="warning">
                      {t('dashboard.police.statuses.waitingForPayment')}
                    </Alert>
                  )}
                  {policyStatus === statuses.CANCELLED && (
                    <Alert severity="info">
                      {t('dashboard.police.statuses.cancelled')}
                      {formatDate(policy.statusDate, 'DD/MM/YYYY')}
                    </Alert>
                  )}
                  {policyStatus === statuses.ENDED && (
                    <Alert className={classes.alert} severity="info">
                      {t('dashboard.police.statuses.terminated')}
                      {formatDate(policy.statusEffectiveDate, 'DD/MM/YYYY')}
                    </Alert>
                  )}
                  {policyStatus === statuses.ENDED_FUTUR && (
                    <Alert className={classes.alert} severity="info">
                      {t('dashboard.police.statuses.toBeTerminated')}
                      {formatDate(policy.statusEffectiveDate, 'DD/MM/YYYY')}
                    </Alert>
                  )}
                </Box>
              </Box>
            </Box>
            {isActive && (
              <Hidden xsDown implementation="css">
                <Box width={280}>
                  <PriceCard policy={policy} />
                </Box>
              </Hidden>
            )}
          </Box>
        </CardContent>
        <StyledCardActions
          className={
            policyStatus === statuses.ENDED ? classes.endedActions : ''
          }
        >
          <Box className={classes.bottom} width={1}>
            <Box className={classes.infos}>
              <Grid container>
                {policyInfos.map((info, i) => (
                  <Box
                    className={classes.info}
                    key={`info-${i}`}
                    mr={i <= policyInfos.length - 1 ? 3 : 0}
                  >
                    <Typography variant="caption" className={classes.infoLabel}>
                      {info.label}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={`${classes.infoValue} ${
                        !isActive ? classes.whiteText : ''
                      }`}
                    >
                      {info.value}
                    </Typography>
                  </Box>
                ))}
              </Grid>
            </Box>
            {/* {isActive && ( */}
            <ActivePolicyItemActions
              policyNumber={policy.policyNumber}
              policyStatus={policyStatus}
              onRemove={onRemove}
            />
            {/* )} */}
            {/* {status === statuses.PENDING && <PendingPolicyItemActions />}
            {status === statuses.ENDED && (
              <EndedPolicyItemActions policyNumber={policy.policyNumber} />
            )} */}
          </Box>
        </StyledCardActions>
      </Card>
    </Box>
  );
};

PolicyItem.propTypes = {
  policy: PropTypes.shape().isRequired,
  onRemove: PropTypes.func,
};

export default PolicyItem;
