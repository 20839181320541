import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import ChildItem from 'components/ChildItem';
import ChildrenModal from 'components/ChildrenModal';
import Popin from 'components/Popin';
import customerApi from 'api/customerApi';
import policyApi from 'api/policyApi';

import { UserContext } from 'contexts/user';
import useStyles from './style';

const LINK_TYPES = {
  children: { code: 'ENFANT', label: 'Enfant' },
  roommate: { code: 'COLOCATAIRE', label: 'Colocataire/Co-assuré' },
};
const MODALS = {
  update: 'update',
  delete: 'delete',
};

const Beneficiaries = (props) => {
  const { police, policyNumber, beneficiaries, setBeneficiaries } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [user] = React.useContext(UserContext);
  const [userState] = useContext(UserContext);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedChild, setSelectedChild] = useState();
  const [children, setchildren] = useState([]);
  const [popinOpen, setPopinOpen] = useState(false);
  const [roommates, setRoommates] = useState([]);

  const fetchChildren = async () => {
    try {
      const [childrenList, roommatesList] = await Promise.all([
        customerApi.getChildren(userState.auth, police.customer.customerNumber),
        policyApi.getPersons(
          userState.auth,
          police.policyNumber,
          'COLOCATAIRE'
        ),
      ]);

      setchildren(childrenList);
      setRoommates(roommatesList);
    } catch (error) {
      console.log(t('dashboard.police.attestation.error_get_enfant'), error);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (police) fetchChildren();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [police, userState]);

  useEffect(() => {
    setBeneficiaries([...children, ...roommates]);
  }, [children, roommates]);

  const handleCloseModal = (modal) => {
    if (modal === MODALS.update) setPopinOpen(false);
    else setDeleteModalOpen(false);
    setSelectedChild();
  };

  const handleOpenModal = (modal, child) => {
    if (child) {
      setSelectedChild(child);
    }
    if (modal === MODALS.update) setPopinOpen(true);
    else setDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      await customerApi.deletePerson(
        user.auth,
        user.customerNumber,
        selectedChild.personUuid
      );
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar(
        "Une erreur est survenue lors de la suppression de l'enfant, Veuillez réessayer ultérieurement ou contacter Assurimo si le problème persiste",
        { variant: 'error' }
      );
    } finally {
      fetchChildren();
    }
  };

  const handleValidate = (child, isModification) => {
    if (isModification) {
      modifyPerson(child);
    } else {
      addPerson(child);
    }
  };

  const modifyPerson = async (child) => {
    handleCloseModal(MODALS.update);

    try {
      const newChild = { ...child };
      delete newChild.personUuid;

      if (child.linkType.code === LINK_TYPES.children.code)
        await customerApi.updatePerson(
          user.auth,
          user.customerNumber,
          child.personUuid,
          newChild
        );
      else if (child.linkType.code === LINK_TYPES.roommate.code)
        await policyApi.updatePerson(
          user.auth,
          policyNumber,
          child.personUuid,
          newChild
        );
    } catch (error) {
      enqueueSnackbar(
        'Une erreur est survenue lors de la modification du bénéficiaire, Veuillez réessayer ultérieurement ou contacter Assurimo si le problème persiste',
        { variant: 'error' }
      );
    } finally {
      fetchChildren();
    }
  };

  const addPerson = async (child) => {
    handleCloseModal(MODALS.update);

    try {
      if (child.linkType.code === LINK_TYPES.children.code)
        await customerApi.addPerson(user.auth, user.customerNumber, child);
      else if (child.linkType.code === LINK_TYPES.roommate.code)
        await policyApi.addPerson(user.auth, policyNumber, child);
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar(
        "Une erreur est survenue lors de l'ajout du bénéficiaire, Veuillez réessayer ultérieurement ou contacter Assurimo si le problème persiste",
        { variant: 'error' }
      );
    } finally {
      fetchChildren();
    }
  };

  // const getChildren = async () => {
  //   try {
  //     const result = await customerApi.getChildren(
  //       user.auth,
  //       user.customerNumber
  //     );
  //     setChildren(result);
  //   } catch (error) {
  //     console.log(t('dashboard.police.attestation.error_get_enfant'), error);
  //     enqueueSnackbar(error.message, { variant: 'error' });
  //   }
  // };

  // useEffect(() => {
  //   getChildren();
  // }, []);

  return (
    <>
      <Grid container className={classes.beneficiariesWrapper}>
        <Box p={2} className={classes.container} width="100%">
          <Grid container item xs={12}>
            <Box mb={2}>
              <Typography variant="h5" color="secondary">
                {t('dashboard.police.beneficiaries.title')}
              </Typography>
            </Box>
            <Grid item container>
              {beneficiaries.map((child) => (
                <ChildItem
                  key={child.personUuid}
                  child={child}
                  onClick={() => handleOpenModal(MODALS.update, child)}
                  onDelete={() => handleOpenModal(MODALS.delete, child)}
                />
              ))}
            </Grid>
            <Button
              variant="outlined"
              color="primary"
              to="/sinistres/choix-police"
              startIcon={<AddCircleIcon />}
              onClick={() => handleOpenModal(MODALS.update)}
            >
              {t('dashboard.police.beneficiaries.addBeneficiary')}
            </Button>
          </Grid>
        </Box>
      </Grid>
      <ChildrenModal
        open={popinOpen}
        onClose={() => handleCloseModal(MODALS.update)}
        child={selectedChild}
        onValidate={handleValidate}
      />
      <Popin
        open={deleteModalOpen}
        onClose={() => handleCloseModal(MODALS.delete)}
        onValidate={handleDelete}
        title="Êtes-vous sûre de vouloir supprimer cet enfant ?"
        validateButtonLabel={t('common.yes')}
        cancelButtonLabel={t('common.no')}
        showCancelButton
      />
    </>
  );
};

Beneficiaries.propTypes = {
  police: PropTypes.object.isRequired,
  policyNumber: PropTypes.string.isRequired,
  beneficiaries: PropTypes.array,
  setBeneficiaries: PropTypes.func.isRequired,
};

export default Beneficiaries;
