import React from 'react';
import MomentUtils from '@date-io/moment';
import { Box, TextField, Typography } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function FormSinistre(props) {
  const {
    setDate,
    setDescription,
    date,
    minDate,
    description,
    today,
    dateError,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      {setDate && (
        <Box
          mb={6}
          display="flex"
          width={1}
          alignItems="center"
          flexDirection="column"
        >
          <Typography variant="h5" color="secondary">
            {t('dashboard.sinistre.form.info.date')}
          </Typography>
          <Box mt={2} width={1}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inlined"
                inputVariant="filled"
                format="DD/MM/yyyy"
                className="date-picker-input"
                label={t('dashboard.sinistre.form.info.date')}
                value={date}
                onChange={(newDate) => {
                  if (newDate) setDate(newDate.format('YYYY/MM/DD'));
                  else setDate(null);
                }}
                minDate={minDate}
                maxDate={today}
                style={{ width: '100%' }}
                maxDateMessage={null}
                minDateMessage={null}
                invalidDateMessage={t('error.fields.invalidDate')}
              />
            </MuiPickersUtilsProvider>
            {dateError && (
              <FormHelperText error={true} variant="outlined">
                {dateError}
              </FormHelperText>
            )}
          </Box>
        </Box>
      )}
      {setDescription && (
        <Box
          mb={6}
          display="flex"
          width={1}
          alignItems="center"
          flexDirection="column"
        >
          <Typography variant="h5" color="secondary">
            {t('dashboard.sinistre.form.info.description')}
          </Typography>
          <Box mt={2} width={1}>
            <TextField
              label={t('dashboard.sinistre.form.info.description_placeholder')}
              variant="filled"
              multiline
              rows={8}
              onChange={(evt) => setDescription(evt.target.value)}
              value={description}
              style={{ width: '100%' }}
            />
          </Box>
        </Box>
      )}
    </>
  );
}

FormSinistre.propTypes = {
  setDate: PropTypes.func,
  setDescription: PropTypes.func,
  date: PropTypes.object,
  minDate: PropTypes.object,
  description: PropTypes.string,
  today: PropTypes.object,
  dateError: PropTypes.string,
};

export default FormSinistre;
