import React, { useEffect, useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MrhContext } from 'contexts/mrh';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';

const LODGIS_URL = 'https://my.lodgis.com/id/mrh';

const Lodgis = () => {
  const { search } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useContext(MrhContext);
  const queryParameters = queryString.parse(search);

  const { id, from } = useParams();

  const decode = (encodedObject) => {
    const decodedObject = decodeURIComponent(encodedObject);
    try {
      const object = JSON.parse(decodedObject);
      return object;
    } catch (e) {
      return decodedObject;
    }
  };

  const setData = (key, value) => {
    dispatch({
      type: 'SET_DATA',
      payload: {
        key,
        value,
      },
    });
  };

  const cleanValue = (key, objectInfo) => {
    if (key === 'contact' && objectInfo[key].phone) {
      if (objectInfo[key].phone.value.startsWith('0')) {
        const newValue = { ...objectInfo[key], phone: { country: {} } };
        newValue.phone.country.countryCode = 'fr';
        newValue.phone.country.dialCode = '33';
        newValue.phone.country.name = 'France';
        newValue.phone.value = `+33${objectInfo[key].phone.value.substring(
          1,
          objectInfo[key].phone.value.length
        )}`;
        return newValue;
      } else if (
        !objectInfo[key].phone.country ||
        !objectInfo[key].phone.country.countryCode
      ) {
        const newValue = {
          ...objectInfo[key],
          phone: {
            value: objectInfo[key].phone.value,
            country: { countryCode: null },
          },
        };
        return newValue;
      }
    } else if(key === 'residenceType' && typeof objectInfo[key] === 'string'){
      objectInfo[key] = [objectInfo[key]];
    }
    return objectInfo[key];
  };

  const getSubscribtionInfo = async () => {
    const URL = `${LODGIS_URL}/${id}`;
    const { stay, step } = queryParameters;

    try {
      const response = await fetch(URL, {
        method: 'GET',
      });

      if (response.ok) {
        const infos = await response.json();

        if (infos instanceof Object) {
          Object.keys(infos).forEach((key) => {
            const value = cleanValue(key, infos);
            setData(key, value);
          });

          setData('from', from);
        }

        if (step) {
          const stepId = decode(step);

          dispatch({
            type: 'SET_STEP',
            payload: {
              stepId: stepId,
            },
          });
        }

        if (stay) history.push('/souscription/mrh', { stay: true });
        else history.push('/souscription/mrh');
      } else {
        throw new Error();
      }
    } catch (error) {
      enqueueSnackbar(t('error.lodgis.unknown'), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (id) {
      getSubscribtionInfo();
    }
  }, []);

  return <></>;
};

export default Lodgis;
