export const CLAIMS_STATUS_CODE = {
  CLOSS : {
    code: 'CLOSS',
    description : 'clos sans suite'
  },
  CLOSTER : {
    code: 'CLOSTER',
    description : 'sinistre terminé'
  },
  EC : {
    code: 'EC',
    description : 'en cours'
  },
  PREDECLA : {
    code: 'PREDECLA',
    description : 'déclaration du sinistre dans PREDECLA'
  },
  PARCOURS : {
    code: 'PARCOURS',
    description : 'déclaration du sinistre dans CLAIMSIA'
  },
}