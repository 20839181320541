import quotesApi from 'api/quotesApi';

const findDataInArray = (data, key) => {
  return data?.find((equipement) => equipement === key);
};

const generateQuote = (data, parcoursId) => {
  const params = {
    title: data.contact.gender,
    firstName: data.contact.firstName,
    lastName: data.contact.lastName,
    mail: data.contact.mail,
    originalCode: data.from ? data.from : null,
    paymentSplittingCode: data.paymentSplitting.code,
    promotionCode: data.promotionCode ? data.promotionCode : null,
    rateOptions: {
      deductibleOptionCode: data.deductible.code,
      landscapingOptionCode: data.landscaping.code,
      movableCapitalAmountsOptionCode: data.movableCapitalAmount.code,
    },
    returnAll: false,
    risk: {
      address: {
        address1: data.contractAddress.address1,
        address2: data.contractAddress.address2,
        zipCode: data.contractAddress.zipCode,
        city: data.contractAddress.city,
      },
      area: data.area,
      assuredQuality: data.assuredQuality,
      housingType: data.housingType,
      housingUsageType: 'P',
      numberOfRoom: data.numberOfPrincipalRooms,
      realEstateType: data.housingType,
      numberPieces: data.numberOfPrincipalRooms,
      equipment: {
        swimmingPool: !!findDataInArray(
          data.equipements,
          'gardenWithSwimmingPool'
        ),
        veranda: !!findDataInArray(data.equipements, 'veranda'),
        outbuilding: findDataInArray(data.equipements, 'outbuilding')
          ? 'INF120'
          : 'NONE',
        landscaping:
          !!findDataInArray(data.equipements, 'gardenWithSwimmingPool') ||
          !!findDataInArray(data.equipements, 'gardenWithoutSwimmingPool'),
      },
    },
    riskType: 'SMA',
    souscriptionParcoursId: parcoursId,
  };

  return quotesApi.generateQuote(params);
};

export { generateQuote };
