import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Grid,
  Box,
  Container,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Field from 'components/Field';
import NextStepButton from 'components/NextStepButton/NextStepButton';
import Lottie from 'components/Lottie/index';

const InputsStep = (props) => {
  const {
    fields,
    fieldsTitle,
    text,
    onChange,
    onConfirm,
    disabled,
    lottie,
    twoPerLine,
    secondaryFields,
    secondaryFieldsTitle,
    isFloor,
    isNotFloor,
  } = props;
  const theme = useTheme();
  const [showErrors, setShowErrors] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const isDownXs = useMediaQuery(theme.breakpoints.down('xs'));

  const handleSubmit = async () => {
    setShowErrors(true);

    const isFormCorrect = !fields.find((f) => f.invalid);
    if (!isFormCorrect) return;

    if (isFormCorrect) {
      setIsDisabled(true);
      onConfirm();
    }
  };

  const handleChange = (event, forceLowerCase) => {
    if (event.target.type === 'checkbox') {
      onChange(event.target.id, event.target.checked);
    } else {
      const value = forceLowerCase
        ? event.target.value.toLowerCase()
        : event.target.value;

      onChange(event.target.id, value);
    }
  };

  const getSpacing = () => {
    if (twoPerLine && !isDownXs) return 2;
    else return 0;
  };

  return (
    <Container maxWidth={lottie ? 'md' : 'sm'} style={{ marginBottom: 40 }}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid container alignItems="center" justifyContent="center" spacing={3}>
          <Grid container item xs={12} sm={lottie ? 5 : 12} alignItems="center">
            <form noValidate autoComplete="off" style={{ width: '100%' }}>
              <Grid container spacing={getSpacing()}>
                {fieldsTitle && (
                  <Typography variant="subtitle1">{fieldsTitle}</Typography>
                )}
                {fields.map((field) => {
                  const {
                    invalid,
                    forceLowerCase,
                    showExtraButton,
                    ...otherProps
                  } = field;
                  return (
                    <Grid item xs={12} sm={twoPerLine ? 6 : 12} key={field.id}>
                      <FormControl
                        fullWidth
                        margin="dense"
                        error={showErrors && invalid}
                      >
                        <Field
                          disabled={disabled}
                          {...otherProps}
                          fullWidth
                          helperText={showErrors && invalid && field.helperText}
                          onChange={(e) => handleChange(e, forceLowerCase)}
                        />
                        {showExtraButton && showExtraButton()}
                      </FormControl>
                    </Grid>
                  );
                })}
                {secondaryFieldsTitle && (
                  <Typography variant="subtitle1">
                    {secondaryFieldsTitle}
                  </Typography>
                )}
                {secondaryFields &&
                  secondaryFields.map((field) => {
                    const {
                      invalid,
                      forceLowerCase,
                      showExtraButton,
                      ...otherProps
                    } = field;
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={twoPerLine ? 6 : 12}
                        key={field.id}
                      >
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={showErrors && invalid}
                        >
                          <Field
                            disabled={disabled}
                            {...otherProps}
                            fullWidth
                            helperText={
                              showErrors && invalid && field.helperText
                            }
                            onChange={(e) => handleChange(e, forceLowerCase)}
                          />
                          {showExtraButton && showExtraButton()}
                        </FormControl>
                      </Grid>
                    );
                  })}
              </Grid>
            </form>
          </Grid>
          {lottie && (
            <Grid item md={5} xs={12}>
              <Lottie
                animationData={lottie}
                isFloor={isFloor}
                isNotFloor={isNotFloor}
                destinationFrame={2}
                width={191}
              />
            </Grid>
          )}
        </Grid>
        <Box mt={2}>
          {text && <Typography variant="caption">{text}</Typography>}
          <NextStepButton onClick={handleSubmit} disabled={isDisabled} />
        </Box>
      </Grid>
    </Container>
  );
};

InputsStep.propTypes = {
  fields: PropTypes.array.isRequired,
  fieldsTitle: PropTypes.string,
  text: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  lottie: PropTypes.object,
  twoPerLine: PropTypes.bool,
  secondaryFields: PropTypes.array,
  secondaryFieldsTitle: PropTypes.string,
  isFloor: PropTypes.bool,
  isNotFloor: PropTypes.bool,
};

InputsStep.defaultProps = {
  disabled: false,
  isFloor: true,
};

export default InputsStep;
