import React, { useRef, useEffect, useState, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { Grid, Box, Button, Typography, Hidden } from '@material-ui/core';
import {
  GetApp as GetAppIcon,
  Print as PrintIcon,
  Mail as MailIcon,
} from '@material-ui/icons';
import printJS from 'print-js';
import { makeStyles } from '@material-ui/core/styles';
import policyApi from 'api/policyApi';
import { useSnackbar } from 'notistack';
import { downloadFileFromLink, firstLetterUppercase } from 'utils';
import ReviewsContext from 'contexts/reviews';

import Header from 'components/DashboardHeader';
import RelatedContract from 'components/RelatedContract/RelatedContract';
import PdfViewver from 'components/PdvViewver';
import LateralPopin from 'components/LateralPopin';
import { ASSURIMO_CONTACT_EMAIL } from 'constants';
import GoogleLogo from 'assets/images/google.png';
import TrustpilotLogo from 'assets/images/trustpilot.png';
import { getMessageError } from 'constants/common/errorCode';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 10,
    backgroundColor: '#fff',
    marginTop: theme.spacing(2),
  },
  attestationContainer: {
    paddingTop: theme.spacing(6),
  },
  responsiveButton: {
    width: '100%',
  },
  pdfWrapper: {
    border: '1px solid black',
    marginBottom: theme.spacing(2),
  },
}));

const CertificatePage = memo((props) => {
  const classes = useStyles();
  const history = useHistory();
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(true);
  // const [showReviewsPopin, setShowReviewsPopin] = useState(true);
  const wrapper = useRef();
  const certificateRef = useRef();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { addReviewDisplay, setShowReviewsPopin, getReviewsDisplay } =
    useContext(ReviewsContext);

  const { location } = props;
  const { state: routerState } = location;
  const auth = _get(routerState, 'auth', null);
  const policy = _get(routerState, 'policy', null);
  const params = _get(routerState, 'params', null);
  const certificateType = _get(routerState, 'certificateType', null);

  const [certificateUrl, setCertificateUrl] = useState(null);

  if (!policy || !certificateType || !params) history.goBack();

  const getCertificatePreviewUrl = async () => {
    try {
      const { beneficiary, ...otherParams } = params;

      const blob = await policyApi.generateCertificate(
        auth,
        policy.policyNumber,
        {
          linkedPersonUuid: beneficiary.personUuid,
          certificateType: certificateType.code,
          ...otherParams,
        }
      );
      const url = window.URL.createObjectURL(new Blob([blob]));
      setCertificateUrl(url);
    } catch (error) {
      const messageError = getMessageError(error);
      console.error(t(messageError), error);
      enqueueSnackbar(t(messageError), { variant: 'error' });
    }
  };

  const downloadCertificate = (event) => {
    event.preventDefault();
    downloadFileFromLink(certificateUrl, t(certificateType.label), 'pdf');

    const reviewsDisplay = getReviewsDisplay();

    if (reviewsDisplay < 1) {
      addReviewDisplay();
      handleOpenReviewsPopin();

      // if (newReviewsDisplay < 1) handleOpenReviewsPopin();
    }
  };

  const handleOpenReviewsPopin = () => {
    setShowReviewsPopin(true);
  };

  const printCertificate = () => {
    printJS(certificateUrl);
  };

  useEffect(() => {
    getCertificatePreviewUrl();
  }, []);

  // const lateralPopinDialogContent = (
  //   <div>
  //     <Typography variant="h6" align="center">
  //       {t('common.reviews.satisfyingExperience')}
  //     </Typography>
  //     <div style={{ marginTop: 20 }}>
  //       <Typography variant="body1" align="center">
  //         <b>{t('common.reviews.letReview')}</b>
  //       </Typography>
  //     </div>
  //     <Grid
  //       item
  //       container
  //       justifyContent="space-around"
  //       alignItems="center"
  //       direction="row"
  //       style={{ marginTop: 20, marginBottom: 20 }}
  //     >
  //       <Grid
  //         container
  //         item
  //         direction="column"
  //         xs={6}
  //         alignItems="center"
  //         justifyContent="center"
  //       >
  //         <Grid item>
  //           <a
  //             href="https://g.page/r/CYatEFjsZaHdEAg/review"
  //             target="_blank"
  //             rel="noopener noreferrer"
  //           >
  //             <img src={GoogleLogo} height={40} />
  //           </a>
  //         </Grid>
  //         <Grid item>
  //           <Button
  //             variant="contained"
  //             color="primary"
  //             size="large"
  //             href="https://g.page/r/CYatEFjsZaHdEAg/review"
  //             target="_blank"
  //             rel="noopener noreferrer"
  //           >
  //             {t('common.reviews.writeReview')}
  //           </Button>
  //         </Grid>
  //       </Grid>
  //       <Grid
  //         container
  //         item
  //         direction="column"
  //         xs={6}
  //         alignItems="center"
  //         justifyContent="center"
  //       >
  //         <Grid item>
  //           <a
  //             href="https://fr.trustpilot.com/evaluate/www.assurimo.fr"
  //             target="_blank"
  //             rel="noopener noreferrer"
  //           >
  //             <img src={TrustpilotLogo} height={40} />
  //           </a>
  //         </Grid>
  //         <Grid item>
  //           <Button
  //             variant="contained"
  //             color="primary"
  //             size="large"
  //             href="https://fr.trustpilot.com/evaluate/www.assurimo.fr"
  //             target="_blank"
  //             rel="noopener noreferrer"
  //           >
  //             {t('common.reviews.writeReview')}
  //           </Button>
  //         </Grid>
  //       </Grid>
  //     </Grid>

  //     <Typography variant="caption" color="secondary" align="center">
  //       {t('common.reviews.contactUsEmail')}
  //       <a href={`mailto:${ASSURIMO_CONTACT_EMAIL}`}>
  //         {ASSURIMO_CONTACT_EMAIL}
  //       </a>{' '}
  //       {t('common.reviews.contactUsPhone')}
  //       <a href={`tel:${t('common.assurimoPhoneNumber')}`}>
  //         {t('common.assurimoPhoneNumber')}
  //       </a>
  //     </Typography>
  //   </div>
  // );

  return (
    <Box mb={10}>
      {/* Top bar header */}
      <Header title={t(certificateType.label)} subtitle={policy.policyKey} />

      {/* Attestation details */}
      <Grid container className={classes.attestationContainer}>
        <Box p={2} className={classes.container} width="100%">
          <Grid container item xs={12} ref={wrapper}>
            <Box mb={2}>
              <Typography variant="h5" color="secondary">
                {t(certificateType.label)} -{' '}
                {firstLetterUppercase(params.beneficiary.firstName)}{' '}
                {params.beneficiary.lastName.toUpperCase()}
              </Typography>
            </Box>
            <RelatedContract policy={policy} />
          </Grid>
        </Box>
      </Grid>

      {/* PDF part */}
      <Grid container>
        <Box p={4} className={classes.container} width="100%">
          <Hidden mdDown>
            <Box mb={2}>
              <Grid container item xs={12}>
                <Button
                  startIcon={<GetAppIcon />}
                  variant="outlined"
                  color="secondary"
                  onClick={downloadCertificate}
                  style={{ textAlign: 'center' }}
                  disabled={downloadButtonDisabled}
                >
                  {t('common.download')}
                </Button>
                <Box ml={1} mr={1}>
                  <Button
                    startIcon={<PrintIcon />}
                    variant="outlined"
                    color="secondary"
                    onClick={printCertificate}
                    style={{ textAlign: 'center' }}
                  >
                    {t('common.print')}
                  </Button>
                </Box>
                {/* <Button
                  startIcon={<MailIcon />}
                  variant="outlined"
                  color="secondary"
                >
                  Envoyer par email
                </Button> */}
              </Grid>
            </Box>
          </Hidden>
          <div ref={certificateRef} className={classes.pdfWrapper}>
            <PdfViewver
              filePath={certificateUrl}
              setDocumentLoaded={() => setDownloadButtonDisabled(false)}
            />
          </div>
          <Hidden lgUp>
            <Grid container item xs={12}>
              <Button
                startIcon={<GetAppIcon />}
                variant="outlined"
                color="secondary"
                className={classes.responsiveButton}
                onClick={downloadCertificate}
                disabled={downloadButtonDisabled}
              >
                {t('common.download')}
              </Button>
              <Box mt={1} mb={1} width="100%">
                <Button
                  startIcon={<PrintIcon />}
                  variant="outlined"
                  color="secondary"
                  className={classes.responsiveButton}
                  onClick={printCertificate}
                >
                  {t('common.print')}
                </Button>
              </Box>
              {/* <Button
                startIcon={<MailIcon />}
                variant="outlined"
                color="secondary"
                className={classes.responsiveButton}
              >
                Envoyer par email
              </Button> */}
            </Grid>
          </Hidden>
        </Box>
      </Grid>
      {/* <LateralPopin
        open={showReviewsPopin}
        onClose={handleCloseReviewsPopin}
        content={lateralPopinDialogContent}
        maxWidth="sm"
        enableTitleArea={false}
        showCancelButton={false}
      /> */}
    </Box>
  );
});

CertificatePage.displayName = 'CertificatePage';

CertificatePage.propTypes = {
  location: PropTypes.shape(),
};

export default CertificatePage;
